export function formatCellphone(cel) {
  if (!cel) return "";
  let formatted = cel.replace(/(\d{1,1})(\d{1})?(\d{1,2})?(\d{1,4})?/, function(
    _,
    p1,
    p2,
    p3,
    p4
  ) {
    let output = "";
    if (p1) output = `(${p1}`;
    if (p2) output += `${p2})`;
    if (p3) output += ` ${p3}`;
    if (p4) output += ` ${p4}`;
    return output;
  });
  return formatted;
}

export function formatDate(date) {
  if (!date) return "";
  return date
    .split("/")
    .reverse()
    .join("-");
}

export function formatDateAPI(date) {
  if (!date) return "";
  return date
    .split("-")
    .reverse()
    .join("/");
}

export function spliceField(stringField, maxLength){
  let finalString;

  if (stringField?.length > maxLength)
    finalString = `${stringField?.substring(0,maxLength)}...`

  else
    finalString = stringField;

  return finalString;
}

export function formatNumber(number) {
  const numStr = number.toString();
  let formattedNumber = '';
  for (let i = numStr.length - 1, j = 1; i >= 0; i--, j++) {
    formattedNumber = numStr[i] + formattedNumber;
    if (j % 3 === 0 && i !== 0) {
      formattedNumber = '.' + formattedNumber;
    }
  }
  return formattedNumber;
}

export function trimObjFields(obj){
  for (let objAttr in obj){
    if (objAttr !== "errors" && objAttr !== "invalid"){
      if (typeof obj[objAttr] === "object"){
        trimObjFields(obj[objAttr])
      }

      else{
        obj[objAttr] = obj[objAttr]?.toString().trim()
      }
    }
  }
}