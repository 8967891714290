<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Importar {{configTranslated}}</h1>
    </div>
    <div class="col-12 col-sm-12 p-0 col-md-12">
      <div class="card direct-chat direct-chat-primary">
        <div class="card-body">
          <div class="p-4 bg-white rounded">
            <div class="row">
              <div class="col-12 pr-4">
                <div class="form-group">
                  <UploadExcel
                    :on-success="handleSuccess"
                    :before-upload="beforeUpload"
                    :draggable="true"
                  />
                </div>
              </div>
            </div>

            <div v-show="tableData.length">
              <div class="content-header px-0">
                <h4>{{configTranslated}}s encontrad{{pronoun}}s</h4>
              </div>
              <b-table
                id="importConfigsTable"
                responsive
                striped
                borderless
                hover
                :fields="fields"
                :items="tableData"
                class="mt-3 rounded"
                selectable
                @row-selected="onRowSelected"
                select-mode="multi"
                ref="selectableTable"
                editableFields
              >
                <template
                  v-for="field in editableFields"
                  v-slot:[`cell(${field.key})`]="{ item }"
                >
                  <input
                    type="text"
                    class="form-control"
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'imp-' + field.key"
                    @change="verifyConfigs"
                  />

                  <span
                    v-if="item.error"
                    style="color: red"
                    :key="'erro-' + field.key"
                    >{{ item.error }}</span
                  >
                </template>
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true" class="check-symbol">&check;</span>
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                  </template>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center my-2">
                    <strong>
                      <i class="fas fa-sync-alt fa-spin"></i>
                    </strong>
                  </div>
                </template>
                <template v-slot:table-colgroup>
                  <col
                    v-for="field in fields"
                    :key="field.key"
                    :style="{
                      width:
                        field.key === 'selected' ||
                        field.key === 'actions'
                          ? '150px'
                          : '550px',
                    }"
                  />
                </template>
                <template v-slot:cell(invalid)="data">
                  <span
                    v-if="data.item.invalid"
                    class="valid-import badge badge-danger"
                    >Inválido</span
                  >
                  <span
                    v-if="!data.item.invalid"
                    class="valid-import badge badge-success"
                    >Válido</span
                  >
                </template>
              </b-table>

              <div class="row p-1">
                <button
                  id="selectAll"
                  type="button"
                  class="btn btn-outlined btn-primary mr-2"
                  @click="selectAllRows"
                >
                  Selecionar tudo
                </button>
                <button
                  id="desselectAll"
                  type="button"
                  class="btn btn-outlined btn-primary mr-2"
                  @click="clearSelected"
                >
                  Desselecionar tudo
                </button>
                <button
                  type="button"
                  class="btn btn-outlined btn-danger mr-2"
                  @click="deleteSelected"
                  id="btn-delete-selected"
                >
                  Remover selecionados
                </button>
                <button
                  id="btn-saveConfigs-selected"
                  @click="saveConfigs"
                  class="btn btn-success px-5"
                  :disabled="load"
                >
                  <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                </button>
              </div>
            </div>
            <span v-if="!tableData.length" style="color: blue">
              Selecione um arquivo de {{configTranslated.toLowerCase()}}s |
              <a v-bind:href="item.loc" download>Modelo de importação xlsx</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadExcel from "@/components/UploadExcel/index.vue";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: { Breadcrumb, UploadExcel },
  data() {
    return {
      breadcrumbList: [],
      tableData: [],
      tableHeader: [],
      storage: null,
      user: "",
      sortBy: "",
      configTranslated: "",
      pronoun: "",
      currentPage: 1,
      fields: [
        { key: "selected", sortable: false, label: "Selecionado" },
        {
          key: "",
          editable: true,
          sortable: true,
          label: "",
        },
        {
          key: "invalid",
          label: "Validade",
        },
      ],
      fieldsHeader: [
        {
          key: "",
          label: "",
        },
      ],
      selected: [],
      item: {
        title: "Excel template",
        loc: `/assets/files/model_import_${this.config}s.xlsx`,
      },
    };
  },
  watch: {
    customer() {
      if (this.customer) {
        this.updateList();
      }
    },
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  beforeCreate(){
    if (!this.$route.params.config) {
      // set this.$route.params.user to the object inside the localStorage
      this.config = JSON.parse(localStorage.getItem("customerConfig"));
      if (!this.config) {
        window.frontMsg(
          "FRNT_MISSING_WARNING",
          {
            frontelement: "da Configuração de Importação",
          }
        );
        this.$router.push({
          name: 'customer-config',
          id: this.$route.params.id
        });
      }
    } else {
      this.config = this.$route.params.config;
      localStorage.setItem("customerConfig", JSON.stringify(this.$route.params.config));
    }
  },
  created(){
    //const configPage = this.config;
    if (this.config === "area") this.configTranslated = "Área";
    else if (this.config === "department") {this.configTranslated = "Departamento";}
    else if (this.config === "position") {this.configTranslated = "Cargo";}

    this.pronoun = this.config === 'area' ? 'a' : 'o';
    this.fields[1].key = this.config;
    this.fields[1].label = this.configTranslated;
    this.fieldsHeader[0].key = this.config;
    this.fieldsHeader[0].label = this.configTranslated;
    this.sortBy = this.config;
  },
  mounted() {
    this.updateList();

    if (this.$route.params.customerId) {
      if (this.$route.params.customerId) {
        this.$store.dispatch("employee/setores/getEmployeeAreas", {
          customer_id: this.$route.params.customerId,
        });
        this.$store.dispatch("employee/departamentos/getEmployeeDepartamentos", {
          customer_id: this.$route.params.customerId,
        });
        this.$store.dispatch("employee/cargos/getEmployeeCargos", {
          customer_id: this.$route.params.customerId,
        });
      }
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
      this.$router.push("/");
    }
  },
  methods: {
    verifyConfigs() {
      let hashSet = new Set();

      let repeatConfig = (configArray, item) => {
        return configArray.some(
          element => element[this.config].toUpperCase() == item[this.config].toUpperCase()
        );
      };

      this.tableData.forEach((item) => {
        item.error = null;
        item.invalid = false;
        
        if (!item[this.config]?.trim()){
          item.error = "Campo não pode ser vazio.";
          item.invalid = true;
        }
        else if (item[this.config]?.length > 50){
          item.error = "Tamanho não pode exceder 50 caracteres.";
          item.invalid = true;
        }
        else if (
          hashSet.has(item[this.config])
        ) {
          item.invalid = true;
          item.error = `${this.configTranslated} duplicad${this.pronoun}.`;
        }
        else if (repeatConfig(this[`${this.config}s`], item)){
          item.error = `${this.configTranslated} já cadastrad${this.pronoun}.`;
          item.invalid = true;
        } 
        else {
          hashSet.add(item[this.config]);
        }
        
      });
      this.$refs.selectableTable.refresh();
    },
    changeKeys(obj_array) {
      obj_array.forEach((elem) => {
        Object.keys(elem).forEach((key) => {
          for (let y in this.fieldsHeader) {
            if (key.toLowerCase() == this.fieldsHeader[y].label.toLowerCase()) {
              /* Create new key with the same values/properties */
              Object.defineProperty(
                elem,
                this.fieldsHeader[y].key,
                Object.getOwnPropertyDescriptor(elem, key)
              );
              /*Delete old key*/
              delete elem[key];
              break;
            }
          }
        });
      });

      return obj_array;
    },
    label_to_key_header(header) {
      let new_header = [];
      let found;
      /*This code is not optimal, but in the worse case it is only a 32 element array, so it doesnt matter*/
      header.forEach((field) => {
        /* Cannot use "break" in forEach*/
        found = false;
        for (let y in this.fieldsHeader) {
          if (field.toLowerCase() == this.fieldsHeader[y].label.toLowerCase()) {
            new_header.push(this.fieldsHeader[y].key);
            found = true;
            break;
          }
        }
        /*didnt find a match, keep the same*/
        if (!found) {
          new_header.push(field);
        }
      });
      return new_header;
    },
    saveConfigs() {
      const routeConfig = this.configTranslated.replace("Á", "A") + 's';
      const midRoute = routeConfig === 'Areas' ? 'setores' : routeConfig.toLowerCase();

      this.verifyConfigs();
      let validConfigs = this.selected.filter((item) => !item.invalid);
      if (validConfigs.length && validConfigs.length === this.selected.length) {
        let sendConfigs = [...this.selected];
        validConfigs.forEach((config) => {
          delete config.invalid;
          delete config.selected;
          delete config.error;
          config[this.config] = config[this.config].toUpperCase()
        });

        this.$store.dispatch(`employee/${midRoute}/saveList${routeConfig}`, {
          customer_id: this.$route.params.customerId,
          [routeConfig.toLowerCase()]: sendConfigs
        });
        
        // delete the selected configs from the table
        this.tableData = this.tableData.filter(
          (config) => !this.selected.includes(config)
        );
        this.selected = [];
      } else {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
          field: `Dados d${this.pronoun}s ${this.configTranslated}`,
          extra: `Selecione ${this.configTranslated}s válid${this.pronoun}s para salvar`,
        });
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    deleteSelected() {
      this.tableData = this.tableData.filter((e) => {
        return !this.selected.includes(e);
      });
      this.$refs.selectableTable.refresh();
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    },
    handleSuccess({ results, header }) {
      if (results.length < 50) {
        this.tableData = this.changeKeys(results);
        this.tableHeader = this.label_to_key_header(header);
        if (this.tableHeader[0] === this.config){
          this.tableData.forEach((line) => {
            line[this.config] = line[this.config].trim();
            if (line[this.config]?.length > 50){
              line[this.config] = line[this.config].slice(0,50)
            }
          });
        }
        this.verifyConfigs();
      } else {
        window.frontMsg(
          "FRNT_ACTION_WARNING",
          {
            reason: "a quantidade de linhas na planilha excede o limite de 50",
          }
        );
      }
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = `Editar cliente ${this.customer.company_name}`;
      }
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    editableFields() {
      return this.fields.filter((field) => field.editable);
    },
    areas() {
      return this.$store.getters["employee/setores/areas"];
    },
    departments() {
      return this.$store.getters["employee/departamentos/departamentos"];
    },
    positions() {
      return this.$store.getters["employee/cargos/cargos"];
    },
  },
};
</script>

<style lang="scss" scoped>
.check-symbol {
  font-size: 3rem;
  margin-top: -30px;
  margin-left: 15px;
  position: absolute;
  display: block;
  text-align: center;
  user-select: none;
}

.valid-import {
  width: 100px;
  font-size: 20px;
  padding: 10px;
  border-radius: 50px;
  transition: none;
}
</style>
