<template>
  <div>
    <div
      v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'FRONTDESK', 'DASHBOARD', 'USER']"
    >
      <div class="row px-0">
        <TwInput label="" col_lg="10" class="pr-0">
          <b-input-group>
            <b-input-group-prepend is-text
              ><i class="fas fa-search"></i>
            </b-input-group-prepend>
            <input
              style="max-width: 900px"
              v-model="filter"
              type="search"
              class="form-control"
              id="filterInputTags"
              placeholder="Buscar"
            />
          </b-input-group>
        </TwInput>
        <div class="col-2 d-flex flex-column-reverse pb-3">
          <b-button
            href="#"
            variant="primary"
            v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
            @click="
              () => {
                this.$router.push({ name: 'customer-add-tags' });
              }
            "
            :disabled="loadingCustomerTags"
            >Associar Tags</b-button
          >
        </div>
      </div>

      <b-table
        responsive="sm"
        striped
        borderless
        hover
        class="mt-3 rounded"
        no-sort-reset
        ref="selectableTable"
        sticky-header="610px"
        show-empty
        empty-text="Nenhum registro encontrado"
        empty-filtered-text="Nenhum registro encontrado para essa busca"
        select-mode="multi"
        head-variant="light"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        @context-changed="changeBsTableState"
        :filter="filter"
        :busy="loadingCustomerTags"
        :items="customerTags"
        :fields="fieldsTags"
        @row-selected="onRowSelected"
        @filtered="onFiltered"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <strong>
              <i class="fas fa-sync-alt fa-spin"></i>
            </strong>
          </div>
        </template>
        <template v-slot:cell(association)="data">
          <a
            v-if="data.item.employee"
            :href="`/customer/${data.item.customer_id}/employee/${data.item.employee.id}/edit`"
          >
            COLABORADOR: {{ data.item.employee.name || "Não alocado" }}
          </a>
          <a
            v-else-if="data.item.asset"
            :href="`/customer/${data.item.customer_id}/asset/${data.item.asset.id}/edit`"
          >
            ATIVO: {{ data.item.asset.name || "Não alocado" }}
          </a>
          <span
            style="
              color: red;
              padding: 4px;
              border-radius: 4px;
              font-size: 18px;
            "
            v-else
            title="Não alocado"
            ><i class="fas fa-times"></i
          ></span>
        </template>
        <template v-slot:cell(actions)="data">
          <button
            id="unassign-colab"
            type="button"
            @click="unassignTag(data)"
            class="mr-3"
            :disabled="loadingCustomerTags"
          >
            <i
              class="fas fa-lg fa-user-minus"
              title="Desassociar Colaborador"
            ></i>
          </button>
        </template>
      </b-table>

      <div class="row">
        <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="pagination"
          ></b-pagination>
        </div>
        <div class="col-12 col-md-8">
          <div class="btn-group" role="group">
            <b-dropdown
              variant="primary"
              v-model="perPage"
              :text="`Linhas: ${perPage}`"
            >
              <b-dropdown-item
                href="#"
                @click="
                  () => {
                    this.perPage = 10;
                  }
                "
                >10</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="
                  () => {
                    this.perPage = 20;
                  }
                "
                >20</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="
                  () => {
                    this.perPage = 50;
                  }
                "
                >50</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="
                  () => {
                    this.perPage = '1000';
                  }
                "
                >1000</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import TwInput from "@/components/TwInput.vue";

export default {
  name: "CustomerTagTab",
  components: {
    BDropdown,
    BDropdownItem,
    TwInput,
  },
  data() {
    return {
      filter: null,
      perPage: 10,
      currentPage: 1,
      rows: 1,
      sortBy: "",
      TagUnassign: "",
      fieldsTags: [
        { label: "Nome Tag", key: "tag_label" },
        { label: "Identificador", key: "mac_address" },
        { label: "Associação", key: "association" },
        { label: "Tipo Tag", key: "tag_type" },
        { label: "Ações", key: "actions", class: "actions" },
      ],
      selected: [],
      searchMacAddress: null,
      filteredTags: [],
    };
  },
  watch: {
    customerTags() {
      if (this.$options.name) {
        const ctx = JSON.parse(
          localStorage.getItem(`bstable-state-${this.$options.name}`)
        );
        if (!ctx) {
          this.perPage = 10;
          this.currentPage = 1;
          this.sortBy = "";
          this.rows = this.customerTags.length;
          return;
        }
        if (ctx.filter !== "" && ctx.filter !== null) {
          this.filter = ctx.filter;
        }
        if (this.perPage !== "" && this.perPage !== null) {
          this.perPage = ctx.perPage;
        }
        if (ctx.sortBy !== "") {
          this.sortBy = ctx.sortBy;
        }
        // IOT-873: Table indicates page 1, but the content is of the last page visited.
        /*if (ctx.currentPage) {
          this.currentPage = ctx.currentPage;
        }*/
      }
      this.rows = this.customerTags.length;
    },
    customer() {
      if (this.customer) {
        this.$store.dispatch("tags/getCustomerTags", {
          customer_id: this.customer.id,
        });
      }
    },
  },
  methods: {
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    onFiltered(customerTags) {
      this.rows = customerTags.length;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    unassignTag(tag) {
      if (!tag.item.employee && !tag.item.asset) {
        window.frontMsg("FRNT_ACTION_WARNING", {
          reason: "a Tag não está associada a nenhum Colaborador ou Ativo",
        });
        return;
      }
      if (tag.item.employee) {
        const data = {
          tag: tag.item,
          employee_id: tag.item.employee.id,
        };
        this.$store.dispatch("tags/unassignTagFromEmployee", data);
        return;
      }
      if (tag.item.asset) {
        const data = {
          mac_address: tag.item.mac_address,
          assetId: tag.item.asset.id,
          customer_id: tag.item.customer_id,
        };
        this.$store.dispatch("tags/unassignTagFromAsset", data);
        return;
      }
    },
    async bulkUnassign(tag) {
      await this.$store.dispatch("tags/unassignTagFromEmployee", {
        tag: tag.item,
        employee_id: this.employee.id,
      });
    },
  },
  computed: {
    customer() {
      return this.$store.getters["customer/customer"];
    },
    employeeTags() {
      return this.$store.getters["tags/employeeTags"];
    },
    customerTags() {
      return this.$store.getters["tags/customerTags"];
    },
    loadingCustomerTags() {
      return this.$store.getters["tags/loadingCustomerTags"];
    },
  },
};
</script>
