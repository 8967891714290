import { api } from "../services/api";
import router from "../router";
import employee_area from "./employee/employee_area";
import employee_departamento from "./employee/employee_departamento";
import employee_cargos from "./employee/employee_cargos";
import {displayErrMsg} from "../utils/errors";

export default {
  namespaced: true,
  modules: {
    setores: employee_area,
    departamentos: employee_departamento,
    cargos: employee_cargos,
  },
  state: {
    employee: null,
    employees: [],
    areas: [],
    departments: [],
    positions: [],
    verifyImport: true,
  },

  mutations: {
    async SAVE_EMPLOYEE(state, { data }) {
      await api
        .post("customers/" + data.customer_id + "/employees", data.employee)
        .then(() => {
          displayErrMsg('GLOBAL_SUCCESS_ADD', 'Colaborador', "SAVE_EMPLOYEE");
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Colaborador', "SAVE_EMPLOYEE");
        });

      router.push({
        name: "customer-edit",
        params: router.currentRoute.params,
      });
    },
    async SAVE_LIST_EMPLOYEE(state, { data }) {
      state.verifyImport = false;

      await api
        .post("customers/" + data.customer_id + "/employees/list", data.data)
        .then((res) => {
          displayErrMsg('GLOBAL_SUCCESS_ADD', 'Colaborador', "SAVE_LIST_EMPLOYEE");
          let { data } = res;

          for (let employee of data)  state.employees.push(employee);

          
          state.verifyImport = true;
          // router.push({
          //   name: "customer-edit",
          //   params: router.currentRoute.params,
          // });
        })
        .catch((err) => {
          state.verifyImport = true;
          displayErrMsg(err.response.data.code, 'Colaborador', "SAVE_LIST_EMPLOYEE");
        });
    },
    async DELETE_EMPLOYEE(state, { data }) {
      await api
        .delete(`employees/${data.id}`)
        .then(() => {
          displayErrMsg('GLOBAL_SUCCESS_PERMANENTLY_DELETE', 'Colaborador', "DELETE_EMPLOYEE");
          state.employees = state.employees.filter(
            (employee) => employee.id !== data.id
          );
        })
        .catch((err) => {
          let errMsg = err.response.data?.message;
          if(errMsg.includes("employee-tag-association-exists")) {
            displayErrMsg(err.response.data.code, 'Colaborador', "DELETE_EMPLOYEE", {
              action: "remover", 
              reason: "possui tags associadas"
            });
          } else {
            displayErrMsg(err.response.data.code, 'Colaborador', "DELETE_EMPLOYEE", {
              action: "remover"
            });
          }
        });
    },
    async DELETE_EMPLOYEES(state, { data }) {
      const paramsDelete = data.employees.map((empl) => {
        return `id=${empl}&`;
      });

      await api
        .delete(
          "customers/" +
            data.customer.id +
            "/employees?" +
            paramsDelete.join("")
        )
        .then(() => {
          displayErrMsg('GLOBAL_SUCCESS_PERMANENTLY_DELETE', 'Colaborador', "DELETE_EMPLOYEES");
          state.employees = [];
        })
        .catch((err) => {
          let errMsg = err.response.data?.message;
          if(errMsg.includes("employee-tag-association-exists")) {
            displayErrMsg(err.response.data.code, 'Colaborador', "DELETE_EMPLOYEES", {
              action: "remover",
              reason: "possuem tags associadas"
            });
          } else {
            displayErrMsg(err.response.data.code, 'Colaborador', "DELETE_EMPLOYEES");
          }
        });
    },
    async UPDATE_EMPLOYEE(state, { data }) {
      await api
        .put("customers/" + data.customer.id + "/employees", data.employee)
        .then(() => {
          displayErrMsg('GLOBAL_SUCCESS_MODIFY', 'Colaborador', "UPDATE_EMPLOYEE");
        })
        .catch((err) => {
          if (err.response.data.message === "409 Conflict: employee-supervisor-has-active-subordinate"){
            displayErrMsg(err.response.data.code, 'Colaborador', "DELETE_EMPLOYEE");
          } 
          else if (err.response.data.code === 409 && err.response.data.message.includes("employee-is-fired")){
            displayErrMsg(err.response.data.code, 'Colaborador', 'DELETE_EMPLOYEE', {
              reason: "o colaborador já está excluído."
            })
          }          
          else {
            displayErrMsg(err.response.data.code, 'Colaborador', "UPDATE_EMPLOYEE");
          }
        });

      router.push({
        name: "customer-edit",
        params: router.currentRoute.params,
      });
    },
    async UPDATE_EMPLOYEES_STATE(state, { data }){
      await api
      .patch("customers/"+ data.customer_id + "/employees/state", {
        employees: data.employees
      })
      .then((res) => {
        for(let updatedEmployee of data.employees){
            for (let employee of state.employees){
              if (employee.id === updatedEmployee.id){
                employee.functional_data.state = updatedEmployee.state;
                break;
              }
            }
        }

        if (data.employees[0].state === "FIRED"){
          displayErrMsg('GLOBAL_SUCCESS_DELETE', 'Colaborador', 'DELETE_EMPLOYEE');
        }

        else {
          displayErrMsg('GLOBAL_SUCCESS_MODIFY', 'Colaborador', 'UPDATE_EMPLOYEE_STATES');
        }
      })
      .catch((err) => {
        if (data.employees[0].state === "FIRED"){
          if (err.response.data.code === 409 && err.response.data.message.includes("employee-supervisor-has-active-subordinate")){
            displayErrMsg(err.response.data.code, 'Colaborador', 'DELETE_EMPLOYEE')
          }
          
          else{
            displayErrMsg(err.response.data.code, 'Colaborador', 'DELETE_EMPLOYEES', {
              element: `${data.employees.length > 1 ? 'os Colaboradores' : 'o Colaborador'}`,
              reason: `${data.employees.length > 1 ? 'possuem' : 'possui'} tags associadas`
            });
          }
          
        }
      })
    },
    async GET_EMPLOYEES(state, { data }) {
    
      await api
        .get("customers/" + data.customer_id + "/employees")
        .then((res) => {
          state.employees = res.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Colaborador', "GET_EMPLOYEES");
        });
    },
    async GET_EMPLOYEE(state, { data }) {
      
      await api
        .get(`employees/${data.employeeId}`)
        .then((response) => {
          state.employee = response.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Colaborador', "GET_EMPLOYEE");
          router.push("/");
        });
    },
    async GET_AREAS(state, { data }) {
      await api
        .get(`customers/${data.customerId}/employees/areas`)
        .then((response) => {
          state.areas = response.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Área', "GET_AREAS");
          router.push("/");
        });
    },
    async GET_DEPARTMENTS(state, { data }) {
      await api
        .get(`customers/${data.customerId}/employees/departments`)
        .then((response) => {
          state.departments = response.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Área', "GET_DEPARTMENTS");
          router.push("/");
        });
    },
    async GET_POSITIONS(state, { data }) {
      await api
        .get(`customers/${data.customerId}/employees/positions`)
        .then((response) => {
          state.positions = response.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Área', "GET_POSITIONS");
          router.push("/");
        });
    },
    async UPDATE_LIST_EMPLOYEE(state, { data }) {
      await api
        .patch(`customers/${data.customer_id}/employees/trainings/assign`, data.data)
        .then((response) => {
          displayErrMsg('GLOBAL_SUCCESS_MODIFY', 'Colaborador', "UPDATE_EMPLOYEE");
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Colaborador', "UPDATE_EMPLOYEE");
        })
    },
  },
  getters: {
    employee: (state) => state.employee,
    employees: (state) => state.employees,
    areas: (state) => state.areas,
    departments: (state) => state.departments,
    positions: (state) => state.positions,
    verifyImport: (state) => state.verifyImport,
  },
  actions: {
    getEmployee(context, data) {
      context.commit("GET_EMPLOYEE", { data });
    },
    getEmployees(context, data) {
      context.commit("GET_EMPLOYEES", { data });
    },
    updateEmployee(context, data) {
      context.commit("UPDATE_EMPLOYEE", { data });
    },
    updateEmployeesState(context, data) {
      context.commit("UPDATE_EMPLOYEES_STATE", {data})
    },
    deleteEmployee(context, data) {
      context.commit("DELETE_EMPLOYEE", { data });
    },
    deleteEmployees(context, data) {
      context.commit("DELETE_EMPLOYEES", { data });
    },
    saveEmployee(context, data) {
      context.commit("SAVE_EMPLOYEE", { data });
    },
    saveListEmployee(context, data) {
      context.commit("SAVE_LIST_EMPLOYEE", { data });
    },
    getAreas(context, data) {
      context.commit("GET_AREAS", { data });
    },
    getDepartments(context, data) {
      context.commit("GET_DEPARTMENTS", { data });
    },
    getPositions(context, data) {
      context.commit("GET_POSITIONS", { data });
    },
    updateListEmployee(context, data){
      context.commit("UPDATE_LIST_EMPLOYEE", { data });
    },
  },
};
