<template>
  <div>
    <div class="content-header px-0 pb-0">
      <h1 class="title-I">Tags cadastradas</h1>
      {{ load ? "carregando" : "" }}
    </div>

    <div class="row px-0 d-flex align-items-center">
      <TwInput col_lg="4" label="">
        <b-input-group>
          <b-input-group-prepend is-text
            ><i class="fas fa-search"></i>
          </b-input-group-prepend>
          <input
            v-model="filter"
            type="search"
            class="form-control"
            id="filterInput"
            placeholder="Buscar"
          />
        </b-input-group>
      </TwInput>

      <TwInput col_lg="4" label="">
        <b-form-select
          class="form-control d-block"
          v-model="customer"
          :options="customers"
        >
          <template #first>
            <b-form-select-option :value="null" disabled>
              Buscar por Cliente
            </b-form-select-option>
          </template>
        </b-form-select>
      </TwInput>

      <TwInput col_lg="4" label="">
        <div
          style="height: 100%; width: 100%"
          class="d-flex align-items-center justify-content-center"
        >
          <router-link
            id="add-tag"
            title="Adicionar tag"
            v-can="['ADMIN']"
            to="/tag/add"
            class="btn btn-primary add-user col-6 ml-4 align-self-center"
          >
            <span class="d-none d-md-block d-lg-block">Adicionar</span>
            <span class="d-block d-md-none d-lg-none">
              <i class="fas fa-plus-circle"></i>
            </span>
          </router-link>
          <router-link
            v-can="['ADMIN']"
            title="Importar Tag"
            to="/tag/import"
            class="btn btn-primary col-6 ml-2 align-self-center"
            id="import-tag"
          >
            <div style="text-align: center">
              <span id="importTags" class="d-none d-md-block d-lg-block">Importar</span>
              <span class="d-block d-md-none d-lg-none">
                <i class="fas fa-file-import iconbotao"></i>
              </span>
            </div>
          </router-link>
        </div>
      </TwInput>
    </div>

    <b-table
      responsive="sm"
      striped
      borderless
      hover
      class="mt-0 rounded"
      no-sort-reset
      ref="selectableTable"
      sticky-header="610px"
      empty-text="Nenhum registro encontrado"
      empty-filtered-text="Nenhuma tag encontrada para essa busca"
      show-empty
      selectable
      select-mode="multi"
      :filter="filter"
      :busy="load || bulkLoad"
      :fields="fields"
      :sort-by.sync="sortBy"
      :items="tags"
      :per-page="perPage"
      :current-page="currentPage"
      @filtered="onFiltered"
      @row-selected="onRowSelected"
      @context-changed="changeBsTableState"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <strong>
            <i class="fas fa-sync-alt fa-spin"></i>
          </strong>
        </div>
      </template>

      <template v-slot:table-colgroup>
        <col
          v-for="field in fields"
          :key="field.key"
          :style="{
            width: ['actions', 'error'].includes(field.key) ? '130px' : '550px',
          }"
        />
      </template>
      <template v-slot:cell(customer_name)="data">
        <span>{{ data.item.customer_name }}</span>
        <br />
        <span
          v-can="['SYSADMIN', 'DASHBOARD', 'USER']"
          v-if="data.item.employee"
        >
          <span class="b">Colaborador:</span>
          <a
            :href="`/customer/${data.item.customer_id}/employee/${data.item.employee.id}/edit`"
          >
            {{ data.item.employee.name || "Não alocado" }}
          </a>
        </span>
      </template>
      <template v-slot:cell(info)="data">
        <div v-if="data.item.tag_state.battery_level >= 90">
          <i class="fas fa-battery-full mr-1"></i>
          <span>{{ data.item.tag_state.battery_level }}% </span>
        </div>
        <div v-else-if="data.item.tag_state.battery_level >= 70">
          <i class="fas fa-battery-three-quarters mr-1"></i>
          <span> {{ data.item.tag_state.battery_level }}% </span>
        </div>
        <div v-else-if="data.item.tag_state.battery_level >= 30">
          <i class="fas fa-battery-half mr-1"></i>
          <span> {{ data.item.tag_state.battery_level }}% </span>
        </div>
        <div v-else-if="data.item.tag_state.battery_level > 10">
          <i class="fas fa-battery-quarter mr-1"></i>
          <span> {{ data.item.tag_state.battery_level }}% </span>
        </div>
        <div
          v-else-if="
            data.item.tag_state.battery_level >= 0 &&
            data.item.tag_state.battery_level <= 10
          "
        >
          <i class="fas fa-battery-empty mr-1"></i>
          <span> {{ data.item.tag_state.battery_level }}% </span>
        </div>
        <div v-else>
          <span> NA </span>
        </div>
      </template>
      <template v-slot:cell(error)="data">
        <i
          v-if="bulkErrors.includes(data.item.id)"
          class="fas fa-exclamation-triangle"
          style="color: red"
          title="Erro ao executar ação em massa"
        ></i>
      </template>
      <template v-slot:cell(actions)="data">
        <button
          id="admStateTag"
          v-can="['ADMIN', 'SYSADMIN']"
          type="button"
          @click="tagChange = { ...data.item }"
          class="mr-3"
          data-toggle="modal"
          data-target="#ModalStateAdminstrativeTAG"
          title="Alterar Estado Administrativo"
        >
          <i
            v-if="
              tags[tags.indexOf(data.item)] &&
              tags[tags.indexOf(data.item)].tag_state.administrative_state ===
                'IN-SERVICE'
            "
            class="fas fa-lg fa-toggle-on iconspace"
            title="Ligado"
          ></i>
          <i
            v-else
            class="fas fa-lg fa-toggle-off iconspace"
            title="Desligado"
          ></i>
        </button>
        <a
          v-can="['ADMIN']"
          @click="tagDelete = data.item"
          href="#"
          class="mr-3"
          data-toggle="modal"
          data-target="#ModalDeleteTag"
          title="Excluir"
          :id="`excluir-${data.item.mac_address}`"
        >
          <i class="far fa-trash-alt iconspace"></i>
        </a>

        <router-link
          id="editTag"
          v-can="['ADMIN']"
          :to="{ name: 'tag-edit', params: { tag: data.item, tag_id: data.item.id } }"
          title="Editar"
        >
          <i class="fas fa-pen iconspace"></i>
        </router-link>
      </template>
    </b-table>
    <Modal
      id="ModalDeleteTag"
      @click="tagDelete = data.item"
      title="Excluir Tag"
      :msg="'Tem certeza que deseja excluir ' + tagDelete.tag_label + '?' +
      '\n\nOs dados de monitoramento desta tag serão apagados permanentemente da base de dados!'"
      style="white-space: pre-wrap"
      button="Excluir"
      :method="deleteTag"
    />
    <Modal
      id="ModalDeleteMultipleTags"
      @click="tagDelete = data.item"
      title="Excluir Tag"
      v-bind:msg="'Tem certeza que deseja excluir as tags selecionadas?' +
      '\n\nOs dados de monitoramento destas tags serão apagados permanentemente da base de dados!'"
      button="Excluir"
      :method="bulkDelete"
    />
    <Modal
      id="ModalStateAdminstrativeTAG"
      @click="tagChange = { ...data.item }"
      title="Alterar Estado Administrativo"
      v-bind:msg="
        'Tem certeza que deseja alterar o estado administrativo ' +
        tagChange.tag_label +
        '?'
      "
      button="Alterar"
      buttonMod="info"
      :method="stateAdministrativeTAG"
    />
    <div class="row">
      <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="pagination"
        ></b-pagination>
      </div>
      <div class="col-12 col-md-8">
        <div class="btn-group" role="group">
          <b-dropdown
            id="linhasDropdown"
            variant="primary"
            v-model="perPage"
            :text="`Linhas: ${perPage}`"
          >
            <b-dropdown-item
              href="#"
              id="dez"
              @click="
                () => {
                  this.perPage = 10;
                }
              "
              >10</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              id="vinte"
              @click="
                () => {
                  this.perPage = 20;
                }
              "
              >20</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              id="cinquenta"
              @click="
                () => {
                  this.perPage = 50;
                }
              "
              >50</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              id="mil"
              @click="
                () => {
                  this.perPage = '1000';
                }
              "
              >1000</b-dropdown-item
            >
          </b-dropdown>
          <b-dropdown id="selecionar" variant="primary" v-model="perPage" :text="`Selecionar`">
            <b-dropdown-item
              href="#"
              id="todos"
              @click="
                () => {
                  this.selectAllRows();
                }
              "
              >Todos</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              id="nenhum"
              @click="
                () => {
                  this.clearSelected();
                }
              "
              >Nenhum</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <b-dropdown
          :disabled="!this.selected.length"
          class="ml-2"
          variant="primary"
          text="Ação"
          id="acao"
        >
          <b-dropdown-item 
          href="#" 
          id="excluir"
          data-toggle="modal"
          data-target="#ModalDeleteMultipleTags"
            >Excluir</b-dropdown-item
          >
          <b-dropdown-item href="#" id="ligarEstado" @click="bulkTurnOnAdm"
            >Ligar estado adm.</b-dropdown-item
          >
          <b-dropdown-item href="#" id="desligarEstado" @click="bulkTurnOffAdm"
            >Desligar estado adm</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "../../components/Modal.vue";
import TwInput from "@/components/TwInput.vue";
import { BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
  name: "ManagerTag",
  components: {
    Modal,
    TwInput,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      filter: null,
      perPage: 10,
      rows: 1,
      tagDelete: "",
      tagChange: "",
      sortBy: "tag_label",
      currentPage: 1,
      customer: null,
      selected: [],
      fields: [
        { key: "tag_label", sortable: true, label: "Nome" },
        { key: "customer_name", sortable: true, label: "Cliente" },
        { key: "mac_address", sortable: true, label: "Identificador" },
        { key: "tag_type", sortable: true, label: "Tipo" },
        {
          key: "info",
          sortable: true,
          label: "Informações",
        },
        { key: "actions",
          sortable: true,
          label: "Ações",
          class: "actions"
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("tag/get");
    this.$store.dispatch("customer/get");
  },
  methods: {
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    async stateAdministrativeTAG() {
      this.tagChange.tag_state.administrative_state =
        this.tagChange.tag_state.administrative_state === "IN-SERVICE"
          ? "OUT-OF-SERVICE"
          : "IN-SERVICE";
      // this.tagChange.employee.name = "";
      await this.$store.dispatch("tag/updateTag",{
        ...this.tagChange,
        mac_address: this.tagChange.mac_address.replace(/:|-/g, ""),
      });
    },
    onFiltered(tags) {
      this.rows = tags.length;
    },
    deleteTag() {
      event.preventDefault();
      this.$store.dispatch("tag/deleteTag", this.tagDelete);
    },
    async bulkTurnOffAdm() {
      let previouslySelected = this.selected; // Backup variable of the number of selected rows.

      this.selected = this.selected.filter((selected) => {
        return selected.tag_state.administrative_state === "IN-SERVICE";
      });

      if (this.selected.length === 0) {
        window.frontMsg(
          "FRNT_ACTION_WARNING",
          {
            reason: "nenhuma Tag selecionada está ativa",
          }
        );
        this.selected = previouslySelected; // Restore the number of selected rows.
        return;
      }

      this.selected.forEach((selected) => {
        selected.tag_state.administrative_state = "OUT-OF-SERVICE";
      });
      await this.$store.dispatch("tag/bulkSaveTags", this.selected);
    },
    async bulkTurnOnAdm() {
      let previouslySelected = this.selected; // Backup variable of the number of selected rows.

      this.selected = this.selected.filter((selected) => {
        return selected.tag_state.administrative_state === "OUT-OF-SERVICE";
      });

      if (this.selected.length === 0) {
        window.frontMsg(
          "FRNT_ACTION_WARNING",
          {
            reason: "todas as Tags selecionadas estão ativas",
          }
        );
        this.selected = previouslySelected; // Restore the number of selected rows.
        return;
      }

      this.selected.forEach((selected) => {
        selected.tag_state.administrative_state = "IN-SERVICE";
      });

      await this.$store.dispatch("tag/bulkSaveTags", this.selected);
    },
    async bulkDelete() {
      await this.$store.dispatch("tag/bulkDeleteTags", this.selected);
    },
  },
  watch: {
    tags() {
      if (this.$options.name) {
        const ctx = JSON.parse(
          localStorage.getItem(`bstable-state-${this.$options.name}`)
        );
        if (!ctx){
          this.perPage = 10;
          this.currentPage = 1;
          this.sortBy = "";
          this.rows = this.tags.length;
          return;
        }
        if (ctx.perPage) {
          this.perPage = ctx.perPage;
        }
        if (ctx.sortBy !== "") {
          this.sortBy = ctx.sortBy;
        }
        // IOT-873: Table indicates page 1, but the content is of the last page visited.
        /*if (ctx.currentPage) {
          this.currentPage = ctx.currentPage;
        }*/
      }
      this.rows = this.tags.length;
    },
  },
  computed: {
    bulkLoad() {
      return this.$store.getters["tag/bulkLoad"];
    },
    bulkErrors() {
      return this.$store.getters["tag/bulkErrors"];
    },
    load() {
      return this.$store.getters["load"];
    },
    tags() {
      let filteredTags = [];
      if (this.customer !== null) {
        filteredTags = this.$store.getters["tag/tags"].filter((tag) => {
          return tag.customer_id === this.customer;
        });
        return filteredTags;
      } else {
        filteredTags = this.$store.getters["tag/tags"];
      }
      filteredTags.forEach((element) => {
        const index = this.customers.findIndex((customer) => {
          return customer.value === element.customer_id;
        });
        if (index > 0)
          element.customer_name = this.customers[index]?.text || "";

        // add "actions" and "info" fields to the tag to show if it is in service or not
        // used for Vue's sorting
        element.actions = element.tag_state.administrative_state;
        element.info = element.tag_state.battery_level;
      });
      return filteredTags;
    },
    customers() {
      let customers = this.$store.getters["customer/customers"].map(
        (customer) => {
          return {
            value: customer.id,
            text: customer.company_name,
          };
        }
      );
      customers = [{ value: null, text: "TODOS" }, ...customers];
      return customers;
    },
    errors() {
      return this.$store.getters["tag/bulk/errors"];
    },
  },
};
</script>

<style>
@media screen and (max-width: 1040px) {
  .iconspace {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
</style>
