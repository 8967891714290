<template>
  <div v-can="['ADMIN', 'SYSADMIN']">
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Adicionar Ativo</h1>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card direct-chat direct-chat-primary employee">
          <form @submit="saveAsset">
            <div class="card-body">
              <div class="pt-4 pr-4 pb-2 pl-4 bg-white rounded">
                <div class="row">
                  <TwInput col_lg="6" label="Nome" required="true">
                    <input
                      v-model="asset.name"
                      required
                      type="text"
                      class="form-control"
                      placeholder="Nome do Ativo"
                      id="assetName"
                      maxlength="64"
                      @change="verifyErrors('header.name')"
                      v-bind:style="
                        assetError.header.name.error
                          ? 'border-color: red'
                          : 'border-color: #ced4da'
                      "
                    />
                  </TwInput>
                  <TwInput label="Data de Aquisição">
                    <input
                      v-model="asset.acquisition_date"
                      type="date"
                      class="form-control"
                      placeholder="Data de Aquisição"
                      id="assetAcquisitionDate"
                      :min="'1900-01-01'"
                      :max="'2099-12-31'"
                      @focusout="verifyErrors('header.acquisition_date')"
                      v-bind:style="
                        assetError.header.acquisition_date.error
                          ? 'border-color: red'
                          : 'border-color: #ced4da'
                      "
                    />
                  </TwInput>
                </div>
                <div class="row">
                  <TwInput label="Número de série" required="true">
                    <input
                      v-model="asset.serial_number"
                      type="text"
                      class="form-control"
                      placeholder="Número de série do Ativo"
                      id="assetSerial"
                      maxlength="128"
                      required="true"
                      @change="verifyErrors('header.serial_number')"
                      v-bind:style="
                        assetError.header.serial_number.error
                          ? 'border-color: red'
                          : 'border-color: #ced4da'
                      "
                    />
                  </TwInput>
                  <TwInput label="Identificador Interno" required="true">
                    <i
                      title="Identificador Interno - SKU ou Número de patrimônio"
                      class="fas fa-question-circle"
                      style="margin-left: 5px"
                    >
                    </i>
                    <input
                      v-model="asset.internal_id"
                      type="text"
                      class="form-control"
                      placeholder="Número Identificador do Ativo"
                      id="assetInternal"
                      maxlength="32"
                      required="true"
                      @change="verifyErrors('header.internal_id')"
                      v-bind:style="
                        assetError.header.internal_id.error
                          ? 'border-color: red'
                          : 'border-color: #ced4da'
                      "
                    />
                  </TwInput>
                </div>
                <div class="row mb-2">
                  <TwInput label="Categoria">
                    <b-form-input
                      list="categoryList"
                      placeholder="Categoria do Ativo"
                      class="form-control noArrow"
                      id="assetCategory"
                      autocomplete="off"
                      type="text"
                      @change="asset.category = $event"
                      maxlength="64"
                    ></b-form-input>
                    <div class="datalist-wrapper">
                      <datalist id="categoryList">
                        <option v-for="cat in cats" v-bind:key="cat">
                          {{ cat }}
                        </option>
                      </datalist>
                    </div>
                  </TwInput>
                  <TwInput label="Subcategoria">
                    <b-form-input
                      list="subcategoryList"
                      placeholder="Subcategoria do Ativo"
                      class="form-control noArrow"
                      id="assetSubcategory"
                      autocomplete="off"
                      type="text"
                      @change="asset.subcategory = $event"
                      maxlength="64"
                    ></b-form-input>
                    <div class="datalist-wrapper">
                      <datalist id="subcategoryList">
                        <option v-for="sub in subs" v-bind:key="sub">
                          {{ sub }}
                        </option>
                      </datalist>
                    </div>
                  </TwInput>
                </div>
                <div class="row mb-2">
                  <TwInput col_lg="12" label="Descrição do ativo">
                    <b-form-textarea
                      v-model="asset.description"
                      id="assetDescription"
                      class="form-control"
                      placeholder="Descrição do ativo"
                      rows="3"
                      max-rows="8"
                      maxlength="256"
                      no-auto-shrink
                    ></b-form-textarea>
                  </TwInput>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <div class="card direct-chat direct-chat-primary">
                      <div class="card-body custom-card overflow-visible">
                        <ul class="nav nav-tabs bg-main">
                          <li class="nav-item">
                            <a
                              id="functionalData-tab"
                              class="nav-link first"
                              v-bind:class="{
                                active: tabSelected === 'functionalData',
                              }"
                              v-bind:style="
                                assetError.functional_data.error
                                  ? 'color: red !important'
                                  : 'color: #000'
                              "
                              href="javascript:void(0);"
                              v-on:click="changeTab('functionalData')"
                              aria-current="dados-funcionais"
                              tabindex="-1"
                              >Dados funcionais</a
                            >
                          </li>
                          <li class="nav-item">
                            <a
                              id="maintenance-tab"
                              class="nav-link"
                              v-bind:class="{
                                active: tabSelected === 'maintenance',
                              }"
                              v-bind:style="
                                assetError.maintenance_data.error
                                  ? 'color: red !important'
                                  : 'color: #000'
                              "
                              v-on:click="changeTab('maintenance')"
                              href="javascript:void(0);"
                              aria-current="maintenance"
                              tabindex="-1"
                              >Tipos de Manutenção</a
                            >
                          </li>
                          <li class="nav-item">
                            <a
                              id="financialData-tab"
                              class="nav-link"
                              v-bind:class="{
                                active: tabSelected === 'financialData',
                              }"
                              v-bind:style="
                                assetError.financial_data.error
                                  ? 'color: red !important'
                                  : 'color: #000'
                              "
                              v-on:click="changeTab('financialData')"
                              href="javascript:void(0);"
                              aria-current="financialData"
                              tabindex="-1"
                              >Dados Financeiros</a
                            >
                          </li>
                          <li class="nav-item">
                            <a
                              id="warrantyData-tab"
                              class="nav-link"
                              v-bind:class="{
                                active: tabSelected === 'warrantyData',
                              }"
                              v-bind:style="
                                assetError.warranty_data.error
                                  ? 'color: red !important'
                                  : 'color: #000'
                              "
                              v-on:click="changeTab('warrantyData')"
                              href="javascript:void(0);"
                              aria-current="warrantyData"
                              tabindex="-1"
                              >Dados de Garantia</a
                            >
                          </li>
                        </ul>
                        <div class="p-4 bg-white rounded">
                          <div class="tab-content">
                            <div
                              class="tab-pane fade"
                              v-bind:class="{
                                show: tabSelected === 'maintenance',
                                active: tabSelected === 'maintenance',
                              }"
                            >
                              <div>
                                <b-table
                                  striped
                                  hover
                                  :items="asset.maintenance_data"
                                  :fields="fields"
                                >
                                  <template
                                    v-slot:cell(maintenance_type)="data"
                                  >
                                    <b-form-input
                                      list="mainList"
                                      placeholder="Selecione"
                                      class="form-control noArrow"
                                      v-model="data.item.type"
                                      id="assetMaintenanceType"
                                      type="text"
                                      autocomplete="off"
                                      maxlength="64"
                                    ></b-form-input>
                                    <div class="datalist-wrapper">
                                      <datalist id="mainList">
                                        <option
                                          v-for="mtype in maintenanceHistory"
                                          v-bind:key="mtype"
                                        >
                                          {{ mtype }}
                                        </option>
                                      </datalist>
                                    </div>
                                  </template>
                                  <template
                                    v-slot:cell(maintenance_frequency)="data"
                                  >
                                    <b-form-input
                                      type="number"
                                      v-model="data.item.frequency"
                                      placeholder="Frequência da Manutenção"
                                      id="assetMaintenanceFrequency"
                                      step="1"
                                      max="999999999"
                                    />
                                  </template>
                                  <template
                                    v-slot:cell(maintenance_description)="data"
                                  >
                                    <b-form-input
                                      type="text"
                                      v-model="data.item.description"
                                      placeholder="Descrição da Manutenção"
                                      id="assetMaintenanceDescription"
                                      maxlength="256"
                                    />
                                  </template>
                                  <template v-slot:cell(actions)="data">
                                    <b-button
                                      variant="danger"
                                      @click="removeRow(data.index)"
                                    >
                                      <i class="far fa-trash-alt"></i>
                                    </b-button>
                                  </template>
                                  <template #table-caption>
                                    <b-button
                                      class="btn btn-primary"
                                      @click="addRow()"
                                    >
                                      <i class="fa fa-add"></i></b-button
                                  ></template>
                                </b-table>
                              </div>
                            </div>
                            <div
                              class="tab-pane fade"
                              v-bind:class="{
                                show: tabSelected === 'functionalData',
                                active: tabSelected === 'functionalData',
                              }"
                            >
                              <div class="row">
                                <TwInput col_lg="4" label="Área">
                                  <select
                                    v-model="asset.functional_data.area"
                                    class="form-control"
                                    id="assetArea"
                                  >
                                    <option
                                      v-for="area in area_options"
                                      :key="area"
                                    >
                                      {{ area }}
                                    </option>
                                    <option value="other">Outro</option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Departamento">
                                  <select
                                    v-model="asset.functional_data.department"
                                    class="form-control"
                                    id="assetDepartment"
                                  >
                                    <option
                                      v-for="department in department_options"
                                      :key="department"
                                    >
                                      {{ department }}
                                    </option>
                                    <option value="other">Outro</option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Responsável">
                                  <select
                                    v-model="asset.functional_data.supervisor"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="sup in filteredSupervisors"
                                      class="sup"
                                      :key="sup.id"
                                    >
                                      <b>{{ sup.name }}</b>
                                    </option>
                                    <option value="other">Outro</option>
                                  </select>
                                </TwInput>
                                <TwInput
                                  col_lg="4"
                                  label="Estado de Funcionamento"
                                >
                                  <select
                                    v-model="asset.functional_data.state"
                                    class="form-control"
                                    id="assetStatus"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="ATIVO">ATIVO</option>
                                    <option value="INATIVO">INATIVO</option>
                                    <option value="DESCARTADO">
                                      DESCARTADO
                                    </option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Importância">
                                  <select
                                    v-model="asset.functional_data.importance"
                                    class="form-control"
                                    id="assetImportance"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="ALTA">ALTA</option>
                                    <option value="MEDIA">MÉDIA</option>
                                    <option value="BAIXA">BAIXA</option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Qualidade">
                                  <select
                                    v-model="
                                      asset.functional_data.quality_state
                                    "
                                    class="form-control"
                                    id="assetQuality"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="PERFEITO">PERFEITO</option>
                                    <option value="BOM">BOM</option>
                                    <option value="SUCATEADO">SUCATEADO</option>
                                  </select>
                                </TwInput>
                                <TwInput
                                  col_lg="4"
                                  label="Treinamento de Manuseio Necessário"
                                >
                                  <div class="form-check">
                                    <input
                                      id="TrainingYES"
                                      class="form-check-input"
                                      type="radio"
                                      name="radioButtonStatus"
                                      value="true"
                                      v-model="
                                        asset.functional_data.training_required
                                      "
                                    />
                                    <label
                                      class="form-check-label"
                                      value="true"
                                    >
                                      SIM
                                    </label>
                                  </div>
                                  <div class="form-check">
                                    <input
                                      id="TrainingNO"
                                      class="form-check-input"
                                      type="radio"
                                      name="radioButtonStatus"
                                      value="false"
                                      v-model="
                                        asset.functional_data.training_required
                                      "
                                      checked
                                    />
                                    <label
                                      class="form-check-label"
                                      value="false"
                                    >
                                      NÃO
                                    </label>
                                  </div>
                                </TwInput>
                                <TwInput col_lg="4" label="Fabricante">
                                  <input
                                    v-model="asset.functional_data.manufacturer"
                                    type="text"
                                    class="form-control"
                                    placeholder="Fabricante do Ativo"
                                    id="assetManufacturer"
                                    maxlength="64"
                                  />
                                </TwInput>
                                <TwInput col_lg="4" label="Modelo">
                                  <input
                                    v-model="asset.functional_data.model"
                                    type="text"
                                    class="form-control"
                                    placeholder="Modelo do Ativo"
                                    id="assetModel"
                                    maxlength="64"
                                  />
                                </TwInput>
                              </div>
                            </div>
                            <div
                              class="tab-pane fade"
                              v-bind:class="{
                                show: tabSelected === 'financialData',
                                active: tabSelected === 'financialData',
                              }"
                            >
                              <div class="row">
                                <TwInput
                                  col_lg="4"
                                  label="Taxa anual de depreciação (%)"
                                >
                                  <b-form-input
                                    v-model="
                                      asset.financial_data.depreciation_rate
                                    "
                                    type="number"
                                    class="form-control"
                                    placeholder="Taxa anual de depreciação (%)"
                                    id="assetDepreciationRate"
                                    min="0"
                                    max="100"
                                    v-bind:style="
                                      assetError.financial_data
                                        .depreciation_rate.error
                                        ? 'border-color: red'
                                        : 'border-color: #ced4da'
                                    "
                                    @change="
                                      verifyErrors(
                                        'financial_data.depreciation_rate'
                                      )
                                    "
                                  />
                                </TwInput>
                                <TwInput col_lg="4" label="Custo de Aquisição">
                                  <b-form-input
                                    v-model="
                                      asset.financial_data.acquisition_cost
                                    "
                                    type="number"
                                    min="0"
                                    step=".01"
                                    class="form-control"
                                    placeholder="Custo de Compra do Ativo"
                                    id="assetAcquisitionCost"
                                    max="10000000000"
                                    v-bind:style="
                                      assetError.financial_data.acquisition_cost
                                        .error
                                        ? 'border-color: red'
                                        : 'border-color: #ced4da'
                                    "
                                    @change="
                                      verifyErrors(
                                        'financial_data.acquisition_cost'
                                      )
                                    "
                                  />
                                </TwInput>
                                <TwInput col_lg="4" label="Data de Descarte">
                                  <input
                                    v-model="
                                      asset.financial_data.expiration_date
                                    "
                                    type="date"
                                    class="form-control"
                                    placeholder="Data de Descarte do Ativo"
                                    id="assetDisposalDate"
                                    :min="'1900-01-01'"
                                    :max="'2099-12-31'"
                                    v-bind:style="
                                      assetError.financial_data.expiration_date
                                        .error
                                        ? 'border-color: red'
                                        : 'border-color: #ced4da'
                                    "
                                    @focusout="
                                      verifyErrors(
                                        'financial_data.expiration_date'
                                      )
                                    "
                                  />
                                </TwInput>
                              </div>
                              <div class="row">
                                <TwInput col_lg="4" label="Valor Residual">
                                  <b-form-input
                                    v-model="asset.financial_data.salvage_value"
                                    type="number"
                                    min="0"
                                    step=".01"
                                    class="form-control"
                                    placeholder="Valor Residual"
                                    id="assetSalvageValue"
                                    max="10000000000"
                                    v-bind:style="
                                      assetError.financial_data.salvage_value
                                        .error
                                        ? 'border-color: red'
                                        : 'border-color: #ced4da'
                                    "
                                    @change="
                                      verifyErrors(
                                        'financial_data.salvage_value'
                                      )
                                    "
                                  />
                                </TwInput>
                                <TwInput
                                  col_lg="4"
                                  label="Vida Útil Operacional"
                                >
                                  <b-form-input
                                    v-model="asset.financial_data.useful_life"
                                    type="number"
                                    class="form-control"
                                    placeholder="Vida Útil Operacional em anos"
                                    id="assetUsefulLife"
                                    min="0"
                                    max="12000"
                                    v-bind:style="
                                      assetError.financial_data.useful_life
                                        .error
                                        ? 'border-color: red'
                                        : 'border-color: #ced4da'
                                    "
                                    @change="
                                      verifyErrors('financial_data.useful_life')
                                    "
                                  />
                                </TwInput>
                                <TwInput col_lg="4" label="Vida Útil Total">
                                  <input
                                    v-model="asset.financial_data.lifespan"
                                    type="number"
                                    class="form-control"
                                    placeholder="Vida Útil do Ativo em anos?"
                                    id="assetLifespan"
                                    max="12000"
                                    min="0"
                                    v-bind:style="
                                      assetError.financial_data.lifespan.error
                                        ? 'border-color: red'
                                        : 'border-color: #ced4da'
                                    "
                                    @change="
                                      verifyErrors('financial_data.lifespan')
                                    "
                                  />
                                </TwInput>
                                <TwInput
                                  col_lg="4"
                                  label="Nomenclatura Comum do Mercosul"
                                >
                                  <input
                                    v-model="asset.financial_data.ncm"
                                    type="text"
                                    class="form-control"
                                    placeholder="Nomenclatura Comum do Mercosul"
                                    id="assetNCM"
                                    maxlength="16"
                                  />
                                </TwInput>
                                <TwInput col_lg="4" label="Comodatário">
                                  <input
                                    v-model="asset.financial_data.borrower"
                                    type="text"
                                    class="form-control"
                                    placeholder="Comodatário do Ativo"
                                    id="assetBorrower"
                                    maxlength="32"
                                  />
                                </TwInput>
                                <TwInput
                                  col_lg="4"
                                  label="Data da Última Movimentação"
                                >
                                  <input
                                    v-model="
                                      asset.financial_data.asset_movement_date
                                    "
                                    type="date"
                                    class="form-control"
                                    placeholder="Data da Última Movimentação"
                                    id="assetMovementDate"
                                    :min="'1900-01-01'"
                                    :max="'2099-12-31'"
                                    @focusout="
                                      verifyErrors(
                                        'financial_data.asset_movement_date'
                                      )
                                    "
                                    v-bind:style="
                                      assetError.financial_data
                                        .asset_movement_date.error
                                        ? 'border-color: red'
                                        : 'border-color: #ced4da'
                                    "
                                  />
                                </TwInput>
                                <TwInput col_lg="6" label="Nota Fiscal">
                                  <b-input-group>
                                    <b-form-file
                                      placeholder="Selecione o arquivo"
                                      drop-placeholder="Solte o arquivo aqui..."
                                      accept="image/*,.pdf,.xml"
                                      @change="onFileChange"
                                      ref="nfUpload"
                                    ></b-form-file>
                                    <b-input-group-append
                                      v-if="asset.financial_data.nf_file"
                                    >
                                      <b-button
                                        variant="danger"
                                        @click="deleteNF()"
                                      >
                                        <i class="far fa-trash-alt"></i>
                                      </b-button>
                                    </b-input-group-append>
                                  </b-input-group>
                                </TwInput>
                                <TwInput col_lg="6" label="Número da Última NF">
                                  <input
                                    v-model="
                                      asset.financial_data.last_nf_number
                                    "
                                    type="number"
                                    step="1"
                                    class="form-control"
                                    placeholder="Número da Última NF"
                                    id="assetLastNFNumber"
                                    max="9999999999"
                                    min="0"
                                    v-bind:style="
                                      assetError.financial_data.last_nf_number
                                        .error
                                        ? 'border-color: red'
                                        : 'border-color: #ced4da'
                                    "
                                    @change="
                                      verifyErrors(
                                        'financial_data.last_nf_number'
                                      )
                                    "
                                  />
                                </TwInput>
                              </div>
                            </div>
                            <div
                              class="tab-pane fade"
                              v-bind:class="{
                                show: tabSelected === 'warrantyData',
                                active: tabSelected === 'warrantyData',
                              }"
                            >
                              <div class="row">
                                <TwInput col_lg="4" label="Estado da Garantia">
                                  <input
                                    v-model="asset.warranty_data.status"
                                    class="form-control"
                                    id="warrantyStatus"
                                    disabled
                                  />
                                </TwInput>

                                <TwInput col_lg="4" label="Código de Garantia">
                                  <b-form-input
                                    v-model="asset.warranty_data.code"
                                    type="text"
                                    class="form-control"
                                    placeholder="Código de Garantia"
                                    id="warrantyCode"
                                    maxlength="64"
                                  />
                                </TwInput>

                                <TwInput col_lg="4" label="Válido até">
                                  <b-form-input
                                    v-model="asset.warranty_data.valid_until"
                                    type="date"
                                    class="form-control"
                                    placeholder="Garantia Válida até"
                                    id="warrantyValidUntil"
                                    @focusout="verifyValidity()"
                                    :min="'1900-01-01'"
                                    :max="'2099-12-31'"
                                    v-bind:style="
                                      assetError.warranty_data.valid_until.error
                                        ? 'border-color: red'
                                        : 'border-color: #ced4da'
                                    "
                                  />
                                </TwInput>
                              </div>
                              <div class="row">
                                <TwInput
                                  col_lg="12"
                                  label="Descrição de Cobertura - Garantia"
                                >
                                  <b-form-textarea
                                    v-model="asset.warranty_data.description"
                                    id="warrantyDescription"
                                    class="form-control"
                                    placeholder="Descrição da Garantia"
                                    rows="3"
                                    max-rows="8"
                                    maxlength="256"
                                    no-auto-shrink
                                  ></b-form-textarea>
                                </TwInput>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-3 pl-2">
                  <button
                    id="saveAsset"
                    class="btn btn-primary px-5"
                    :disabled="load"
                  >
                    <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import {
  formatDate,
  formatDateAPI,
  formatNumber,
} from "@/utils/format-fields.js";
import "./style.scss";
import TwInput from "@/components/TwInput.vue";

export default {
  name: "AddAsset",
  data: function () {
    return {
      employeeSupervisor: "",
      fields: [
        {
          key: "maintenance_type",
          label: "Tipo de Manutenção",
        },
        {
          key: "maintenance_frequency",
          label: "Frequência da Manutenção",
        },
        { key: "maintenance_description", label: "Descrição da Manutenção" },
        { key: "actions", label: "Ações" },
      ],
      category: null,
      subcategory: null,
      cats: ["HARDWARE", "HOSPITALAR", "INFORMATICA", "MOBILIARIO"],
      subs: ["COMPUTADOR", "IMPRESSORA", "MONITOR", "NOTEBOOK"],
      maintenanceHistory: [
        "PREDITIVA",
        "CORRETIVA",
        "PREVENTIVA",
        "CALIBRAÇÃO",
      ],
      tabSelected: "functionalData",
      breadcrumbList: [],
      asset: {
        internal_id: null,
        acquisition_date: null,
        category: "",
        description: "",
        financial_data: {
          acquisition_cost: null,
          depreciation_rate: null,
          expiration_date: null,
          lifespan: null,
          ncm: null,
          salvage_value: null,
          useful_life: null,
          borrower: null,
          last_nf_number: null,
          asset_movement_date: null,
          nf_file: "",
        },
        functional_data: {
          area: null,
          department: null,
          importance: null,
          manufacturer: null,
          model: null,
          quality_state: null,
          state: null,
          supervisor: "",
          training_required: false,
        },
        maintenance_data: [
          {
            description: "",
            frequency: null,
            type: null,
          },
        ],
        name: null,
        serial_number: null,
        subcategory: "",
        warranty_data: {
          code: "",
          description: "",
          status: null,
          valid_until: null,
        },
      },
      assetError: {
        header: {
          error: false,
          name: {
            error: false,
            label: "Nome",
            type: "string",
          },
          serial_number: {
            error: false,
            label: "Número de Série",
            type: "string",
          },
          internal_id: {
            error: false,
            label: "Identificador Interno",
            type: "string",
          },
          acquisition_date: {
            error: false,
            label: "Data de Aquisição",
            type: "date",
          },
        },
        financial_data: {
          error: false,
          acquisition_cost: {
            error: false,
            label: "Custo de Aquisição",
            type: "number",
            max: 10000000000,
          },
          depreciation_rate: {
            error: false,
            label: "Taxa Anual de Depreciação",
            type: "number",
            max: 100,
          },
          expiration_date: {
            error: false,
            label: "Data de Descarte",
            type: "date",
          },
          lifespan: {
            error: false,
            label: "Vida Útil Total",
            type: "number",
            max: 12000,
          },
          salvage_value: {
            error: false,
            label: "Valor Residual",
            type: "number",
            max: 10000000000,
          },
          useful_life: {
            error: false,
            label: "Vida Útil Operacional",
            type: "number",
            max: 12000,
          },
          last_nf_number: {
            error: false,
            label: "Número da Última NF",
            type: "number",
            max: 9999999999,
          },
          asset_movement_date: {
            error: false,
            label: "Data da Última Movimentação",
            type: "date",
          },
        },
        warranty_data: {
          error: false,
          valid_until: {
            error: false,
            label: "Válido até",
            type: "date",
          },
        },
        maintenance_data: {
          error: false,
        },
        functional_data: {
          error: false,
        },
      },
      tabErrors: {
        financial_data: false,
        functional_data: false,
        maintenance_data: false,
        warranty_data: false,
      },
    };
  },

  mounted() {
    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getEmployees", {
        customer_id: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getAreas", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getDepartments", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getPositions", {
        customerId: this.$route.params.customerId,
      });
    }
    /*  this.$store.dispatch("asset/getAssets", {
        customer_id: this.$route.params.customerId,
      });

      this.$store.dispatch("asset/getAreas", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("asset/getDepartments", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("asset/getPositions", {
        customerId: this.$route.params.customerId,
      });
    } else {
      window.frontMsg(
        "FRNT_MISSING_WARNING",
        { frontelement: "do Cliente" }
      );
      this.$router.push("/");
    } */
    this.updateList();
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = this.customer.company_name;
      }
    },
    addRow() {
      document.activeElement.blur(); /* focus out the element (macumba) */

      this.asset.maintenance_data.push({
        type: "",
        frequency: "",
        description: "",
      });
      this.asset.maintenance_data.forEach((obj) => {
        if (!this.maintenanceHistory.includes(obj.type)) {
          this.maintenanceHistory.push(obj.type);
        }
      });
    },
    removeRow(index) {
      this.asset.maintenance_data.splice(index, 1);
    },
    verifyValidity() {
      const todayDate = new Date();

      if (this.asset.warranty_data.valid_until) {
        this.verifyErrors("warranty_data.valid_until");
        let validityDate = new Date(this.asset.warranty_data.valid_until);
        
        validityDate.setDate(validityDate.getDate() + 1); // Adjusting validity date to the choosed day.

        this.asset.warranty_data.status =
          validityDate >= todayDate ? "VALIDO" : "EXPIRADO";
      } else this.asset.warranty_data.status = null;
    },
    saveAsset() {
      event.preventDefault();
      let sendAsset = this.asset;
      this.verifyErrors("warranty_data.valid_until");
      if (sendAsset.acquisition_date) {
        sendAsset.acquisition_date = formatDateAPI(sendAsset.acquisition_date);
      }
      if (sendAsset.financial_data.expiration_date) {
        sendAsset.financial_data.expiration_date = formatDateAPI(
          sendAsset.financial_data.expiration_date
        );
      }
      if (sendAsset.warranty_data.valid_until) {
        sendAsset.warranty_data.valid_until = formatDateAPI(
          sendAsset.warranty_data.valid_until
        );
      }
      if (sendAsset.financial_data.asset_movement_date) {
        sendAsset.financial_data.asset_movement_date = formatDateAPI(
          sendAsset.financial_data.asset_movement_date
        );
      }
      this.$store.dispatch("asset/addAsset", {
        assets: [sendAsset],
        customerId: this.$route.params.customerId,
      });
    },
    onSelectSupervisor(supervisor) {
      this.employeeSupervisor = supervisor.name;
      this.asset.functional_data.supervisor = {
        name: supervisor.name,
        registration_number: supervisor.registration_number,
      };
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.fileName = files[0].name;

      let reader = new FileReader();
      reader.onload = (event) => {
        this.asset.financial_data.nf_file = event.target.result;
      };
      reader.readAsDataURL(files[0]);
    },
    changeTab(newTab) {
      this.tabSelected = newTab;
    },
    deleteNF() {
      this.asset.financial_data.nf_file = "";
      this.$refs.nfUpload.reset();
    },
    verifyErrors(key) {
      let assetContent = (assetData, objKey) => {
        const assetKey = objKey.split(".");
        let data = assetData;

        for (let i of assetKey) {
          data = data[i];
        }

        return data;
      };

      let changeTabColor = (tab) => {
        for (let input in this.assetError[tab]) {
          if (this.assetError[tab][input].error) {
            return true;
          }
        }

        return false;
      };

      let changeErrorStatus = (status) => {
        const assetKey = key.split(".");

        if (assetKey.length === 1) {
          this.assetError[assetKey[0]].error = status;
        } else if (assetKey.length === 2) {
          this.assetError[assetKey[0]][assetKey[1]].error = status;
        } else if (assetKey.length === 3) {
          this.assetError[assetKey[0]][assetKey[1]][assetKey[2]].error = status;
        }
        this.assetError[assetKey[0]].error = changeTabColor(assetKey[0]);
      };

      let objKey = key.includes("header.") ? key.replace("header.", "") : key;
      let content = assetContent(this.asset, objKey);
      let errorObj = assetContent(this.assetError, key);
      let error = false;

      if (errorObj.type !== "string" && content) {
        if (
          errorObj.type === "number" &&
          (content < 0 || content > errorObj.max)
        ) {
          error = true;
          window.frontMsg("FRNT_NUM_RANGE_WARNING", {
            field: errorObj.label,
            numbercondition: `entre 0 e ${formatNumber(errorObj.max)}`,
          });
        } else if (errorObj.type === "date") {
          let inputDate = new Date(content);
          let minDate = new Date("1900-01-01");
          let maxDate = new Date("2099-12-31");

          if (
            inputDate < minDate ||
            inputDate > maxDate ||
            inputDate == "Invalid Date"
          ) {
            error = true;
            window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
              field: errorObj.label,
              extra: "Insira uma data entre 01/01/1900 e 31/12/2099.",
            });
          }
        }
      } else if (
        errorObj.type === "string" &&
        (!content || !content?.toString().trim())
      ) {
        error = true;
        window.frontMsg("FRNT_EMPTY_WARNING", { field: errorObj.label });
      }

      changeErrorStatus(error);
    },
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    customer_employees() {
      return this.$store.getters["employee/employees"];
    },
    area_options() {
      return this.$store.getters["employee/areas"].map((area) => area.area);
    },
    department_options() {
      return this.$store.getters["employee/departments"].map(
        (department) => department.department
      );
    },
    filteredSupervisors() {
      if (this.employeeSupervisor) {
        return this.customer_employees.filter((employee) => {
          return (
            employee.name
              .toLowerCase()
              .includes(this.employeeSupervisor.toLowerCase()) &&
            employee.functional_data?.position?.toLowerCase() === "supervisor"
          );
        });
      }
      return this.customer_employees.filter(
        (employee) =>
          employee.functional_data?.position?.toLowerCase() === "supervisor"
      );
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
};
</script>

<style>
.datalist-wrapper {
  appearance: none;
  position: relative;
}
.datalist-wrapper::after {
  content: "⌄";
  font-size: 1.2rem;
  pointer-events: none;
  /*default right/bottom doenst align select field arrow, therefore this is necessary*/
  bottom: 5px;
  right: 0px;
  position: absolute;
}
.noArrow::-webkit-calendar-picker-indicator {
  opacity: 0;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: center;
  caption-side: bottom;
  background-color: rgba(215, 198, 248, 0.2);
  border: 2px solid rgba(215, 198, 248, 0.2);
  border-radius: 2px;
}
</style>
