<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />

    <div class="content-header px-0">
      <h1 class="title-I">Associar Tags</h1>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card direct-chat direct-chat-primary">
          <div class="card-body">
            <div class="p-4 bg-white rounded">
              <form @submit.prevent="assignTag">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group required position-relative">
                      <template v-if="Object.keys(selectedTag).length === 0">
                        <template>
                          <label>
                            Buscar Tag <span class="required">*</span>
                          </label>

                          <input
                            required
                            v-model="tagIdentification"
                            type="text"
                            class="form-control filter-input"
                            placeholder="Identificador"
                            id="tagIdentification"
                            autocomplete="off"
                          />
                        </template>

                        <div v-if="filteredTags.length" class="autocomplete">
                          <div
                            v-for="tag in filteredTags"
                            class="tag"
                            :key="tag.id"
                            @click="selectedTag = tag"
                          >
                            <b>{{ tag.tag_label }}</b>
                            <div>
                              <span>{{ tag.mac_address }} | {{ tag.tag_type }} | {{ tag.tag_model }}</span>
                            </div>
                          </div>
                        </div>
                      </template>

                      <template v-else>
                        <label>
                          Tag Selecionada <span class="required">*</span>
                        </label>

                        <div
                          @click="selectedTag = {}"
                          class="tag selected-tag"
                          type="button"
                        >
                          <b>{{ selectedTag.tag_label }}</b>

                          <div>
                            <span>{{ selectedTag.mac_address }}</span>
                            <span>{{ selectedTag.tag_type }}</span>
                            <span>{{ selectedTag.tag_model }}</span>
                          </div>
                        </div>

                        <small>Clique na tag para alterar</small>
                      </template>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <button
                      id="assignTag"
                      class="btn btn-primary float-right"
                      type="submit"
                      :disabled="load"
                    >
                      <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
                      Associar
                    </button>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12">
                    <b-table
                      responsive="sm"
                      striped
                      borderless
                      :busy="load"
                      :items="assetTags"
                      @context-changed="changeBsTableState"
                      :fields="fields"
                      show-empty
                      ref="tagtable"
                      id="tagTable"
                    >
                      <template v-slot:table-busy>
                        <div class="text-center my-2">
                          <b-spinner class="align-middle mr-3"></b-spinner>
                          <strong>Carregando...</strong>
                        </div>
                      </template>
                      <template v-slot:cell(actions)="data">
                        <button
                          type="button"
                          @click="unassignTag(data.item)"
                          class="mr-3"
                          id="tagButton"
                        >
                          <i
                            class="fas fa-lg fa-user-minus"
                            title="Dessasociar Tag"
                          ></i>
                        </button>
                      </template>
                      <template slot="empty">Nenhuma tag associada</template>
                    </b-table>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import hasRole from "../../utils/hasRole";

export default {
  name: "AssetAddTags",
  data: function () {
    return {
      breadcrumbList: [],
      fields: [
        { label: "Nome Tag", key: "tag_label" },
        { label: "Identificador", key: "mac_address" },
        { label: "Tipo Tag", key: "tag_type" },
        { label: "Ações", key: "actions", class: "actions" },
      ],
      tagIdentification: "",
      selectedTag: {},
    };
  },
  components: {
    Breadcrumb,
  },
  watch: {
    $route() {
      this.updateList();
    },
    filterType() {
      this.clearSearchInputs();
    },
    selectedAsset() {
      if (this.selectedAsset && this.selectedAsset.id) {
        this.$store.dispatch("tags/setAssetTags", this.selectedAsset.tags);
        this.updateList();
        /* refresh the table */
        this.$refs.tagtable.refresh();
      }
    },
  },
  computed: {
    customer() {
      return this.$store.getters["customer/customer"];
    },
    selectedAsset() {
      return this.$store.getters["asset/selectedAsset"];
    },
    customerTags() {
      return this.$store.getters["tags/customerTags"];
    },
    filteredTags() {
      if (this.tagIdentification) {
        return this.customerTags.filter((tag) => {
          return (
            (tag.mac_address
              .toLowerCase()
              .includes(this.tagIdentification.toLowerCase()) ||
              
             tag.tag_label
              .toLowerCase()
              .includes(this.tagIdentification.toLowerCase()) ||

             tag.tag_model
              .toLowerCase()
              .includes(this.tagIdentification.toLowerCase()) ||

             tag.tag_type
              .toLowerCase()
              .includes(this.tagIdentification.toLowerCase())
            
            ) && !tag.asset && !tag.employee
          );
        });
      }
      
      return this.customerTags.filter((tag) => !tag.asset && !tag.employee);
    },
    assetTags() {
      return this.$store.getters["tags/assetTags"];
    },
    loadingAssign() {
      return this.$store.getters["tags/loadingAssign"];
    },
    load() {
      return this.$store.getters["load"];
    },
  },
  mounted() {
    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("tags/getCustomerTags", {
        customer_id: this.$route.params.customerId,
        assetId: this.$route.params.assetId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
      this.$router.push("/");
    }

    if (this.$route.params.assetId) {
      this.$store.dispatch("asset/getAsset", {
        assetId: this.$route.params.assetId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Ativo" });
      this.$router.push("/");
    }

    this.updateList();
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;

      if (this.customer) {
        this.breadcrumbList[1].label = `${this.customer.company_name}`;
      }

      if (!hasRole(["ADMIN", "SYSADMIN"])) {
        this.breadcrumbList[2].name = null;
        this.breadcrumbList[2].link = null;
      }

      this.breadcrumbList[2].label = "Associar Tags";
    },
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    async assignTag() {
      const data = {
        mac_address: this.selectedTag.mac_address,
        assetId: this.selectedAsset.id,
      };

      if (this.selectedTag.mac_address) {
        await this.$store.dispatch("tags/assignTagtoAsset", data);

        this.selectedTag = {};
        this.tagIdentification = "";
        this.updateList();
      } else {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
          field: "Tag",
          extra: "Selecione uma Tag para associar",
        });
      }
    },
    unassignTag(tag) {
      const data = {
        mac_address: tag.mac_address,
        assetId: this.selectedAsset.id,
        customer_id: this.customer.id,
      };

      this.$store.dispatch("tags/unassignTagFromAsset", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-input {
  &:focus,
  &:active {
    + .autocomplete {
      display: block;
    }
  }
}
.autocomplete {
  border: 1px solid #ced4da;
  max-height: 200px;
  position: absolute;
  width: 100%;
  z-index: 2;
  background: white;
  border-radius: 4px;
  overflow-y: auto;
  display: none;

  &:hover,
  &:active {
    display: block;
  }
}

.tag {
  padding: 4px;
  font-size: 0.75rem;

  &.selected-tag {
    text-align: left;
    border-radius: 4px;
    display: block;
    width: 100%;
    line-height: 1;
    padding: 5px;
    border: 1px solid #ced4da;

    > div {
      margin-top: 2px;
    }
  }

  &:hover {
    cursor: pointer;
    background: #f4f6f9;
  }

  div {
    span {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
</style>
