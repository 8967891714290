import { api } from "../../services/api";
import { displayErrMsg } from "../../utils/errors";
export default {
  namespaced: true,
  state: {
    cargos: [],
  },
  mutations: {
    async SAVE_EMPLOYEE_CARGOS(state, { data }) {
      await api
        .post(`customers/${data.customer_id}/employees/positions`, {
          position: data.cargo,
        })
        .then(() => {
          state.cargos.push({ position: data.cargo });
          displayErrMsg("GLOBAL_SUCCESS_ADD", 'Colaborador - Cargos', 'SAVE_EMPLOYEE_CARGOS');
        })
        .catch((err) => {
          // if (err.response.data.subcode !== "TOKEN_EXPIRED") {
          //   window.toastr.error("Erro ao adicionar", "Colaborador - Cargo");
          // }
          displayErrMsg(err.response.data.code, 'Colaborador - Cargos', 'SAVE_EMPLOYEE_CARGOS');
        });
    },
    async DELETE_EMPLOYEE_CARGOS(state, { data }) {
      await api
        .delete(
          `customers/${
            data.customer_id
          }/employees/positions?position=${encodeURIComponent(data.cargo)}`
        )
        .then(() => {
          displayErrMsg("GLOBAL_SUCCESS_DELETE", 'Colaborador - Cargos', 'DELETE_EMPLOYEE_CARGOS');
          state.cargos = state.cargos.filter(
            (cargo) => cargo.position !== data.cargo
          );
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, 'Colaborador - Cargos', 'DELETE_EMPLOYEE_CARGOS');
        });
    },
    async GET_EMPLOYEES_CARGOS(state, { data }) {
      await api
        .get(`customers/${data.customer_id}/employees/positions`)
        .then((res) => {
          state.cargos = res.data;
        });
    },
    async SAVE_LIST_CARGOS(state, {data}){
      await api
      .post(`customers/${data.customer_id}/employees/positions/list`, {
        positions: data.cargos
      })
      .then(() => {
        for (let savedPosition of data.cargos){
          state.cargos.push({position: savedPosition.position});
        }
        displayErrMsg("GLOBAL_SUCCESS_ADD", 'Colaborador - Cargo', 'SAVE_LIST');
      })
      .catch((err) => {
        displayErrMsg(err.response.data.code, 'Colaborador - Cargos', 'SAVE_EMPLOYEE_CARGOS');
      });
    },
  },
  getters: {
    cargos: (state) => state.cargos,
  },
  actions: {
    getEmployeeCargos(context, data) {
      context.commit("GET_EMPLOYEES_CARGOS", { data });
    },
    deleteEmployeeCargos(context, data) {
      context.commit("DELETE_EMPLOYEE_CARGOS", { data });
    },
    saveEmployeeCargos(context, data) {
      context.commit("SAVE_EMPLOYEE_CARGOS", { data });
    },
    saveListCargos(context, data){
      context.commit("SAVE_LIST_CARGOS", { data });
    },
  },
};
