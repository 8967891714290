<template>
  <div v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']">
    <Modal
      id="modalDeleteBuilding"
      title="Excluir edifício"
      v-bind:msg="
        'Tem certeza que deseja excluir ' + buildingDelete.building_name + '?'
      "
      button="Excluir"
      :method="deleteBuilding"
    />

    <div class="row px-0">
      <TwInput label="" col_lg="10" class="pr-0">
        <b-input-group>
          <b-input-group-prepend is-text
            ><i class="fas fa-search"></i>
          </b-input-group-prepend>
          <input
            style="max-width: 900px"
            v-model="filter"
            type="search"
            class="form-control"
            id="filterInput"
            placeholder="Buscar"
          />
        </b-input-group>
      </TwInput>

      <div class="col-2 d-flex flex-column-reverse pb-3">
        <b-button
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
          variant="primary"
          @click="modalShow = !modalShow"
        >
          <i></i> Adicionar edifício
        </b-button>
      </div>
    </div>

    <b-table
      responsive="sm"
      striped
      borderless
      hover
      class="mt-2 rounded buildings"
      no-sort-reset
      sticky-header="610px"
      show-empty
      empty-text="Nenhum registro encontrado"
      empty-filtered-text="Nenhum registro encontrado para essa busca"
      head-variant="light"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortByBuildings"
      :filter="filter"
      :busy="load"
      :fields="fieldsBuildings"
      :items="customerBuildings"
      @row-clicked="editBuilding"
      @filtered="onFiltered"
      @context-changed="changeBsTableState"
    >
      <template v-slot:table-colgroup>
        <col
          v-for="field in fieldsBuildings"
          :key="field.key"
          :style="{
            width: field.key === 'actions' ? '100px' : '550px',
          }"
        />
      </template>
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <strong>
            <i class="fas fa-sync-alt fa-spin"></i>
          </strong>
        </div>
      </template>

      <template v-slot:cell(actions)="data">
        <a
          @click="buildingDelete = data.item"
          id="deleteBuilding" 
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
          href="#"
          class="mr-3"
          data-toggle="modal"
          data-target="#modalDeleteBuilding"
          title="Excluir"
        >
          <i class="far fa-trash-alt"></i>
        </a>
        <a
          @click="editBuilding(data.item)"
          class="mr-3"
          style="cursor: pointer"
          title="Ver/Gerenciar"
          id="editBuilding"
        >
          <i style="color: #4e01d6" class="fas fa-arrow-right"></i>
        </a>
      </template>
    </b-table>
    <div class="row">
      <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="pagination"
        ></b-pagination>
      </div>
      <div class="col-12 col-md-8">
        <div class="btn-group" role="group">
          <b-dropdown
            variant="primary"
            v-model="perPage"
            :text="`Linhas: ${perPage}`"
          >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 10;
                }
              "
              >10</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 20;
                }
              "
              >20</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 50;
                }
              "
              >50</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = '1000';
                }
              "
              >1000</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>

    <b-modal v-model="modalShow" hide-footer>
      <template #modal-header="{ close }">
        <h5>Cadastrar Edifício</h5>
        <b-button variant="link" @click="close()">
          <span>X</span>
        </b-button>
      </template>
      <form @submit="saveBuilding" novalidate="true">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>
                Nome
                <span class="required">*</span>
              </label>

              <input
                v-model="building.building_name"
                maxlength="55"
                required
                type="text"
                class="form-control"
                placeholder="Nome"
                id="buildingName"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label>Endereço</label>
              <input
                v-model="building.address"
                maxlength="255"
                type="text"
                class="form-control"
                placeholder="Endereço"
                id="buildingAddress"
              />
            </div>
          </div>
        </div>

        <div class="row d-flex flex-row-reverse mt-1">
          <button
            id="saveBuilding"
            class="btn btn-primary px-5"
            :disabled="load"
          >
            <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Modal from "../../../components/Modal.vue";
import TwInput from "@/components/TwInput.vue";

export default {
  name: "BuildingTab",
  data() {
    return {
      filter: null,
      perPage: 10,
      rows: 1,
      sortBy: "",
      currentPage: 1,
      modalShow: false,
      buildingDelete: "",
      sortByBuildings: "building_name",
      fieldsBuildings: [
        { key: "building_name", sortable: true, label: "Nome" },
        { key: "address", sortable: true, label: "Endereço" },
        { key: "actions", label: "Ações", class: "actions" },
      ],
      building: {
        building_name: "",
        address: "",
        latitude: 0,
        longitude: 0,
      },
    };
  },
  watch: {
    customer() {
      if (this.customer) {
        this.$store.dispatch("building/getBuildings", {
          customer_id: this.customer.id,
        });
      }
    },
    customerBuildings() {
      if (this.$options.name) {
        const ctx = JSON.parse(
          localStorage.getItem(`bstable-state-${this.$options.name}`)
        );
        if (!ctx) {
          this.perPage = 10;
          this.currentPage = 1;
          this.sortBy = "";
          this.rows = this.customerBuildings.length;
          return;
        }
        if (ctx.filter !== "" && ctx.filter !== null) {
          this.filter = ctx.filter;
        }
        if (this.perPage !== "" && this.perPage !== null) {
          this.perPage = ctx.perPage;
        }
        if (ctx.sortBy !== "" && ctx.sortBy !== null) {
          this.sortBy = ctx.sortBy;
        }
      }
      this.rows = this.customerBuildings.length;
    },
  },
  methods: {
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    onFiltered(gateways) {
      this.rows = gateways.length;
    },
    editBuilding(data) {
      this.$store.commit("UPDATE_BUILDING", data);
      this.$router.push({
        name: "customer-building-edit",
        params: { customerId: this.customer.id, buildingId: data.id },
      });
    },
    deleteBuilding() {
      event.preventDefault();
      this.$store.dispatch("building/deleteBuilding", {
        id: this.buildingDelete.id,
      });
    },
    isBuildingValid(building) {
      if (!building.building_name || building.building_name.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Nome do Edifício" }
        );
        return false;
      }

      return true;
    },
    async saveBuilding() {
      event.preventDefault();
      if (!this.isBuildingValid(this.building)) {
        return;
      }
      try {
        this.$store.dispatch("building/saveBuilding", {
          customer: this.customer,
          building: this.building,
        });

        this.building = {
          building_name: "",
          address: "",
          latitude: 0,
          longitude: 0,
        };

        this.modalShow = false;
      } catch (err) {
        console.log(err);
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Edifício", extra: err.message }
        );
      }
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    customerBuildings() {
      return this.$store.getters["building/buildings"];
    },
  },
  components: {
    Modal,
    TwInput,
  },
};
</script>

<style>
.buildings tr {
  cursor: pointer;
}
</style>
