<template>
  <div v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD']">
    <Modal
      id="modalDeleteNotificationRule"
      title="Excluir regra de notificação"
      v-bind:msg="'Tem certeza que deseja excluir essa regra ?'"
      button="Excluir"
      :method="deleteNotificationRule"
    />
    <div class="card">
      <div class="card-body">
        <div class="row px-0">
          <TwInput label="" col_lg="10" class="pr-0">
            <b-input-group>
              <b-input-group-prepend is-text
                ><i class="fas fa-search"></i>
              </b-input-group-prepend>
              <input
              style="max-width: 900px"
                v-model="filter"
                type="search"
                class="form-control"
                id="filterInputNotification"
                placeholder="Buscar"
              />
            </b-input-group>
          </TwInput>
          <div class="col-2 d-flex flex-column-reverse pb-3">
            <b-button
              v-can="['ADMIN', 'MANAGER']"
              href="#"
              variant="primary"
              id="addrulebutton"
              @click="
                () => {
                  this.$router.push({ name: 'customer-add-notification-rule' });
                }
              "
              >Adicionar Regra
            </b-button>
          </div>
        </div>

        <b-table
          responsive="sm"
          striped
          borderless
          hover
          class="mt-3 rounded"
          no-sort-reset
          ref="selectableTable"
          sticky-header="610px"
          show-empty
          empty-text="Nenhum registro encontrado"
          empty-filtered-text="Nenhum registro encontrado para essa busca"
          selectable
          head-variant="light"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortByNotificationRules"
          :busy="load"
          :fields="fieldsNotificationRules"
          :items="customerNotificationRules"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template v-slot:table-colgroup>
            <col
              v-for="field in fieldsNotificationRules"
              :key="field.key"
              :style="{
                width: field.key === 'actions' ? '100px' : '550px',
              }"
            />
          </template>
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <strong>
                <i class="fas fa-sync-alt fa-spin"></i>
              </strong>
            </div>
          </template>

          <template v-slot:cell(actions)="data">
            <a
              id="deleteNotication"
              @click="notificationRuleDelete = data.item"
              href="#"
              class="mr-3"
              data-toggle="modal"
              data-target="#modalDeleteNotificationRule"
              title="Excluir"
              v-can="['ADMIN', 'MANAGER']"
            >
              <i class="far fa-trash-alt iconspace"></i>
            </a>
            <router-link
              :to="{
                name: 'customer-edit-notification-rule',
                params: {
                  customerId: customer ? customer.id : -1,
                  notificationRuleId: data.item.id ? data.item.id : -1,
                },
              }"
              class="mr-3"
              style="cursor: pointer"
              title="Editar"
              v-can="['ADMIN', 'MANAGER']"
              id="editNotification"
              ><i class="fas fa-pen iconspace"></i>
            </router-link>
            <router-link
              :to="{
                name: 'customer-edit-notification-rule',
                params: {
                  customerId: customer ? customer.id : -1,
                  notificationRuleId: data.item.id ? data.item.id : -1,
                },
              }"
              class="mr-3"
              style="cursor: pointer"
              title="Ver"
              v-can="['SYSADMIN']"
              id="editNotification"
              ><i class="fas fa-arrow-right"></i>
            </router-link>
          </template>
        </b-table>
        <div class="row">
          <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="pagination"
            ></b-pagination>
          </div>
          <div class="col-12 col-md-8">
            <div class="btn-group" role="group">
              <b-dropdown
                variant="primary"
                v-model="perPage"
                :text="`Linhas: ${perPage}`"
              >
                <b-dropdown-item
                  href="#"
                  @click="
                    () => {
                      this.perPage = 10;
                    }
                  "
                  >10</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="
                    () => {
                      this.perPage = 20;
                    }
                  "
                  >20</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="
                    () => {
                      this.perPage = 50;
                    }
                  "
                  >50</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="
                    () => {
                      this.perPage = '1000';
                    }
                  "
                  >1000</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <router-link
        id="addRegraNotificacao"
        :to="{
          name: 'customer-add-notification-rule',
          params: {
            customerId: customer ? customer.id : -1,
          },
        }"
        v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
      >
      </router-link>
    </div>
  </div>
</template>

<script>
import Modal from "../../../components/Modal.vue";
import TwInput from "@/components/TwInput.vue";
import "./styles.scss";

export default {
  name: "NotificationTab",
  data() {
    return {
      filter: null,
      perPage: 10,
      currentPage: 1,
      rows: 1,
      sortByNotificationRules: "notification_rule_name",
      notificationRuleDelete: "",
      fieldsNotificationRules: [
        { key: "name", sortable: true, label: "Regra" },
        { key: "status", sortable: true, label: "Status" },
        { key: "actions", label: "Ações", class: "actions" },
      ],
    };
  },
  watch: {
    customer() {
      if (this.customer) {
        this.$store.dispatch(
          "notificationRule/getNotificationRules",
          this.customer.id
        );
      }
    },
    customerNotificationRules() {
      if (this.$options.name) {
        const ctx = JSON.parse(
          localStorage.getItem(`bstable-state-${this.$options.name}`)
        );
        if (!ctx){
          this.perPage = 10;
          this.currentPage = 1;
          this.sortBy = "";
          this.rows = this.customerNotificationRules.length;
          return;
        }
        if (ctx.filter !== "" && ctx.filter !== null) {
          this.filter = ctx.filter;
        }
        if (this.perPage !== "" && this.perPage !== null) {
          this.perPage = ctx.perPage;
        }
        if (ctx.sortBy !== "") {
          this.sortBy = ctx.sortBy;
        }
        // IOT-873: Table indicates page 1, but the content is of the last page visited.
        /*if (ctx.currentPage) {
          this.currentPage = ctx.currentPage;
        }*/
      }
      this.rows = this.customerNotificationRules.length;
    },
  },
  methods: {
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    onFiltered(customerNotificationRules) {
      this.rows = customerNotificationRules.length;
    },
    deleteNotificationRule() {
      event.preventDefault();
      this.$store.dispatch("notificationRule/deleteNotificationRule", {
        customerId: this.customer.id,
        notificationRules: [this.notificationRuleDelete.id],
      });
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    customerNotificationRules() {
      return this.$store.getters["notificationRule/notificationRules"];
    },
  },
  components: {
    Modal,
    TwInput,
  },
};
</script>

<style>
@media screen and (max-width: 1040px) {
  .iconspace {
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>
