<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1>Importar coletores</h1>
    </div>
    <GatewayImportConfig
      :entireGateway="editGatewayConfig"
      @editGTWC="updateData"
    ></GatewayImportConfig>
    <div class="col-12 col-sm-12 p-0 col-md-12">
      <div class="card direct-chat direct-chat-primary">
        <div class="card-body">
          <div class="p-4 bg-white rounded">
            <div class="row">
              <div class="col-12 pr-4">
                <div class="form-group">
                  <UploadExcel
                    :on-success="handleSuccess"
                    :before-upload="beforeUpload"
                    :draggable="true"
                  />
                </div>
              </div>
            </div>

            <div v-show="tableData.length">
              <div class="content-header px-0">
                <h4>Coletores encontrados</h4>
              </div>
              <b-table
                id="gateway-import-table"
                striped
                borderless
                hover
                :fields="fields"
                :items="tableData"
                class="mt-3 rounded"
                selectable
                @row-selected="onRowSelected"
                select-mode="multi"
                ref="selectableTable"
                editableFields
              >
                <template
                  v-for="field in editableFields"
                  v-slot:[`cell(${field.key})`]="{ item }"
                >
                  <select
                    class="form-control"
                    v-if="field.key === 'gateway_model'"
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'select-' + field.key"
                  >
                    <option
                      v-for="opt in models"
                      :key="opt.name"
                      :id="'optm-' + opt.name"
                    >
                      {{ opt }}
                    </option>
                  </select>
                  <select
                    class="form-control"
                    v-if="field.key === 'gateway_type'"
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'typesel-' + field.key"
                  >
                    <option
                      v-for="opt in types"
                      :key="opt.name"
                      :id="'optt-' + opt.name"
                    >
                      {{ opt }}
                    </option>
                  </select>

                  <input
                    type="text"
                    class="form-control"
                    v-if="
                      field.key !== 'mac_address' &&
                      field.key !== 'gateway_type' &&
                      field.key !== 'gateway_model'
                    "
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'in-' + field.key"
                    @change="isGatewayValid(item)"
                  />
                  <input
                    type="text"
                    class="form-control"
                    v-mask="'NN:NN:NN:NN:NN:NN'"
                    v-if="field.key === 'mac_address'"
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'inmc2-' + field.key"
                    @change="isGatewayValid(item)"
                  />
                  <span
                    v-if="item.errors"
                    style="color: red"
                    :key="'erro-' + field.key"
                    >{{ item.errors[field.key] }}</span
                  >
                </template>
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true" class="check-symbol">&check;</span>
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                  </template>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center my-2">
                    <strong>
                      <i class="fas fa-sync-alt fa-spin"></i>
                    </strong>
                  </div>
                </template>
                <template v-slot:table-colgroup>
                  <col
                    v-for="field in fields"
                    :key="field.key"
                    :id="'clgp-' + field.key"
                    :style="{
                      width:
                        field.key === 'selected' ||
                        field.key === 'gateway_config'
                          ? '100px'
                          : '550px',
                    }"
                  />
                </template>
                <template v-slot:cell(invalid)="data">
                  <span
                    v-if="data.item.invalid"
                    class="valid-import badge badge-danger"
                    >Inválido</span
                  >
                  <span
                    v-if="!data.item.invalid"
                    class="valid-import badge badge-success"
                    >Válido</span
                  >
                </template>
                <template v-slot:cell(gateway_config)="data">
                  <div style="text-align: center">
                    <i
                      class="fas fa-xl fa-edit fa-1x"
                      style="color: gray; margin: 2px"
                      @click="editGatewayConfig = data.item"
                      data-toggle="modal"
                      data-target="#import-config-modal"
                      v-if="data.item.gateway_config"
                    ></i>
                    <i
                      v-else
                      class="fas fa-xl fa-times-circle fa-1x"
                      style="color: gray; margin: 2px"
                      title="Configuração não existente"
                    ></i>
                    <i
                      v-if="
                        (data.item.errors.gateway_config &&
                          data.item.errors.gateway_config != null) ||
                        data.item.errors.badConfig
                      "
                      class="fas fa-xl fa-times-circle"
                      style="color: red; margin: 2px"
                      title="Configuração não valida"
                    >
                    </i>
                  </div>
                </template>
              </b-table>
              <div class="row p-1">
                <button
                  id="selectAllRows"
                  type="button"
                  class="btn btn-outlined btn-primary mr-2"
                  @click="selectAllRows"
                >
                  Selecionar tudo
                </button>
                <button
                  id="clearAllRows"
                  type="button"
                  class="btn btn-outlined btn-primary mr-2"
                  @click="clearSelected"
                >
                  Desselecionar tudo
                </button>
                <button
                  id="gtw-delete-selected"
                  type="button"
                  class="btn btn-outlined btn-danger mr-2"
                  @click="deleteSelected"
                >
                  Remover selecionados
                </button>
                <button
                  id="salvarGateways"
                  @click="saveGateways"
                  class="btn btn-success px-5 mr-2"
                >
                  <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                  Coletores com Configs
                </button>
                <button
                  id="salvarConfigs"
                  @click="saveConfigs"
                  class="btn btn-success px-5 mr-2"
                >
                  <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                  Somente Configs
                </button>
              </div>
            </div>
            <span v-if="!tableData.length" style="color: blue">
              Selecione um arquivo de coletores |
              <a v-bind:href="item.loc" download>Modelo de importação xlsx</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadExcel from "@/components/UploadExcel/index.vue";
import Breadcrumb from "@/components/Breadcrumb";
import GatewayImportConfig from "@/views/Gateway/ImportConfig.vue";
import { trimObjFields } from "../../utils/format-fields";

export default {
  components: { Breadcrumb, UploadExcel, GatewayImportConfig },
  data() {
    return {
      breadcrumbList: [],
      tableData: [],
      tableHeader: [],
      storage: null,
      user: "",
      sortBy: "gateway_name",
      currentPage: 1,
      fields: [
        { key: "selected", sortable: false, label: "Selecionado" },
        {
          key: "gateway_model",
          editable: true,
          sortable: true,
          label: "Modelo",
        },
        { key: "gateway_name", editable: true, sortable: true, label: "Nome" },
        { key: "gateway_type", editable: true, sortable: true, label: "Tipo" },
        {
          key: "mac_address",
          editable: true,
          sortable: true,
          label: "Mac Address",
        },
        {
          key: "serial_number",
          editable: true,
          sortable: true,
          label: "Número de série",
        },
        { key: "gateway_config", label: "Configuração", sortable: true },
        {
          key: "invalid",
          label: "Validade",
        },
      ],
      selected: [],
      item: {
        title: "Excel template",
        loc: "/assets/files/model_import_gatways.xlsx",
      },
      filename: "",
      editGatewayConfig: {},
    };
  },
  mounted() {
    this.updateList();
    this.$store.dispatch("gateway/getModels");
    this.$store.dispatch("gateway/getTypes");
    this.$store.dispatch("gateway/get");
    this.storage =
      localStorage.getItem("rememberMe") === "true"
        ? window.localStorage
        : window.sessionStorage;
  },
  watch: {
    successGateways() {
      this.successGateways.forEach((successMacs) => {
        this.tableData = this.tableData.filter((gateway) => {
          return gateway.mac_address?.replace(/:|-/g, "") !== successMacs;
        });
      });
      this.validate();
    },
    badGateways() {
      this.badGateways.forEach((badMac) => {
        this.tableData.forEach((gateway) => {
          if (gateway.mac_address?.replace(/:|-/g, "") === badMac) {
            gateway.invalid = true;
            gateway.errors = {
              gateway_model: null,
              gateway_name: null,
              gateway_type: null,
              mac_address: null,
              gateway_config: null,
              badConfig: true,
            };
          }
        });
      });
      this.$refs.selectableTable.refresh();
    },
    gateways(){
      this.validate();
    }
  },
  methods: {
    saveGateways() {
      if(this.selected.length == 0){
        window.frontMsg(
          "FRNT_MUST_SELECT",
          { field: "Coletor"}
        );
        return;
      }
      if (this.selected.length > 0 && this.validateSelected()) {
        let sendGateways = this.selected;

        sendGateways.forEach((element) => {
          element.serial_number = element.serial_number.toString();
          // if mac_address has a : in the end, remove it
          if (element.mac_address[element.mac_address.length - 1] === ":") {
            element.mac_address = element.mac_address.slice(0, -1);
          }
          delete element.invalid;
          delete element.errors;
        });

        this.$store.dispatch("gateway/saveGateways", {
          gateways: sendGateways,
        });
      } else {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Coletores", extra: "Verifique os campos" }
        );
        return;
      }
    },
    saveConfigs() {
      if (this.selected.length > 0) {
        let sendGateways = this.selected;
        let bad = false;
        sendGateways.forEach((element) => {
          if (
            element.gateway_config &&
            this.validateGTWConfig(element.gateway_config) &&
            element.mac_address &&
            element.mac_address?.trim() !== ""
          ) {
            element.serial_number = element.serial_number.toString();
            delete element.invalid;
            delete element.errors;
          } else {
            bad = true;
            element.invalid = true;
            element.errors = {
              badConfig: true,
            };
          }
        });

        if (bad) {
          window.frontMsg(
            "FRNT_INVALID_FIELD_WARNING",
                        {
              field: "Configurações",
              extra: "Verifique config. do coletor e endereço MAC",
            }
          );
          return;
        }
        this.$store.dispatch("gateway/saveConfigs", {
          gateways: sendGateways,
        });
      } else {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Coletores", extra: "Verifique os campos" }
        );
        return;
      }
    },
    updateData(value) {
      let index = this.tableData.findIndex(
        (item) =>
          item.mac_address?.replaceAll(":", "") ==
          value.mac_address?.replaceAll(":", "")
      );
      this.tableData[index]["gateway_config"] = value;
      this.validate();
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    deleteSelected() {
      this.tableData = this.tableData.filter((e) => {
        return !this.selected.includes(e);
      });
      this.$refs.selectableTable.refresh();
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      this.filename = file.name;
      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    },
    transformDotNotation(data) {
      let transformedData = [];
      data.forEach((element) => {
        let transformedElement = {};
        for (let key in element) {
          let keyArray = key.split(".");
          let currentElement = transformedElement;
          for (let i = 0; i < keyArray.length; i++) {
            if (i === keyArray.length - 1) {
              currentElement[keyArray[i]] = element[key];
            } else {
              if (!currentElement[keyArray[i]]) {
                currentElement[keyArray[i]] = {};
              }
              currentElement = currentElement[keyArray[i]];
            }
          }
        }

        trimObjFields(transformedElement)
        transformedData.push(transformedElement);
      });
      return transformedData;
    },
    handleSuccess({ results, header }) {
      if (results.length < 50) {
        this.tableData = this.transformDotNotation(results);
        this.tableHeader = header;
        this.validate();
      } else {
        window.frontMsg(
          "FRNT_ACTION_WARNING",
          {
            reason: "a quantidade de linhas na planilha excede o limite de 50",
          }
        );
      }
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    isGatewayValid(gateway) {
      trimObjFields(gateway);
      
      gateway.errors = {
        gateway_model: null,
        gateway_name: null,
        gateway_type: null,
        mac_address: null,
        gateway_config: null,
      };
      gateway.invalid = false;
      /** Gateway model validation **/
      try {
        if (!this.models.includes(gateway.gateway_model)) {
          gateway.invalid = true;
          gateway.errors["gateway_model"] = "Modelo inválido";
        }

        /** Gateway name validation **/
        if (!gateway.gateway_name || gateway.gateway_name.trim() === "") {
          gateway.invalid = true;
          gateway.errors["gateway_name"] = "Nome não pode ser vazio";
        }

        /** Gateway type validation **/
        if (!gateway.gateway_type || gateway.gateway_type.trim() === "") {
          gateway.invalid = true;
          gateway.errors["gateway_type"] = "Tipo inválido";
        }

        /** Gateway MAC address validation **/
        let validCaracters = /^[0-9a-fA-F]+$/;
        let mac_address = gateway.mac_address?.replace(/:|-/g, "");

        if (!validCaracters.test(mac_address)) {
          gateway.invalid = true;
          gateway.errors["mac_address"] =
            "MAC Address possui caractere inválido";
        }

        if (mac_address.length !== 12) {
          gateway.invalid = true;
          gateway.errors["mac_address"] = "MAC Address inválido";
        }

        let checkMac = (obj) => obj.mac_address === mac_address;

        let checkMac2 = (obj) => 
          obj.mac_address ? obj.mac_address.toString().replace(/:|-/g, "") === mac_address : "";

        const repeat_mac = this.tableData.filter(checkMac2);

        if (repeat_mac.length > 1) {
          gateway.invalid = true;
          gateway.errors["mac_address"] =
            "MAC Address duplicado nessa importação";
        }

        if (this.gateways.some(checkMac)) {
          gateway.invalid = true;
          gateway.errors["mac_address"] = "MAC Address já cadastrado";
        }

        gateway.mac_address = mac_address?.replace(/(.{2})/g, "$1:");
        gateway.mac_address = gateway.mac_address.toLowerCase();
        /** Gateway serial_number validation **/
        let checkSerialNumber = (obj) =>
          obj.serial_number === gateway.serial_number;

        const repeat_serial_number = this.tableData.filter(checkSerialNumber);

        if (repeat_serial_number.length > 1) {
          gateway.invalid = true;
          gateway.errors["serial_number"] =
            "Número de série duplicado nessa importação";
        }

        if (this.gateways.some(checkSerialNumber)) {
          gateway.invalid = true;
          gateway.errors["serial_number"] = "Número de série já cadastrado";
        }

        if (
          gateway.gateway_config &&
          gateway.gateway_config != {} &&
          !this.validateGTWConfig(gateway.gateway_config)
        ) {
          gateway.invalid = true;
          gateway.errors["gateway_config"] = "Configuração inválida";
        }
      } catch (e) {
        gateway.invalid = true;
        console.log(e);
        if (!gateway.serial_number)
          gateway.errors["serial_number"] = "Número de série não importado";

        if (!gateway.mac_address)
          gateway.errors["mac_address"] = "MAC Address não importado";
      }
      return !gateway.invalid;
    },
    validate() {
      this.tableData.forEach((e) => this.isGatewayValid(e));

      const valido = !this.tableData.some((e) => e.invalid === true);

      this.$refs.selectableTable.refresh();
      return valido;
    },
    validateSelected() {
      this.selected.forEach((e) => this.isGatewayValid(e));
      const valido = !this.selected.some((e) => e.invalid === true);

      this.$refs.selectableTable.refresh();
      return valido;
    },
    validateGTWConfig(gtw_config) {
      if (gtw_config.ble.rssi_limit == "") {
        return false;
      }
      /** App validation **/
      if (gtw_config.app.id === "") {
        return false;
      }
      if (
        /^([0-9A-Fa-f]{8}[-])([0-9A-Fa-f]{4}[-]){4}([0-9A-Fa-f]{12})$/i.test(
          gtw_config.app.id
        )
      ) {
        return false;
      }
      if (gtw_config.app.name === "") {
        return false;
      }

      if (gtw_config.app.pwd === "") {
        return false;
      }
      if (gtw_config.app.server === "") {
        return false;
      }
      /** Send information validation **/
      if (gtw_config.app.send_status_interval == "") {
        return false;
      }
      if (gtw_config.app.send_data_interval.trim == "") {
        return false;
      }
      if (gtw_config.app.send_battery_interval == "") {
        return false;
      }
      if (gtw_config.app.send_health_interval == "") {
        return false;
      }
      // TODO: Refactor
      if (gtw_config.transmission == "mqtt") {
        if (gtw_config.mqtt.host === "") {
          return false;
        }
        if (gtw_config.mqtt.port === "") {
          return false;
        }
        if (gtw_config.mqtt.username === "") {
          return false;
        }
        if (gtw_config.mqtt.password === "") {
          return false;
        }
        if (gtw_config.mqtt.keepalive === "") {
          return false;
        }
        if (gtw_config.mqtt.qos === "") {
          return false;
        }
        if (gtw_config.mqtt.status_topic === "") {
          return false;
        }
        if (gtw_config.mqtt.battery_topic === "") {
          return false;
        }
        if (gtw_config.mqtt.data_topic === "") {
          return false;
        }
        if (gtw_config.mqtt.health_topic === "") {
          return false;
        }
      } else {
        if (gtw_config.http.url === "") {
          return false;
        }
        if (gtw_config.http.token === "") {
          return false;
        }
        if (gtw_config.http.entries === "") {
          return false;
        }
        if (gtw_config.http.status_route === "") {
          return false;
        }
        if (gtw_config.http.battery_route === "") {
          return false;
        }
        if (gtw_config.http.data_route === "") {
          return false;
        }
        if (gtw_config.http.health_route === "") {
          return false;
        }
      }
      return true;
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    editableFields() {
      return this.fields.filter((field) => field.editable);
    },
    models() {
      return this.$store.getters["gateway/models"].map((e) => {
        return e.name;
      });
    },
    types() {
      return this.$store.getters["gateway/types"].map((e) => {
        return e.name;
      });
    },
    gateways() {
      return this.$store.getters["gateway/gateways"];
    },
    successGateways() {
      return this.$store.getters["gateway/successGateways"];
    },
    badGateways() {
      return this.$store.getters["gateway/badGateways"];
    },
  },
};
</script>

<style></style>

<style lang="scss" scoped>
.check-symbol {
  font-size: 3rem;
  margin-top: -30px;
  margin-left: 15px;
  position: absolute;
  display: block;
  text-align: center;
  user-select: none;
}

.valid-import {
  width: 100px;
  font-size: 20px;
  padding: 10px;
  border-radius: 50px;
  transition: none;
}
</style>
