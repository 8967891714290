import { render, staticRenderFns } from "./Import.vue?vue&type=template&id=79140531&scoped=true"
import script from "./Import.vue?vue&type=script&lang=js"
export * from "./Import.vue?vue&type=script&lang=js"
import style1 from "./Import.vue?vue&type=style&index=1&id=79140531&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79140531",
  null
  
)

export default component.exports