<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />

    <div class="content-header px-0">
      <h1 class="title-I">Editar Grupo</h1>
    </div>

    <div class="col-12 col-sm-12 p-0 col-md-12">
      <div class="card direct-chat direct-chat-primary">
        <div class="card-body">
          <div class="p-4 bg-white rounded">
            <div class="row">
              <TwInput label="Nome do Grupo" required="false">
                <input
                  id="groupname"
                  disabled
                  v-model="group_name"
                  type="text"
                  maxlength="45"
                  class="form-control"
                  placeholder="Nome do grupo"
                />
              </TwInput>
            </div>
            <div>
              <p class="col-6 row" style="font-size: 18px">Licenças</p>
              <hr />
              <div id="licenses" class="col-12 row">
                <div
                  :class="'col-2 row-10 circle_title circle-' + license.role"
                  v-for="license in licenses"
                  :v-key="'circle-' + license.role"
                  :key="'circle-' + license.role"
                  :v-if="license.used_license"
                  :id="'extern-circle' + license.role"
                >
                  <p :id="'title-' + license.role">{{ license.role }}</p>
                  <div
                    :class="'circle-' + license.role"
                    style="width: 100%"
                    height="10rem"
                    :id="'id-circle-' + license.role"
                  ></div>
                </div>
              </div>
              <div :v-can="['ADMIN']">
                <p class="col-6 row" style="font-size: 18px">Editar licenças</p>
                <hr />
                <form id="edit-licenses" class="col-12 row">
                  <TwInput
                    v-for="license in licenses_board"
                    :key="license.role"
                    :label="license.role"
                    required="true"
                    col_lg="2"
                    :id="'twinput-' + license.role"
                    :disabled="!hasRole(['ADMIN'])"
                  >
                    <input
                      type="number"
                      max="2147483647"
                      min="0"
                      :id="'input-' + license.role"
                      v-model="license.total_licenses"
                      class="form-control"
                      :disabled="!hasRole(['ADMIN'])"
                    />
                  </TwInput>
                </form>
              </div>
            </div>
            <div class="row mt-2">
              <TwInput label="Power BI">
                <b-form-checkbox
                  id="pbi"
                  v-model="pbi"
                  disabled
                  name="pbi"
                  size="md"
                >
                  Grupo associado ao Power BI?
                </b-form-checkbox>
              </TwInput>
            </div>
            <div :v-can="['ADMIN']">
              <p class="col-12 row" style="font-size: 18px">Configurações de login</p>
                <hr/>
                <form class="col-12 row">
                <TwInput label="Tentativas de login">
                  <i
                    title="Número máximo de tentativas de login erradas antes do usuário ser bloqueado"
                    class="fas fa-question-circle"
                    style="margin-left: 5px"
                  />
                  <input
                    id="max_attempts"
                    v-model="authen_config.max_attempts"
                    type="number"
                    :min="1"
                    :max="999"
                    class="form-control"
                    placeholder="10"
                    :disabled="!hasRole(['ADMIN'])"
                  />
                </TwInput>  
                <TwInput label="Expiração de senha (Dias)">
                  <i
                    title="Número de dias para a expiração da senha. Após esse período a senha deve ser redefinida"
                    class="fas fa-question-circle"
                    style="margin-left: 5px"
                  />
                <input
                  id="password_expiration"
                  v-model="authen_config.password_expiration"
                  type="number"
                  :min="1"
                  :max="999"
                  class="form-control"
                  placeholder="180"
                  :disabled="!hasRole(['ADMIN'])"
                />
              </TwInput>
            </form>
            </div>
            <div class="row mt-2" v-if="pbi">
              <TwInput label="Nome do Workgroup" required="false">
                <input
                  id="workgroupname"
                  v-model="new_workgroup"
                  type="text"
                  maxlength="55"
                  class="form-control"
                  placeholder="Nome do Workgrupo"
                  :disabled="!hasRole('ADMIN')"
                />
              </TwInput>
              <div style="padding: 3.1%" v-can="['ADMIN']">
                <button
                  id="addnewworkgroup"
                  class="btn btn-primary px-5"
                  @click="addWorkgroup"
                  :disabled="load"
                >
                  <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Adicionar
                </button>
              </div>
            </div>
            <b-table
              v-if="pbi"
              responsive="sm"
              striped
              borderless
              hover
              class="mt-2 rounded"
              no-sort-reset
              ref="selectableTable"
              sticky-header="610px"
              show-empty
              empty-text="Nenhum registro encontrado"
              empty-filtered-text="Nenhum grupo encontrado para essa busca"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              @context-changed="changeBsTableState"
              :busy="load"
              :fields="fields"
              :items="workgroups"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center my-2">
                  <strong>
                    <i class="fas fa-sync-alt fa-spin"></i>
                  </strong>
                </div>
              </template>
              <template v-slot:table-colgroup>
                <col
                  v-for="field in fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '50px' : '550px' }"
                />
              </template>
              <template v-slot:cell(actions)="data">
                <a
                  id="excluirWorkgroup"
                  @click="workgroupDelete = data.item"
                  v-can="['ADMIN']"
                  href="#"
                  class="mr-2"
                  data-toggle="modal"
                  data-target="#ModalDeleteWorkgroup"
                  title="Excluir"
                >
                  <i class="far fa-trash-alt"></i>
                </a>
              </template>
            </b-table>
            <div style="padding: 3.1%" v-can="['ADMIN']">
              <button
                id="savegroup"
                class="btn btn-primary px-5"
                @click="updateGroup"
                :disabled="load"
              >
                <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
              </button>
            </div>
            <Modal
              id="ModalDeleteWorkgroup"
              @click="workgroupDelete = data.item"
              title="Excluir Workgrupo"
              v-bind:msg="
                'Tem certeza que deseja excluir ' + workgroupDelete.name + '?'
              "
              button="Excluir"
              :method="deleteWorkgroup"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import TwInput from "@/components/TwInput.vue";
import Modal from "../../components/Modal.vue";
import CircleProgress from "js-circle-progress";
import hasRole from "@/utils/hasRole";

export default {
  name: "EditGroup",
  data: function () {
    return {
      breadcrumbList: [],
      group_name: "",
      new_workgroup: "",
      pbi: true,
      tfa: false,
      filter: null,
      perPage: 30,
      sortBy: "name",
      currentPage: 1,
      rows: 1,
      workgroupDelete: "",
      fields: [
        { key: "name", label: "Nome" },
        { key: "actions", label: "Ações", class: "actions" },
      ],
      licenses: [],
      licenses_circle: [],
      licenses_board: [],
      //authen_config with default values
      authen_config: {
        max_attempts:10,
        password_expiration:180,
      },
    };
  },
  created() {
    this.$store.dispatch("user/getGroup", {
      group_name: this.$route.params.groupName,
    });
    this.$store.dispatch("user/getWorkgroups", {
      group_name: this.$route.params.groupName,
    });
  },
  updated() {
    if (this.licenses.length > 0 && this.licenses_circle.length == 0) {
      for (const element of this.licenses) {
        let t = new CircleProgress(".circle-" + element.role, {
          value: element.used_licenses,
          max: element.total_licenses,
          textFormat: "horizontal",
        });
        this.licenses_circle.push(t);
      }
    }
  },
  watch: {
    $route() {
      this.updateList();
    },
    group() {
      // Save to localstorage when group is updated
      localStorage.setItem("groupdata", JSON.stringify(this.group));
      this.group_name = this.group.name;
      this.tfa = this.group.tfa;
      this.pbi = this.group.pbi;
      this.authen_config.max_attempts=this.group.authen_config.max_attempts;
      this.authen_config.password_expiration=this.group.authen_config.password_expiration;
      this.licenses = this.group.licenses
        .filter((element) => {
          return element.total_licenses > 0;
        })
        .sort((a, b) => this.sortLicenses(a, b));
      this.licenses_board = this.group.licenses.sort((a, b) =>
        this.sortLicenses(a, b)
      );
    },
  },
  methods: {
    hasRole,
    sortLicenses(a, b) {
      if (a.role < b.role) {
        return -1;
      }
      if (a.role > b.role) {
        return 1;
      }
      return 0;
    },
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    onFiltered(groups) {
      this.rows = groups.length;
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    addWorkgroup() {
      if (this.new_workgroup != "" && !this.new_workgroup.includes(" ")) {
        this.$store.dispatch("user/addWorkgroup", {
          group_name: this.$route.params.groupName,
          workgroup_name: this.new_workgroup,
        });
        this.updateList();
      } else if (this.new_workgroup != "") {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
          field: "Nome do Grupo",
          extra: "Apenas letras, números, hífen e underline são permitidos",
        });
      } else {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Nome do Workgroup" });
      }
    },
    deleteWorkgroup() {
      event.preventDefault();
      this.$store.dispatch("user/deleteWorkgroup", {
        group_name: this.$route.params.groupName,
        workgroup_name: this.workgroupDelete,
      });
    },
    updateGroup() {
      event.preventDefault();
      let lic_env = [];
      let form = document.getElementById("edit-licenses");
      if (!form.reportValidity()) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Número de Licenças",
          numbercondition: "dentro do intervalo entre zero e o limite máximo",
        });
        return;
      }
      if (this.authen_config.max_attempts>999||this.authen_config.max_attempts<1) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Número de Licenças",
          numbercondition: "dentro do intervalo de 1 e 999",
        });
        return;
      }
      if (this.authen_config.password_expiration>999||this.authen_config.password_expiration<1) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Expiração de senha (Dias)",
          numbercondition: "dentro do intervalo de 1 e 999 dias",
        });
        return;
      }
      for (let license of this.licenses_board) {
        if (license.total_licenses < 0 || license.total_licenses === "") {
          window.frontMsg("FRNT_NUM_RANGE_WARNING", {
            field: "Tentativas de login",
            numbercondition: "dentro do intervalo entre zero e o limite máximo",
          });
          return;
        }
        lic_env.push({
          role: license.role,
          total_licenses: license.total_licenses,
        });
      }
      this.$store.dispatch("user/updateGroup", {
        name: this.group_name,
        tfa: this.tfa,
        licenses: lic_env,
        authen_config: {
          max_attempts:this.authen_config.max_attempts,
          password_expiration: this.authen_config.password_expiration,
      },
      });
    },
  },
  components: {
    Breadcrumb,
    Modal,
    TwInput,
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    group() {
      return this.$store.getters["user/group"];
    },
    workgroups() {
      return this.$store.getters["user/workgroups"];
    },
  },
};
</script>
<style lang="scss" scoped>
.circle_title {
  padding: 10px;
  text-align: center;
}
</style>
