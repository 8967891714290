<template>
  <div v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']">
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Editar colaborador</h1>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card direct-chat direct-chat-primary employee">
          <form @submit="updateEmployee">
            <div class="card-body">
              <div class="pt-4 pr-4 pb-2 pl-4 bg-white rounded">
                <div class="row">
                  <TwInput col_lg="12" label="Nome" required="false">
                    <input
                      v-model="employee.name"
                      required
                      type="text"
                      maxlength="55"
                      class="form-control"
                      placeholder="Nome do colaborador"
                      id="employeeName"
                      :disabled="isDisable()"
                    />
                  </TwInput>
                </div>
                <div class="row">
                  <TwInput label="E-mail">
                    <input
                      v-model="employee.email"
                      type="email"
                      maxlength="55"
                      class="form-control"
                      placeholder="E-mail"
                      id="employeeEmail"
                      :disabled="isDisable()"
                    />
                  </TwInput>
                  <TwInput label="Celular">
                    <input
                      v-model="employee.cellphone"
                      v-mask="'+(##) ## #####-####'"
                      type="text"
                      class="form-control"
                      placeholder="Celular"
                      id="employeeCellphone"
                      :disabled="isDisable()"
                    />
                  </TwInput>
                </div>
                <div class="row mb-2">
                  <TwInput label="CPF">
                    <input
                      v-model="employee.cpf"
                      v-mask="'###.###.###-##'"
                      type="text"
                      class="form-control"
                      placeholder="CPF"
                      id="employeeCpf"
                      :disabled="isDisable()"
                    />
                  </TwInput>
                  <TwInput label="Número de registro" required="false">
                    <input
                      v-model="employee.registration_number"
                      required
                      type="text"
                      maxlength="20"
                      class="form-control"
                      placeholder="Número de registro"
                      id="employeeRegistration"
                      :disabled="isDisable()"
                    />
                  </TwInput>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <div class="card direct-chat direct-chat-primary">
                      <div class="card-body custom-card overflow-visible">
                        <ul class="nav nav-tabs bg-main">
                          <li class="nav-item">
                            <a
                              class="nav-link first"
                              v-bind:class="{
                                active: this.tabSelected === 'functionalData',
                              }"
                              href="#"
                              v-on:click="changeTab('functionalData')"
                              aria-current="dados funcionais"
                              tabindex="-1"
                              >Dados funcionais</a
                            >
                          </li>
                          <li class="nav-item">
                            <a
                              id="training-tab"
                              class="nav-link"
                              v-bind:class="{
                                active: this.tabSelected === 'training',
                              }"
                              v-on:click="changeTab('training')"
                              href="javascript:void(0);"
                              aria-current="training"
                              tabindex="-1"
                              >Treinamentos</a
                            >
                          </li>
                          <li class="nav-item">
                            <a
                              id="anamnesis-tab"
                              class="nav-link"
                              v-bind:class="{
                                active: anamnesisSelected,
                              }"
                              v-on:click="changeTab('anamnesis')"
                              href="#"
                              aria-current="anamnese"
                              tabindex="-1"
                              >Anamnese</a
                            >
                          </li>
                        </ul>
                        <div class="p-4 bg-white rounded">
                          <div class="tab-content">
                            <div
                              class="tab-pane fade"
                              v-bind:class="{
                                show: anamnesisSelected,
                                active: anamnesisSelected,
                              }"
                            >
                              <div class="row">
                                <TwInput col_lg="4" label="Peso (kg)">
                                  <input
                                    v-model="employee.anamnesis_data.weight"
                                    type="number"
                                    class="form-control"
                                    placeholder="Peso"
                                    id="employeeWeight"
                                    :max="1000"
                                    :disabled="employee.isDisable"
                                  />
                                </TwInput>
                                <TwInput col_lg="4" label="Altura (m)">
                                  <input
                                    v-model="employee.anamnesis_data.height"
                                    type="number"
                                    :step="0.01"
                                    :max="3"
                                    class="form-control"
                                    placeholder="Altura"
                                    id="employeeHeight"
                                    :disabled="isDisable()"
                                  />
                                </TwInput>
                                <TwInput col_lg="4" label="Fumante">
                                  <select
                                    v-model="employee.anamnesis_data.smoking"
                                    class="form-control"
                                    id="smokerForm"
                                    :disabled="isDisable()"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                  </select>
                                </TwInput>
                              </div>
                              <div class="row">
                                <TwInput
                                  col_lg="4"
                                  label="Doenças Pré-existentes"
                                >
                                  <div class="input-group">
                                    <input
                                      id="edit-diseases-list"
                                      type="text"
                                      class="form-control"
                                      v-model="diseasesList"
                                      :disabled="isDisable()"
                                    />
                                    <div class="input-group-append">
                                      <button
                                        class="btn btn-outline-secondary dropdown-toggle"
                                        type="button"
                                        data-toggle="dropdown"
                                        id="diseaseDropdown"
                                      ></button>
                                      <div class="dropdown-menu">
                                        <span
                                          class="dropdown-item"
                                          v-for="(
                                            disease, index
                                          ) in preexisting_conditions_options"
                                          :key="index"
                                          v-on:click="selectDisease(disease)"
                                        >
                                          {{ disease }}
                                        </span>
                                        <span
                                          class="dropdown-item"
                                          href="#"
                                          v-on:click="selectDisease('Outra')"
                                          >Outra</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </TwInput>
                                <TwInput col_lg="4" label="Atividade Física">
                                  <select
                                    v-model="
                                      employee.anamnesis_data.physical_activity
                                    "
                                    class="form-control"
                                    id="physicalactivitySelect"
                                    :disabled="isDisable()"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="0x">
                                      Não pratica exercícios
                                    </option>
                                    <option value="1x">1x por semana</option>
                                    <option value="2x">2x por semana</option>
                                    <option value="3x">3x por semana</option>
                                    <option value="4x">4x por semana</option>
                                    <option value="5+">5+ por semana</option>
                                  </select>
                                </TwInput>
                                <TwInput
                                  col_lg="4"
                                  label="Medicamentos de uso contínuo"
                                >
                                  <select
                                    v-model="
                                      employee.anamnesis_data
                                        .medicines_for_continuous_use
                                    "
                                    class="form-control"
                                    id="medicinesSelect"
                                    :disabled="isDisable()"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                  </select>
                                </TwInput>
                              </div>
                              <div class="row">
                                <TwInput col_lg="4" label="Pressão arterial">
                                  <select
                                    v-model="
                                      employee.anamnesis_data
                                        .blood_pressure_level
                                    "
                                    class="form-control"
                                    id="pressureSelect"
                                    :disabled="isDisable()"
                                  >
                                    <option value="">Selecione</option>
                                    <option
                                      v-for="(
                                        blood_pressure_level, index
                                      ) in blood_pressure_level_options"
                                      :key="index"
                                      :value="blood_pressure_level.val"
                                    >
                                      {{ blood_pressure_level.option }}
                                    </option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Gravidez">
                                  <select
                                    v-model="employee.anamnesis_data.pregnancy"
                                    class="form-control"
                                    id="pregnancySelect"
                                    :disabled="isDisable()"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Data (último ASO)">
                                  <input
                                    class="form-control"
                                    type="date"
                                    :min="'1900-01-01'"
                                    :max="
                                      new Date().toISOString().split('T')[0]
                                    "
                                    v-model="formatedLastOHC"
                                    id="ohcSelect"
                                    :disabled="isDisable()"
                                  />
                                </TwInput>
                              </div>
                            </div>
                            <div
                              class="tab-pane fade"
                              v-bind:class="{
                                show: functionalDataSelected,
                                active: functionalDataSelected,
                              }"
                            >
                              <div class="row">
                                <TwInput col_lg="4" label="Área">
                                  <select
                                    v-model="employee.functional_data.area"
                                    class="form-control"
                                    id="areaSelect"
                                    :disabled="isDisable()"
                                  >
                                    <option value="">Selecione</option>
                                    <option
                                      v-for="(area, index) in area_options"
                                      :key="index"
                                      :value="area.toLowerCase()"
                                    >
                                      {{ area }}
                                    </option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Setor">
                                  <select
                                    v-model="
                                      employee.functional_data.department
                                    "
                                    class="form-control"
                                    id="departmentSelect"
                                    :disabled="isDisable()"
                                  >
                                    <option value="">Selecione</option>
                                    <option
                                      v-for="(
                                        department, index
                                      ) in department_options"
                                      :key="index"
                                      :value="department.toLowerCase()"
                                    >
                                      {{ department }}
                                    </option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Cargo">
                                  <select
                                    v-model="employee.functional_data.position"
                                    class="form-control"
                                    id="positionSelect"
                                    :disabled="isDisable()"
                                  >
                                    <option value="">Selecione</option>
                                    <option
                                      v-for="(
                                        position, index
                                      ) in position_options"
                                      :key="index"
                                      :value="position.toLowerCase()"
                                    >
                                      {{ position }}
                                    </option>
                                  </select>
                                </TwInput>
                              </div>
                              <div class="row">
                                <TwInput col_lg="4" label="Turno">
                                  <select
                                    v-model="
                                      employee.functional_data.work_shift
                                    "
                                    class="form-control"
                                    id="workshiftSelect"
                                    :disabled="isDisable()"
                                  >
                                    <option value="">Selecione</option>
                                    <option
                                      v-for="(
                                        work_shift, index
                                      ) in work_shift_options"
                                      :key="index"
                                      :value="work_shift.toLowerCase()"
                                    >
                                      {{ work_shift }}
                                    </option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Jornada (h)">
                                  <input
                                    id="edit-employee-work-shift"
                                    v-model="employee.functional_data.work_week"
                                    type="number"
                                    class="form-control"
                                    placeholder="44"
                                    :max="168"
                                    :disabled="isDisable()"
                                  />
                                </TwInput>

                                <TwInput col_lg="4" label="Gestor">
                                  <input
                                    id="edit-employee-supervisor"
                                    v-model="employeeSupervisor"
                                    type="text"
                                    autocomplete="off"
                                    class="form-control filter-input"
                                    placeholder="Gestor"
                                    :disabled="isDisable()"
                                  />
                                  <div
                                    v-if="filteredSupervisors.length"
                                    class="autocomplete"
                                  >
                                    <div
                                      class="sup"
                                      @click="onSelectSupervisor('none')"
                                    >
                                      <b>LIMPAR SELEÇÃO</b>
                                    </div>
                                    <div
                                      v-for="sup in filteredSupervisors"
                                      class="sup"
                                      :key="sup.id"
                                      @click="onSelectSupervisor(sup)"
                                    >
                                      <b>{{ sup.name }}</b>
                                    </div>
                                  </div>
                                </TwInput>
                              </div>
                              <div class="row">
                                <TwInput col_lg="4" label="Plano de saúde">
                                  <select
                                    v-model="
                                      employee.functional_data.health_plan
                                    "
                                    class="form-control"
                                    id="healthplanSelect"
                                    :disabled="isDisable()"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Gênero">
                                  <select
                                    v-model="employee.gender"
                                    class="form-control"
                                    id="genderSelect"
                                    :disabled="isDisable()"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="FEMININO">Feminino</option>
                                    <option value="MASCULINO">Masculino</option>
                                    <option value="NAO-IDENTIFICADO">
                                      Não informado
                                    </option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Data de nascimento">
                                  <input
                                    id="edit-employee-birth-date"
                                    class="form-control"
                                    type="date"
                                    :min="'1900-01-01'"
                                    :max="
                                      new Date().toISOString().split('T')[0]
                                    "
                                    v-model="formatedDateOfBirth"
                                    :disabled="isDisable()"
                                  />
                                </TwInput>
                              </div>
                              <div class="row">
                                <TwInput col_lg="4" label="Data de admissão">
                                  <input
                                    id="edit-employee-hire-date"
                                    class="form-control"
                                    type="date"
                                    :min="'1900-01-01'"
                                    :max="
                                      new Date().toISOString().split('T')[0]
                                    "
                                    v-model="formatedDateOfHire"
                                    :disabled="isDisable()"
                                  />
                                </TwInput>
                                <TwInput col_lg="4" label="Estado">
                                  <select
                                    v-model="employee.functional_data.state"
                                    class="form-control"
                                    id="stateSelect"
                                    @change="
                                      changeState(
                                        employee.functional_data.state
                                      )
                                    "
                                  >
                                    <option value="HIRED">Ativo</option>
                                    <option value="FIRED">Excluído</option>
                                    <option :disabled="isDisable()" value="ON-LEAVE">Afastado</option>
                                    <option :disabled="isDisable()" value="VACATION">Férias</option>
                                  </select>
                                </TwInput>
                                <TwInput
                                  col_lg="4"
                                  label="Data de desligamento"
                                >
                                  <input
                                    id="edit-employee-dismissal-date"
                                    class="form-control"
                                    type="date"
                                    :min="formatedDateOfHire"
                                    :max="
                                      new Date().toISOString().split('T')[0]
                                    "
                                    v-model="formatedDateOfDismissal"
                                    :disabled="
                                      employee.functional_data.state !== 'FIRED'
                                    "
                                  />
                                </TwInput>
                              </div>
                            </div>
                            <div
                              class="tab-pane fade"
                              v-bind:class="{
                                show: trainingSelected,
                                active: trainingSelected,
                              }"
                            >
                              <div>
                                <b-table
                                  striped
                                  hover
                                  :items="employee.trainings"
                                  :fields="fields_training"
                                  ref="employee_trainings_table"
                                  :disabled="isDisable()"
                                >
                                  <template
                                    v-slot:cell(name)="data"
                                  >
                                    <select
                                      v-model="
                                      data.item.name"
                                      class="form-control"
                                      id="trainingSelect"
                                      :disabled="isDisable()"
                                      @change="onSelectTraining(data.item)"
                                    >
                                      <option value="">Selecione</option>
                                      <option
                                        v-for="
                                          training in customerTrainings"
                                        :key="training.id"
                                        :value="training.name"
                                        :disabled="training.selected"
                                      >
                                        {{ training.name }}
                                      </option>
                                    </select>
                                  </template>
                                  <template
                                    v-slot:cell(session)="data"
                                  >
                                    <select
                                      v-model="data.item.session"
                                      class="form-control"
                                      id="trainingScheduleSelect"
                                      @change="onSelectSession(data.item)"
                                      :disabled="data.item.schedules?.length === 0 || isDisable()"
                                    >
                                      <option value="">Selecione</option>
                                      <option
                                        v-for="
                                          schedule in data.item.schedules"
                                        :key="schedule.id"
                                        :value="schedule"
                                      >
                                        {{ "Instrutor: " + schedule.instructor + ". Início: " + schedule.t_start + ". Término: " + schedule.t_end}}
                                      </option>
                                    </select>
                                  </template>
                                  <template
                                  v-slot:cell(expiration)="data"
                                  >
                                    <b-form-input
                                      type="date"
                                      v-model="data.item.expiration"
                                      placeholder="Validade do treinamento"
                                      id="trainingExpiration"
                                      :min='"1900-01-01"'
                                      :max='"2099-12-31"'
                                      :disabled="true"
                                    />
                                  </template>
                                  <template
                                      v-slot:cell(attendence)="data"
                                    >
                                      <b-form-input
                                        type="number"
                                        v-model="data.item.attendence"
                                        placeholder="Frequência"
                                        id="trainingFrequency"
                                        step="1"
                                        max="100"
                                        min="0"
                                        :disabled="isDisable()"
                                      />
                                  </template>
                                  <template
                                  v-slot:cell(grade)="data"
                                  >
                                    <b-form-input
                                      type="number"
                                      v-model="data.item.grade"
                                      placeholder="Nota"
                                      id="trainingGrade"
                                      max="100"
                                      min="0"
                                      :disabled="isDisable()"
                                    />
                                  </template>
                                  <template
                                  v-slot:cell(status)="data"
                                  >
                                    <select
                                    v-model="
                                    data.item.status"
                                    class="form-control"
                                    id="trainingStatusSelect"
                                    :disabled="true"
                                    >
                                      <option value="">Selecione</option>
                                      <option
                                        v-for="
                                          status_val in training_status_options"
                                          :key="status_val.val"
                                          :value="status_val.val"
                                      >
                                        {{ status_val.option }}
                                      </option>
                                    </select>
                                  </template>
                                  <template v-slot:cell(actions)="data">
                                    <b-button
                                      variant="danger"
                                      @click="removeRow(data.index)"
                                      :disabled="isDisable()"
                                    >
                                      <i class="far fa-trash-alt"></i>
                                    </b-button>
                                  </template>
                                  <template #table-caption>
                                    <div style="display: flex; justify-content: center;">
                                      <b-button
                                        class="btn btn-primary"
                                        @click="addRow()"
                                        :disabled="isDisable()"
                                      >
                                        <i class="fa fa-add"></i>
                                      </b-button>
                                    </div>
                                  </template>
                                </b-table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-3 pl-2">
                  <button
                    id="saveEmployee"
                    class="btn btn-primary px-5"
                    :disabled="load"
                  >
                    <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import { validCPF } from "../../services/helpers";
import { formatDate, formatDateAPI } from "../../utils/format-fields";
import { nullEmptyProps } from "../../utils/format-json";
import "./style.scss";
import TwInput from "@/components/TwInput.vue";

export default {
  name: "EditEmployee",
  data: function () {
    return {
      breadcrumbList: [],
      tabSelected: "functionalData",
      preexisting_conditions_options: [
        "Asma",
        "Cardiopatia",
        "Diabetes",
        "Hepatite",
      ],
      fields_training: [
        {
          key: "name",
          label: "Treinamento",
        },
        {
          key: "session",
          label: "Turma",
        },
        {
          key: "expiration",
          label: "Expiração",
        },
        {
          key: "attendence",
          label: "Frequência (%)",
        },
        {
          key: "grade",
          label: "Nota (Máx.: 100)",
        },
        {
          key: "status",
          label: "Estado"
        },
        {
          key: "actions",
          label: "Ações"
        },
      ],
      training_status_options:[
        { val: "UNASSIGNED", option: "Não Definido" },
        { val: "SCHEDULED", option: "Agendado" },
        { val: "IN-PROGRESS", option: "Em progresso" },
        { val: "COMPLETED", option: "Aprovado" },
        { val: "EXPIRED", option: "Expirado" },
        { val: "CANCELED", option: "Reprovado" },
      ],
      work_shift_options: ["Administrativo", "Primeiro", "Segundo", "Terceiro"],
      blood_pressure_level_options: [
        { val: "BAIXO", option: "BAIXA" },
        { val: "NORMAL", option: "NORMAL" },
        { val: "ELEVADO", option: "ELEVADO" },
        { val: "HIPERTENSAO-ESTAGIO-1", option: "HIPERTENSÃO Estágio I" },
        { val: "HIPERTENSAO-ESTAGIO-2", option: "HIPERTENSÃO Estágio II" },
        { val: "CRISE-HIPERTENSIVA", option: "CRISE HIPERTENSIVA" },
      ],

      employee: {
        id: "",
        name: "",
        email: "",
        cellphone: "",
        cpf: "",
        registration_number: "",
        gender: "",
        date_of_birth: "",
        anamnesis_data: {
          height: "",
          weight: "",
          smoking: "",
          preexisting_conditions: [],
          physical_activity: "",
          medicines_for_continuous_use: "",
          blood_pressure_level: "",
          pregnancy: "",
        },
        functional_data: {
          area: "",
          department: "",
          position: "",
          work_shift: "",
          work_week: "",
          state: "",
          date_of_hire: "",
          date_of_dismissal: "",
          supervisor: {
            name: "",
            registration_number: "",
          },
          last_ohc: "",
          health_plan: "",
        },
      },
      trainings:[{
        attendence:0,
        duration:0,
        expiration:"",
        grade:0,
        id:0,
        name:"",
        status:"UNASSIGNED",
        schedules: [],
        session: "",
      }],
      employeeSupervisor: "",
      employeeTags: "",
      empty_training: {
        attendence:0,
        duration:0,
        expiration:"",
        grade:0,
        id:0,
        name:"",
        status:"UNASSIGNED",
        schedules: [],
        session: "",
      },
    };
  },
  beforeMount() {
    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getEmployees", {
        customer_id: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getAreas", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getDepartments", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getPositions", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("training/getCustomerAllTrainings", {
        customer_id: this.$route.params.customerId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
      this.$router.push("/");
    }

    if (this.$route.params.employeeId) {
      this.$store.dispatch("employee/getEmployee", {
        employeeId: this.$route.params.employeeId,
      });

      this.$store.dispatch("tags/getEmployeeTags", {
        employee_id: this.$route.params.employeeId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", {
        frontelement: "do Colaborador",
      });
      this.$router.push("/");
    }

    this.updateList();
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;

      if (this.customer) {
        this.breadcrumbList[1].label = `${this.customer.company_name}`;
      }
    },
    addRow() {
      document.activeElement.blur(); /* focus out the element (macumba) */
      this.employee.trainings.push({
            attendence:0,
            duration:0,
            expiration:"",
            grade:0,
            id:0,
            name:"",
            status:"UNASSIGNED",
            schedules: [],
            session: "",
          });
      this.$refs.employee_trainings_table.refresh();
    },
    removeRow(index) {
      if(this.employee.trainings[index].id !== 0){
        for (let training of this.customerTrainings){
          if(training.id == this.employee.trainings[index].id){
           training.selected = false;
          }
        }
      }
      this.employee.trainings.splice(index, 1);
      this.$refs.employee_trainings_table.refresh();
    },
    onSelectSupervisor(supervisor) {
      if (supervisor == "none") {
        this.employeeSupervisor = "";
        this.employee.functional_data.supervisor = {
          name: null,
          registration_number: null,
        };
        return;
      }
      this.employeeSupervisor = supervisor.name;
      this.employee.functional_data.supervisor = {
        name: supervisor.name,
        registration_number: supervisor.registration_number,
      };
    },
    isEmployeeValid(employee) {
      //Name
      if (!employee.name || employee.name.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Nome" });
        return false;
      }
      //E-mail
      if (
        employee.email &&
        employee.email.trim() !== "" &&
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
          employee.email.trim()
        )
      ) {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", { field: "E-mail" });
        return false;
      }
      let checkEmail = (obj) =>
        obj.email === employee.email && obj.id !== employee.id;
      if (employee.email && this.customer_employees.some(checkEmail)) {
        window.frontMsg("FRNT_IN_USE_WARNING", { field: "E-mail" });
        return false;
      }
      //CPF
      let checkCpf = (obj) =>
        obj.cpf === employee.cpf && obj.id !== employee.id;

      if (employee.cpf && validCPF(employee.cpf) === false) {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", { field: "CPF" });
        return false;
      }
      if (employee.cpf && this.customer_employees.some(checkCpf)) {
        window.frontMsg("FRNT_IN_USE_WARNING", { field: "CPF" });
        return false;
      }
      if (
        (employee.cellphone.replace(/[\s()+-]/g, "").length < 12 ||
          employee.cellphone.replace(/[\s()+-]/g, "").length > 13) &&
        employee.cellphone.trim() !== ""
      ) {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
          field: "Número de Celular",
        });
        return false;
      }
      //Reg. Number
      if (
        !employee.registration_number ||
        employee.registration_number.trim() === ""
      ) {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Número de Registro" });
        return false;
      }
      for (let colab of this.customer_employees) {
        if (colab.cellphone) {
          if (
            colab.cellphone.replace(/[\s()-]/g, "") ===
              employee.cellphone.replace(/[\s()-]/g, "") &&
            colab.id !== employee.id
          ) {
            window.frontMsg("FRNT_IN_USE_WARNING", {
              field: "Número de Celular",
            });
            return false;
          }
        }
        if (
          colab.registration_number.replace(/[\s()-]/g, "") ===
            employee.registration_number.replace(/[\s()-]/g, "") &&
          colab.id !== employee.id
        ) {
          window.frontMsg("FRNT_IN_USE_WARNING", {
            field: "Número de Registro",
          });
          return false;
        }
      }
      //Employee other data
      if (
        Number(employee.anamnesis_data.height) &&
        Number(employee.anamnesis_data.height) < 0
      ) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Altura",
          numbercondition: "maior que zero",
        });
        return false;
      }
      if (
        Number(employee.anamnesis_data.weight) &&
        Number(employee.anamnesis_data.weight) < 0
      ) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Peso",
          numbercondition: "maior que zero",
        });
        return false;
      }
      if (
        Number(employee.functional_data.work_week) &&
        Number(employee.functional_data.work_week) < 0
      ) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Jornada",
          numbercondition: "maior que zero",
        });
        return false;
      }

      let hireDate = new Date(
        formatDate(this.employee.functional_data.date_of_hire)
      );
      let dismissalDate = new Date(
        formatDate(this.employee.functional_data.date_of_dismissal)
      );
      if (dismissalDate < hireDate) {
        window.frontMsg("FRNT_ACTION_WARNING", {
          reason:
            "a Data de Desligamento não pode ser anterior à Data de Admissão.",
        });
        return false;
      }

      this.employeeTags = this.$store.getters["tags/employeeTags"];
      if (
        this.employeeTags.length > 0 &&
        this.employee.functional_data.state === "FIRED"
      ) {
        window.frontMsg("FRNT_ACTION_WARNING", {
          reason: "o Colaborador possui Tags associadas.",
        });
        return false;
      }

      for(let i in this.employee.trainings){
        if(JSON.stringify(this.employee.trainings[i]) != JSON.stringify(this.empty_training)) {

          if(this.employee.trainings[i].attendence < 0){
            window.frontMsg("FRNT_NUM_RANGE_WARNING", {
              field: "Frequencia de treinamento",
              numbercondition: "maior ou igual a zero",
            });
            return false;
          }
          if(this.employee.trainings[i].expiration == ""){
            window.frontMsg("FRNT_EMPTY_WARNING",{
              field: "Expiração",
            });
            return false;
          }
          if(this.employee.trainings[i].grade < 0){
            window.frontMsg("FRNT_NUM_RANGE_WARNING", {
              field: "Nota",
              numbercondition: "maior ou igual a zero",
            });
            return false;
          }
          if(this.employee.trainings[i].name == ""){
            window.frontMsg("FRNT_EMPTY_WARNING",{
              field: "Expiração",
            });
            return false;
          }
          if(this.employee.trainings[i].schedules.length === 0){
            window.frontMsg("FRNT_EMPTY_WARNING",{
              field: "Expiração",
            });
            return false;
          }
          if(this.employee.trainings[i].status == ""){
            window.frontMsg("FRNT_MUST_SELECT", {
              field: "Estado",
            });
            return false;
          }
        }
      }
      return true;
    },
    onSelectTraining(item){
      if(item.id !== 0){
        for (let training of this.customerTrainings){
          if(training.id == item.id){
           training.selected = false;
          }
        }
      }
      if(item.name !== ""){
        for (let training of this.customerTrainings){
          if(training.name == item.name){
           item.id = training.id;
           item.duration = training.duration;
           item.schedules = training.schedules;
           item.expires_at = training.expires_at;
           training.selected = true;
           item.grade = 0;
           item.attendence = 0;
           item.expiration = "";
          }
        }
      } else {
        item.id = 0;
        item.duration = 0;
        item.grade = 0;
        item.attendence = 0;
        item.expiration = "";
        item.schedules = [];
      }
    },
    onSelectSession(item){
      let sche = item.session;
      let end_date = new Date(formatDate(sche.t_end.split(" ")[0]) + "T" + sche.t_end.split(" ")[1]+"Z");
      end_date.setDate(end_date.getUTCDate() + item.expires_at);
      item.expiration = end_date.toISOString().split("T")[0];
    },
    updateEmployee() {
      event.preventDefault();
      if (this.isEmployeeValid(this.employee)) {
        let employeeJson = Object.assign({}, this.employee);
        if (employeeJson.cellphone)
          employeeJson.cellphone = employeeJson.cellphone.replace(
            /[\s()-]/g,
            ""
          );
        if (employeeJson.anamnesis_data.weight)
          employeeJson.anamnesis_data.weight = parseFloat(
            employeeJson.anamnesis_data.weight
          );
        if (employeeJson.anamnesis_data.height)
          employeeJson.anamnesis_data.height =
            parseFloat(employeeJson.anamnesis_data.height) || "";
        if (employeeJson.functional_data.work_week)
          employeeJson.functional_data.work_week = Math.round(
            employeeJson.functional_data.work_week
          );
        if (employeeJson.functional_data.last_ohc) {
          employeeJson.functional_data.last_ohc = formatDateAPI(
            employeeJson.functional_data.last_ohc
          );
        }
        if (employeeJson.date_of_birth) {
          employeeJson.date_of_birth = formatDateAPI(
            employeeJson.date_of_birth
          );
        }
        if (employeeJson.functional_data.date_of_hire) {
          employeeJson.functional_data.date_of_hire = formatDateAPI(
            employeeJson.functional_data.date_of_hire
          );
        }
        if (employeeJson.functional_data.date_of_dismissal) {
          employeeJson.functional_data.date_of_dismissal = formatDateAPI(
            employeeJson.functional_data.date_of_dismissal
          );
        }

        for(let i in employeeJson.trainings){
          if (employeeJson.trainings[i].expiration) {
            employeeJson.trainings[i].expiration = formatDateAPI(
              employeeJson.trainings[i].expiration
            );
         }
        }

        if (!employeeJson.functional_data.supervisor?.name) {
          employeeJson.functional_data.supervisor = {
            name: null,
            registration_number: null,
          };
        }

        for(let i = 0; i < employeeJson.trainings.length;){
          if (employeeJson.trainings[i].expiration) {
            employeeJson.trainings[i].expiration = formatDateAPI(
              employeeJson.trainings[i].expiration
            );
          }

          if (employeeJson.trainings[i].attendence){
            employeeJson.trainings[i].attendence = employeeJson.trainings[i].attendence;
          }

          if (employeeJson.trainings[i].session){
            employeeJson.trainings[i].instructor = employeeJson.trainings[i].session.instructor;
            employeeJson.trainings[i].t_start = employeeJson.trainings[i].session.t_start;
            employeeJson.trainings[i].t_end = employeeJson.trainings[i].session.t_end;
            delete employeeJson.trainings[i].selected;
            delete employeeJson.trainings[i].session;

            if(employeeJson.trainings[i].schedules){
              delete employeeJson.trainings[i].schedules;
            }

            if(employeeJson.trainings[i].expires_at){
              delete employeeJson.trainings[i].expires_at;
            }
          }
          delete employeeJson.trainings[i].status;

          if(JSON.stringify(employeeJson.trainings[i]) === JSON.stringify(this.empty_training)){
              employeeJson.trainings.splice(i,1);
          } 
          else{
              i++;
          }
        }

        if(!employeeJson.trainings){
          delete employeeJson.trainings;
        }

        delete employeeJson.customer_id;
        employeeJson = nullEmptyProps(employeeJson);
        this.$store.dispatch("employee/updateEmployee", {
          employee: employeeJson,
          customer: this.customer,
        });
      }
    },
    changeTab(newTab) {
      this.tabSelected = newTab;
    },
    selectDisease(disease) {
      if (this.employee.anamnesis_data.preexisting_conditions.includes(disease))
        this.employee.anamnesis_data.preexisting_conditions =
          this.employee.anamnesis_data.preexisting_conditions.filter(
            (x) => x !== disease
          );
      else this.employee.anamnesis_data.preexisting_conditions.push(disease);
    },
    changeState(state) {
      this.employee.functional_data.date_of_dismissal =
        state !== "FIRED"
          ? this.employee.functional_data.date_of_dismissal
          : "";
      this.formatedDateOfDismissal = "";
    },
    isDisable(){
      if(this.computedEmployee){
         return this.computedEmployee.functional_data.state === "FIRED";
      } else {
        return false;
      }
    },
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    customer() {
      if (this.customer) {
        this.$store.dispatch("employee/getEmployees", {
          customer_id: this.customer.id,
        });
        this.updateList();
      }
    },
    computedEmployee() {
      if (this.computedEmployee) {
        this.$store.dispatch("tags/getEmployeeTags", {
          employee_id: this.computedEmployee.id,
        });

        this.employee = {
          id: this.computedEmployee.id ?? "",
          name: this.computedEmployee.name ?? "",
          email: this.computedEmployee.email ?? "",
          cellphone: this.computedEmployee.cellphone ?? "",
          cpf: this.computedEmployee.cpf ?? "",
          registration_number: this.computedEmployee.registration_number ?? "",
          gender: this.computedEmployee?.gender ?? "",
          date_of_birth: this.computedEmployee?.date_of_birth ?? "",
          anamnesis_data: {
            height: this.computedEmployee.anamnesis_data?.height ?? "",
            weight: this.computedEmployee.anamnesis_data?.weight ?? "",
            smoking: this.computedEmployee.anamnesis_data?.smoking ?? "",
            preexisting_conditions:
              this.computedEmployee.anamnesis_data?.preexisting_conditions ??
              [],
            physical_activity:
              this.computedEmployee.anamnesis_data?.physical_activity ?? "",
            medicines_for_continuous_use:
              this.computedEmployee.anamnesis_data
                ?.medicines_for_continuous_use ?? "",
            blood_pressure_level:
              this.computedEmployee.anamnesis_data?.blood_pressure_level ?? "",
            pregnancy: this.computedEmployee.anamnesis_data?.pregnancy ?? "",
          },
          functional_data: {
            area: this.computedEmployee.functional_data?.area ?? "",
            department: this.computedEmployee.functional_data?.department ?? "",
            position: this.computedEmployee.functional_data?.position ?? "",
            work_shift: this.computedEmployee.functional_data?.work_shift ?? "",
            work_week: this.computedEmployee.functional_data?.work_week ?? "",
            supervisor: this.computedEmployee.functional_data?.supervisor ?? "",
            state: this.computedEmployee.functional_data?.state ?? "",
            date_of_hire:
              this.computedEmployee.functional_data?.date_of_hire ?? "",
            date_of_dismissal:
              this.computedEmployee.functional_data?.date_of_dismissal ?? "",
            last_ohc: this.computedEmployee.functional_data?.last_ohc ?? "",
            health_plan:
              this.computedEmployee.functional_data?.health_plan ?? "",
          },
        };

        for(let row of this.computedEmployee.trainings){
          let training = {
            attendence: row.attendence ?? 0,
            duration: row.duration ?? 0,
            expiration: formatDate(row.expiration) ?? "",
            grade: row.grade ?? 0,
            id: row.id ?? 0,
            name: row.name ?? "",
            status: row.status ?? "UNASSIGNED",
            selected: true,
            session: {
              instructor: row.instructor,
              t_start: row.t_start,
              t_end: row.t_end,
            },
            schedules: [{
              instructor: row.instructor,
              t_start: row.t_start,
              t_end: row.t_end,
            }],
          };
          if(!this.employee.trainings){
            this.employee.trainings = [{...training}]
          } else {
            this.employee.trainings.push(training);
          }
        }
        if(!this.employee.trainings){
          this.employee.trainings = []
        }

        this.employeeSupervisor =
          this.computedEmployee.functional_data?.supervisor?.name ?? "";
     }
    },
    employee() {
      for (let have_training of this.employee.trainings){
        for (let training of this.customerTrainings){
          if(training.id == have_training.id){
            training.selected = true;
          }
        }
      }
      if(this.$refs.employee_trainings_table){
        this.$refs.employee_trainings_table.refresh();
      }
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    computedEmployee() {
      return this.$store.getters["employee/employee"];
    },
    customer_employees() {
      return this.$store.getters["employee/employees"];
    },
    functionalDataSelected() {
      return this.tabSelected === "functionalData";
    },
    diseasesList() {
      return this.employee.anamnesis_data.preexisting_conditions.join(", ");
    },
    anamnesisSelected() {
      return this.tabSelected === "anamnesis";
    },
    trainingSelected() {
      return this.tabSelected === "training";
    },
    customerTrainings(){
      return this.$store.getters["training/trainings"];
    },
    training_options() {
      return this.$store.getters["training/trainings"].map((training) => training.name);
    },
    area_options() {
      return this.$store.getters["employee/areas"].map((area) => area.area);
    },
    department_options() {
      return this.$store.getters["employee/departments"].map(
        (department) => department.department
      );
    },
    position_options() {
      return this.$store.getters["employee/positions"].map(
        (position) => position.position
      );
    },
    filteredSupervisors() {
      if (this.employeeSupervisor) {
        return this.customer_employees.filter((employee) => {
          return (
            employee.name
              .toLowerCase()
              .includes(this.employeeSupervisor.toLowerCase()) &&
            employee.id !== this.employee.id &&
            employee.functional_data?.position?.toLowerCase() === "supervisor"
          );
        });
      }
      return this.customer_employees.filter(
        (employee) =>
          employee.id !== this.employee.id &&
          employee.functional_data?.position?.toLowerCase() === "supervisor"
      );
    },
    formatedLastOHC: {
      get: function () {
        return formatDate(this.employee.functional_data.last_ohc);
      },
      set: function (value) {
        this.employee.functional_data.last_ohc = value;
      },
    },
    formatedDateOfBirth: {
      get: function () {
        return formatDate(this.employee.date_of_birth);
      },
      set: function (value) {
        this.employee.date_of_birth = value;
      },
    },
    formatedDateOfHire: {
      get: function () {
        return formatDate(this.employee.functional_data.date_of_hire);
      },
      set: function (value) {
        this.employee.functional_data.date_of_hire = value;
      },
    },
    formatedDateOfDismissal: {
      get: function () {
        return formatDate(this.employee.functional_data.date_of_dismissal);
      },
      set: function (value) {
        this.employee.functional_data.date_of_dismissal = value;
      },
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
};
</script>
