<template>
  <div>
    <TrainingScheduleModal 
      isImporting 
      :entireTraining="selectedTraining" 
      :tableRef="$refs.selectableTable"
      ref="scheduleModal"
    />
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Importar Treinamentos</h1>
    </div>
    <div class="col-12 col-sm-12 p-0 col-md-12">
      <div class="card direct-chat direct-chat-primary">
        <div class="card-body">
          <div class="p-4 bg-white rounded">
            <div class="row">
              <div class="col-12 pr-4">
                <div class="form-group">
                  <UploadExcel
                    :on-success="handleSuccess"
                    :before-upload="beforeUpload"
                    :draggable="true"
                  />
                </div>
              </div>
            </div>

            <div v-show="tableData.length">
              <div class="content-header px-0">
                <h4>Treinamentos encontrados</h4>
              </div>
              <b-table
                responsive="sm"
                striped
                borderless
                hover
                :fields="fields"
                :items="tableData"
                class="mt-3 rounded"
                selectable
                @row-selected="onRowSelected"
                select-mode="multi"
                ref="selectableTable"
                editableFields
              >
                <template
                  v-for="field in editableFields"
                  v-slot:[`cell(${field.key})`]="{ item }"
                >
                  <input
                    type="text"
                    class="form-control"
                    v-if="
                      field.key !== 'duration' &&
                      field.key !== 'category' &&
                      field.key !== 'area'
                    "
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'imp-' + field.key"
                    :maxlength="field.key === 'description' ? 256 : 64"
                    @change="verifyTrainings(true)"
                  />

                  <span
                    v-if="item.errors"
                    style="color: red"
                    :key="'erro-' + field.key"
                  >
                      {{ item.errors[field.key] }}
                  </span>
                </template>
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true" class="check-symbol">&check;</span>
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                  </template>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center my-2">
                    <strong>
                      <i class="fas fa-sync-alt fa-spin"></i>
                    </strong>
                  </div>
                </template>
                <template v-slot:table-colgroup>
                  <col
                    v-for="field in fields"
                    :key="field.key"
                    :style="{
                      width:
                        field.key === 'selected' ||
                        field.key === 'actions' ||
                        field.key === 'schedules' 
                          ? '150px' :
                        field.key === 'duration' ||
                        field.key === 'expires_at' ||
                        field.key === 'min_grade' ||
                        field.key === 'min_attendence'
                         ? '250px'
                         : '550px',
                    }"
                  />
                </template>
                <template v-slot:cell(invalid)="data">
                  <span
                    v-if="data.item.invalid"
                    class="valid-import badge badge-danger"
                    >Inválido</span
                  >
                  <span
                    v-if="!data.item.invalid"
                    class="valid-import badge badge-success"
                    >Válido</span
                  >
                </template>
                <template v-slot:cell(duration)="data">
                  <input
                    class="form-control"
                    v-model="data.item.duration"
                    type="number"
                    min="0"
                    max="9999"
                    :id="'trainingDuration'"
                    @change="verifyTrainings(true)"
                  />
                  <span
                    v-if="data.item.errors"
                    style="color: red"
                  >
                    {{ data.item.errors.duration }}
                  </span>
                </template>
                <template v-slot:cell(category)="data">
                  <select
                    class="form-control"
                    v-model="data.item.category"
                    :id="'trainingCategory-' + data.index"
                    @change="verifyTrainings(true)"
                  >
                    <option 
                      v-for="(category, key) of categoriesOptions"
                      :key="key">{{ category }}
                    </option>
                  </select>

                  <span
                    v-if="data.item.errors"
                    style="color: red"
                  >
                    {{ data.item.errors.category }}
                  </span>
                </template>
                <template v-slot:cell(area)="data">
                  <select
                    class="form-control"
                    v-model="data.item.area"
                    :id="'trainingCategory-' + data.index"
                    @change="verifyTrainings(true)"
                  >
                    <option 
                      v-for="(area, key) of areasOptions"
                      :key="key">{{ area }}
                    </option>
                  </select>

                  <span
                    v-if="data.item.errors"
                    style="color: red"
                  >
                      {{ data.item.errors.area }}
                  </span>
                </template>
                <template v-slot:cell(min_grade)="data">
                  <input
                    class="form-control"
                    v-model="data.item.min_grade"
                    type="number"
                    min="0"
                    max="100"
                    :id="'trainingMinGrade'"
                    @change="verifyTrainings(true)"
                  />
                  <span
                    v-if="data.item.min_grade"
                    style="color: red"
                  >
                    {{ data.item.errors.min_grade }}
                  </span>
                </template>
                <template v-slot:cell(min_attendence)="data">
                  <input
                    class="form-control"
                    v-model="data.item.min_attendence"
                    type="number"
                    min="0"
                    max="100"
                    :id="'trainingMinAttendence'"
                    @change="verifyTrainings(true)"
                  />
                  <span
                    v-if="data.item.errors"
                    style="color: red"
                  >
                    {{ data.item.errors.min_attendence }}
                  </span>
                </template>
                <template v-slot:cell(schedules)="data">
                  <div class="clock-symbol">
                    <a
                      href="#"
                      title="Associar horários"
                      @click="selectedTraining = data.item"
                      data-toggle="modal"
                     :data-target="'#training-schedule-modal'"
                     :style="{
                      color: data.item.errors.schedules ? 'red' : ''
                      }"
                    >
                      <i class="fa-solid fa-clock"></i>
                    </a>
                  </div>
                </template>
              </b-table>

              <div class="row p-1">
                <button
                  id="selectAll"
                  type="button"
                  class="btn btn-outlined btn-primary mr-2"
                  @click="selectAllRows"
                >
                  Selecionar tudo
                </button>
                <button
                  id="desselectAll"
                  type="button"
                  class="btn btn-outlined btn-primary mr-2"
                  @click="clearSelected"
                >
                  Desselecionar tudo
                </button>
                <button
                  type="button"
                  class="btn btn-outlined btn-danger mr-2"
                  @click="deleteSelected"
                  id="btn-delete-selected"
                >
                  Remover selecionados
                </button>
                <button
                  id="btn-saveTrainings-selected"
                  @click="saveTrainings"
                  class="btn btn-success px-5"
                  :disabled="load"
                >
                  <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                </button>
              </div>
            </div>
            <span v-if="!tableData.length" style="color: blue">
              Selecione um arquivo de colaboradores |
              <a v-bind:href="item.loc" download>Modelo de importação xlsx</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadExcel from "@/components/UploadExcel/index.vue";
import Breadcrumb from "@/components/Breadcrumb";
import { formatDate, formatDateAPI } from "@/utils/format-fields.js";
import TrainingScheduleModal from "./TrainingScheduleModal.vue";

export default {
  components: { Breadcrumb, UploadExcel, TrainingScheduleModal },
  data() {
    return {
      breadcrumbList: [],
      tableData: [],
      tableHeader: [],
      storage: null,
      user: "",
      sortBy: "name",
      currentPage: 1,
      tableLoad: true,
      categoriesOptions: [],
      areasOptions: [],
      existenceTrainings: [],
      fields: [
        { key: "selected", sortable: false, label: "Selecionado" },
        {
          key: "name",
          editable: true,
          sortable: true,
          label: "Nome",
        },
        {
          key: "category",
          editable: true,
          sortable: false,
          label: "Categoria",
        },
        {
          key: "area",
          editable: true,
          sortable: true,
          label: "Área",
        },
        {
          key: "duration",
          editable: true,
          sortable: true,
          label: "Duração (h)",
        },
        {
          key: "expires_at",
          editable: true,
          sortable: true,
          label: "Validade (dias)",
        },
        {
          key: "description",
          editable: true,
          sortable: true,
          label: "Descrição",
        },
        {
          key: "min_grade",
          editable: true,
          sortable: true,
          label: "Nota Mínima",
        },
        {
          key: "min_attendence",
          editable: true,
          sortable: true,
          label: "Frequência Mínima",
        },
        {
          key: "schedules",
          label: "Agendamento",
        },
        {
          key: "invalid",
          label: "Validade",
        },
      ],
      selected: [],
      fieldsHeader: [
        {
          key: "name",
          label: "Nome",
          type: "string",
        },
        {
          key: "category",
          label: "Categoria",
          type: "string",
        },
        {
          key: "area",
          label: "Área",
          type: "string",
        },
        {
          key: "duration",
          label: "Duração (h)",
          type: "string",
        },
        {
          key: "expires_at",
          label: "Validade (dias)",
          type: "string",
        },
        {
          key: "min_grade",
          editable: true,
          sortable: true,
          label: "Nota Mínima",
        },
        {
          key: "min_attendence",
          editable: true,
          sortable: true,
          label: "Frequência Mínima",
        },
        {
          key: "description",
          label: "Descrição",
          type: "string",
        },
        {
          key: "schedule.instructor",
          label: "Instrutor",
          type: "string",
        },
        {
          key: "schedule.t_end",
          label: "Horário de Término",
          type: "string",
        },
        {
          key: "schedule.t_start",
          label: "Horário de Início",
          type: "string",
        },
      ],
      item: {
        title: "Excel template",
        loc: "/assets/files/model_import_treinaments.xlsx",
      },
      invalidParameter: false,
      selectedTraining: {
            name: "",
            area: "",
            category: "",
            area: "",
            description: "",
            duration: "",
            expires_at: "",
          }
    };
  },
  watch: {
    successTrainings() {
      this.successTrainings.forEach((successTraining) => {
        this.tableData = this.tableData.filter((training) => {
          return training.internal_id !== successTraining.internal_id;
        });
      });
    },
    customer() {
      if (this.customer) {
        this.updateList();
      }
    },
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    categories(){
      this.categoriesOptions = new Set();
      this.categories.map((category) => {
        this.categoriesOptions.add(category.name)
      })
    },
    areas(){
      this.areasOptions = new Set();
      this.areas.map((area) => {
        this.areasOptions.add(area.area)
      })
    },
    trainings(){
      this.existenceTrainings = new Set();
      
      this.trainings.forEach((training) => {
        this.existenceTrainings.add(training.name);
      })
    },
    tableData(){
      this.verifyTrainings(false);
    },
  },
  mounted() {
    this.updateList();

    if (this.$route.params.customerId) {
      if (this.$route.params.customerId) {
        this.$store.dispatch("customer/getCustomer", {
          customerId: this.$route.params.customerId,
        });

        this.$store.dispatch("employee/getEmployees", {
          customer_id: this.$route.params.customerId,
        });

        this.$store.dispatch("employee/getAreas", {
          customerId: this.$route.params.customerId,
        });

        this.$store.dispatch("training/getCustomerCategories", {
          customer_id: this.$route.params.customerId,
        });

        this.$store.dispatch("training/getCustomerAllTrainings", {
          customer_id: this.$route.params.customerId,
        });
      }
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
      this.$router.push("/");
    }
  },
  methods: {
    verifyTrainings(verifyRepeatedTrainings) {
      this.tableLoad = false;
      this.tableData.forEach((item) => {
        item.errors.category = "";
        item.errors.area = "";
        item.errors.duration = "";
        item.errors.description = "";
        item.errors.expires_at = "";
        item.errors.schedules = false;
        item.errors.min_attendence = "";
        item.errors.min_grade = "";
        item.invalid = false;

        item.name = item.name?.toUpperCase()
        if (verifyRepeatedTrainings){
          this.verifyRepeatedTraining(item)
        }

        this.verifyTrainingSchedules(item)

        if (
          !item.name?.toString().trim()
        ) {
          item.errors.name = "Treinamento não pode ser vazio!"
        }

        else if (this.existenceTrainings.has(item.name)){
          item.errors.name = "Treinamento já cadastrado!"
        }
        
        if (!this.categoriesOptions.has(item.category) || !item.category){
          item.category = "";
          item.errors.category = "Categoria não pode ser vazia!"
        }

        if (!this.areasOptions.has(item.area) || !item.area){
          item.area = "";
          item.errors.area = "Área não pode ser vazia!"
        }

        if (!item.duration || isNaN(parseInt(item.duration))){
          item.duration = "";
          item.errors.duration = "Duração não pode ser vazia!"
        }

        else if (item.duration < 0 || item.duration > 9999){
          item.errors.duration = "Duração deve estar entre 0 e 9.999 h!"
        }

        if (!item.expires_at){
          item.errors.expires_at = "Validade do treinamento não pode ser vazia!"
        }

        else if (item.expires_at < 0 || item.expires_at > 9999){
          item.errors.expires_at = "Validade do treinamento estar entre 0 e 9.999 dias!"
        }

        if (!item.min_attendence){
          item.errors.min_attendence = "Frequência mínima não pode ser vazia!"
        }

        else if (item.min_attendence < 0 || item.min_attendence > 100){
          item.errors.min_attendence = "Frequência mínima deve ser um valor entre 0 a 100!"
        }

        if (!item.min_grade){
          item.min_grade = null;
        }
        
        else if (item.min_grade < 0 || item.min_grade > 100){
          item.errors.min_grade = "Nota mínima deve ser um valor entre 0 a 100!"
        }

        for (let key in item.errors){
          if (item.errors[key]){
            item.invalid = true;
            break;
          }
        }
      });

      this.$refs.selectableTable.refresh();
    },
    verifyRepeatedTraining(item){
        let repeatedTrainings = this.tableData.filter((training) => item.name?.toUpperCase() === training.name?.toUpperCase()).length;

        if (repeatedTrainings > 1) {            
          item.errors.name = "Treinamento já está na lista para ser importado!";
        }

        else {
          item.errors.name = "";
        }
    },
    changeKeys(obj_array) {
      obj_array.forEach((elem) => {
        Object.keys(elem).forEach((key) => {
          for (let y in this.fieldsHeader) {
            if (key.toLowerCase() == this.fieldsHeader[y].label.toLowerCase()) {
              /* Create new key with the same values/properties */
              Object.defineProperty(
                elem,
                this.fieldsHeader[y].key,
                Object.getOwnPropertyDescriptor(elem, key)
              );
              /*Delete old key*/
              delete elem[key];
              break;
            }
          }
        });
      });

      return obj_array;
    },
    label_to_key_header(header) {
      let new_header = [];
      let found;
      /*This code is not optimal, but in the worse case it is only a 32 element array, so it doesnt matter*/
      header.forEach((field) => {
        /* Cannot use "break" in forEach*/
        found = false;
        for (let y in this.fieldsHeader) {
          if (field.toLowerCase() == this.fieldsHeader[y].label.toLowerCase()) {
            new_header.push(this.fieldsHeader[y].key);
            found = true;
            break;
          }
        }
        /*didnt find a match, keep the same*/
        if (!found) {
          new_header.push(field);
        }
      });
      return new_header;
    },
    saveTrainings() {
      this.verifyTrainings(true)
      let validTrainings = this.selected.filter((item) => !item.invalid);

      if (validTrainings.length && validTrainings.length === this.selected.length) {
        let sendTrainings = this.selected.map((training) => {
          return ({
            name: training.name?.toUpperCase(),
            area: training.area ?? "",
            category: training.category ?? "",
            area: training.area ?? "",
            description: training.description ?? "",
            duration: training.duration ?? "",
            expires_at: training.expires_at ?? "",
            min_attendence: training.min_attendence ?? null,
            min_grade: training.min_attendence ?? null,
            schedules: [...training.schedules],
          })
        })
        sendTrainings.forEach((training) => {
          this.replaceDate(training);

          this.tableData = this.tableData.filter((tableTraining) => tableTraining.name !== training.name)
        });

        this.$store.dispatch("training/saveListTrainings", {
          trainings: sendTrainings,
          customer_id: this.$route.params.customerId,
        });
      } else {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
          field: "Dados dos Treinamentos",
          extra: "Selecione Treinamentos válidos para salvar",
        });
      }
    },
    replaceStrings(obj) {
      for (let key in obj) {
        if (typeof obj[key] === "object") {
          this.replaceStrings(obj[key]);
        } else if (obj[key] === "NULL") {
          obj[key] = null;
        }
      }
      return obj;
    },
    replaceDate(obj){
      for (let key in obj) {
        if (typeof obj[key] === "object") {
          this.replaceDate(obj[key]);
        }

        else if (
          typeof obj[key] === "string" &&
          obj[key].length === 10 &&
          obj[key].split("-").length === 3
        ) {
          obj[key] = formatDateAPI(obj[key]);
        }
      }
    },
    transformDotNotation(data) {
      let transformedData = [];

      data.forEach((element) => {
        let transformedElement = {
          name: null,
          category: null,
          area: null,
          duration: null,
          expires_at: null,
          description: null,
          schedule: {
            instructor: null,
            t_end: null,
            t_start: null,
          }
        };

        element.category = element.category?.toUpperCase();
        element.area = element.area?.toUpperCase();

        for (let key in element) {
          let keyArray = key.split(".");
          let currentElement = transformedElement;
          for (let i = 0; i < keyArray.length; i++) {
            if (i === keyArray.length - 1) {
              currentElement[keyArray[i]] = element[key];
            } else {
              if (!currentElement[keyArray[i]]) {
                currentElement[keyArray[i]] = {};
              }
              currentElement = currentElement[keyArray[i]];
            }
          }
        }

        transformedElement.errors = {
          name: "",
          category: "",
          area: "",
          duration: "",
          description: "",
          expires_at: "",
          min_attendence: "",
          min_grade: "",
          schedules: "",
        };

        transformedElement.name = transformedElement.name?.toUpperCase()
        transformedData.push(transformedElement);
      });

      this.replaceStrings(transformedData);
      return transformedData;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    deleteSelected() {
      this.tableData = this.tableData.filter((e) => {
        return !this.selected.includes(e);
      });

      this.verifyTrainings(true)

      this.$refs.selectableTable.refresh();
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1 MB in size.",
        type: "warning",
      });
      return false;
    },
    handleSuccess({ results, header }) {
      if (results.length < 50) {
        this.tableData = this.transformDotNotation(this.changeKeys(results));
        this.invalidParameter = false;

        let originalTableDataLength = this.tableData.length;
        let totalVerifiedTrainings = 0;

        while (totalVerifiedTrainings < originalTableDataLength) {
          // All the verified trainings goes to end of the vector, so only the first position has to be verified
          totalVerifiedTrainings += this.verifySchedules(this.tableData[0])
        }

        for (let index in this.tableData){
          // Without timeout, the TrainingScheduleModal doesn't catch changes at the variable
          if (index == 0) this.verifyTrainings(true);
          else this.verifyTrainings(false);
        }

        if (this.invalidParameter)
          window.frontMsg(
          "ASSET_IMPORT_INVALID"
        );
          
        this.tableHeader = this.label_to_key_header(header);
      } else {
        window.frontMsg("FRNT_ACTION_WARNING", {
          reason: "a quantidade de linhas na planilha excede o limite de 50",
        });
      }
    },
    verifySchedules(item){
      let repeatedTrainings = this.tableData.filter((training) => 
        item.name === training.name &&
        item.category === training.category &&
        item.area === training.area &&
        item.duration === training.duration &&
        item.expires_at === training.expires_at &&
        item.description === training.description
      )

      this.tableData = this.tableData.filter((training) => 
        item.name !== training.name ||
        item.category !== training.category ||
        item.area !== training.area ||
        item.duration !== training.duration ||
        item.expires_at !== training.expires_at ||
        item.description !== training.description
      )

      repeatedTrainings[0].schedules = [];

      repeatedTrainings.forEach((training) => {
        if (training.schedule.instructor || training.schedule.t_start || training.schedule.t_end){
          training.schedule.instructor = training.schedule.instructor.toUpperCase()
          repeatedTrainings[0].schedules.push(training.schedule)
        }
      })

      this.tableData.push(repeatedTrainings[0]);
      return repeatedTrainings.length // Number of verified trainings from data table.
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = `Editar cliente ${this.customer.company_name}`;
      }
    },
    verifyTrainingSchedules(entireTraining) {
      let schedules = [];
      let importedTrainings = JSON.parse(JSON.stringify(entireTraining))

      importedTrainings.schedules.map((schedule) => {
        let t_start = schedule.t_start?.split(" ");
        let t_end = schedule.t_end?.split(" ");

        schedule.t_start_hour = t_start ? t_start[1] : "";
        schedule.t_start = t_start ? formatDate(t_start[0]) : "";
        
        schedule.t_end_hour = t_end ? t_end[1] : "";
        schedule.t_end = t_end ? formatDate(t_end[0]) : "";

        schedules.push({...schedule})
      })

      entireTraining.errors.schedules = false;
      
      schedules.map((schedule) => {
        if(!this.isScheduleValid(schedule, importedTrainings)){
          entireTraining.errors.schedules = true;
          entireTraining.invalid = true;
        }
      })

      this.customerId = this.$route.params.customerId;
      this.$refs.selectableTable.refresh()
    },
    isScheduleValid(trainingSchedule, entireTraining) {
      trainingSchedule.errors = {
        instructor: "",
        t_start: "",
        t_end: "",
        t_start_hour: "",
        t_end_hour: "",
      }

      let valid = true;
      let schedule_regex = /^(([0-1]\d)|2[0-3])(:[0-5]\d){2}$/;

      if(trainingSchedule.instructor){
        trainingSchedule.instructor = trainingSchedule.instructor.trim();
      }
      if (!trainingSchedule.instructor){
        valid = false;
      }

      if (!trainingSchedule.t_start_hour?.trim()){
        valid = false;
      }

      else if (!schedule_regex.test(trainingSchedule.t_start_hour)) {
        valid = false;
      }

      if (!trainingSchedule.t_end_hour?.trim()){
        valid = false;
      }

      else if (!schedule_regex.test(trainingSchedule.t_end_hour)) {
        valid = false;
      }

      if (!trainingSchedule.t_start?.trim()){
        valid = false;
      }

      if (!trainingSchedule.t_end?.trim()){
        valid = false;
      }

      if (
          !trainingSchedule.errors.instructor &&
          !trainingSchedule.errors.t_start &&
          !trainingSchedule.errors.t_end &&
          !trainingSchedule.errors.t_start_hour &&
          !trainingSchedule.errors.t_end_hour
      ){
        let startDate = new Date(`${trainingSchedule.t_start} ${trainingSchedule.t_start_hour}`);
        let endDate = new Date(`${trainingSchedule.t_end} ${trainingSchedule.t_end_hour}`);

        if (startDate > endDate){
          valid = false;
        }
        //A diferença entre duas datas é retornada em TS, em milissegundos, por isso se é necessario multiplicar duration, que é em horas, por 3.600.000
        if(endDate - startDate < entireTraining.duration * 3600 * 1000){
          valid = false;
        }

        if (startDate > new Date("2099-12-31T23:59:59.999")){
          // trainingSchedule.errors.t_start = "Data inicial acima da data maxima permitida";
          valid = false;
        }

        if (endDate > new Date("2099-12-31T23:59:59.999")){
          valid = false;
        }
        
        let repeatedNewSchedules = entireTraining.schedules?.filter((otherSchedule) => 
          otherSchedule.instructor === trainingSchedule.instructor &&
          otherSchedule.t_start === trainingSchedule.t_start &&
          otherSchedule.t_start_hour === trainingSchedule.t_start_hour &&
          otherSchedule.t_end === trainingSchedule.t_end && 
          otherSchedule.t_end_hour === trainingSchedule.t_end_hour
        )

        if (repeatedNewSchedules.length > 1){
          valid = false;
        }
      }
    
      this.$refs.selectableTable.refresh();
      return valid;
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    editableFields() {
      return this.fields.filter((field) => field.editable);
    },
    employees() {
      return this.$store.getters["employee/employees"];
    },
    categories(){
      return this.$store.getters["training/categories"];
    },
    areas(){
      return this.$store.getters["employee/areas"];
    },
    trainings(){
      return this.$store.getters["training/trainings"]
    }
  },
};
</script>

<style lang="scss" scoped>
.check-symbol {
  font-size: 3rem;
  margin-top: -30px;
  margin-left: 15px;
  position: absolute;
  display: block;
  text-align: center;
  user-select: none;
}

.valid-import {
  width: 100px;
  font-size: 20px;
  padding: 10px;
  border-radius: 50px;
  transition: none;
}

.clock-symbol{
  text-align: center;
  font-size: 18px;
}
</style>
