<template>
  <div v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']">
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Adicionar colaborador</h1>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card direct-chat direct-chat-primary employee">
          <form @submit="saveEmployee">
            <div class="card-body">
              <div class="pt-4 pr-4 pb-2 pl-4 bg-white rounded">
                <div class="row">
                  <TwInput col_lg="12" label="Nome" required="false">
                    <input
                      v-model="employee.name"
                      required
                      type="text"
                      maxlength="55"
                      class="form-control"
                      placeholder="Nome do colaborador"
                      id="employeeName"
                    />
                  </TwInput>
                </div>
                <div class="row">
                  <TwInput label="E-mail">
                    <input
                      v-model="employee.email"
                      type="email"
                      maxlength="55"
                      class="form-control"
                      placeholder="E-mail"
                      id="employeeEmail"
                    />
                  </TwInput>
                  <TwInput label="Celular">
                    <input
                      v-model="employee.cellphone"
                      v-mask="'+(##) ## #####-####'"
                      type="text"
                      class="form-control"
                      placeholder="Celular"
                      id="employeeCellphone"
                    />
                  </TwInput>
                </div>
                <div class="row mb-2">
                  <TwInput label="CPF">
                    <input
                      v-model="employee.cpf"
                      v-mask="'###.###.###-##'"
                      type="text"
                      class="form-control"
                      placeholder="CPF"
                      id="employeeCpf"
                    />
                  </TwInput>

                  <TwInput label="Número de registro" required="false">
                    <input
                      v-model="employee.registration_number"
                      required
                      type="text"
                      maxlength="20"
                      class="form-control"
                      placeholder="Número de registro"
                      id="employeeRegistration"
                    />
                  </TwInput>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <div class="card direct-chat direct-chat-primary">
                      <div class="card-body custom-card overflow-visible">
                        <ul class="nav nav-tabs bg-main">
                          <li class="nav-item">
                            <a
                              id="functionalData-tab"
                              class="nav-link first"
                              v-bind:class="{
                                active: functionalDataSelected,
                              }"
                              href="javascript:void(0);"
                              v-on:click="changeTab('functionalData')"
                              aria-current="dados-funcionais"
                              tabindex="-1"
                              >Dados funcionais</a
                            >
                          </li>
                          <li class="nav-item">
                            <a
                              id="training-tab"
                              class="nav-link"
                              v-bind:class="{
                                active: this.tabSelected === 'training',
                              }"
                              v-on:click="changeTab('training')"
                              href="javascript:void(0);"
                              aria-current="training"
                              tabindex="-1"
                              >Treinamentos</a
                            >
                          </li>
                          <li class="nav-item">
                            <a
                              id="anamnesis-tab"
                              class="nav-link"
                              v-bind:class="{
                                active: this.tabSelected === 'anamnesis',
                              }"
                              v-on:click="changeTab('anamnesis')"
                              href="javascript:void(0);"
                              aria-current="anamnese"
                              tabindex="-1"
                              >Anamnese</a
                            >
                          </li>
                        </ul>
                        <div class="p-4 bg-white rounded">
                          <div class="tab-content">
                            <div
                              class="tab-pane fade"
                              v-bind:class="{
                                show: anamnesisSelected,
                                active: anamnesisSelected,
                              }"
                            >
                              <div class="row">
                                <TwInput col_lg="4" label="Peso (kg)">
                                  <input
                                    v-model="employee.anamnesis_data.weight"
                                    type="number"
                                    class="form-control"
                                    placeholder="Peso"
                                    id="employeeWeight"
                                    :max="1000"
                                  />
                                </TwInput>
                                <TwInput col_lg="4" label="Altura (m)">
                                  <input
                                    v-model="employee.anamnesis_data.height"
                                    type="number"
                                    class="form-control"
                                    placeholder="Altura"
                                    id="employeeHeight"
                                    :step="0.01"
                                    :max="3"
                                  />
                                </TwInput>
                                <TwInput col_lg="4" label="Fumante">
                                  <select
                                    v-model="employee.anamnesis_data.smoking"
                                    class="form-control"
                                    id="smokerForm"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                  </select>
                                </TwInput>
                              </div>
                              <div class="row">
                                <TwInput
                                  col_lg="4"
                                  label="Doenças Pré-existentes"
                                >
                                  <div class="input-group">
                                    <input
                                      id="diseases-list-in"
                                      type="text"
                                      class="form-control"
                                      v-model="diseasesList"
                                    />
                                    <div class="input-group-append">
                                      <button
                                        class="btn btn-outline-secondary dropdown-toggle"
                                        type="button"
                                        data-toggle="dropdown"
                                        id="diseaseDropdown"
                                      ></button>
                                      <div class="dropdown-menu">
                                        <span
                                          class="dropdown-item"
                                          v-for="(
                                            disease, index
                                          ) in preexisting_conditions_options"
                                          :key="index"
                                          v-on:click="selectDisease(disease)"
                                        >
                                          {{ disease }}
                                        </span>
                                        <span
                                          class="dropdown-item"
                                          v-on:click="selectDisease('Outra')"
                                          >Outra</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </TwInput>
                                <TwInput col_lg="4" label="Atividade Física">
                                  <select
                                    v-model="
                                      employee.anamnesis_data.physical_activity
                                    "
                                    class="form-control"
                                    id="physicalactivitySelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="0x">
                                      Não pratica exercícios
                                    </option>
                                    <option value="1x">1x por semana</option>
                                    <option value="2x">2x por semana</option>
                                    <option value="3x">3x por semana</option>
                                    <option value="4x">4x por semana</option>
                                    <option value="5+">5+ por semana</option>
                                  </select>
                                </TwInput>
                                <TwInput
                                  col_lg="4"
                                  label="Medicamentos de uso contínuo"
                                >
                                  <select
                                    v-model="
                                      employee.anamnesis_data
                                        .medicines_for_continuous_use
                                    "
                                    class="form-control"
                                    id="medicinesSelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                  </select>
                                </TwInput>
                              </div>
                              <div class="row">
                                <TwInput
                                  class="blood-pressure"
                                  col_lg="4"
                                  label="Pressão arterial"
                                >
                                  <select
                                    v-model="
                                      employee.anamnesis_data
                                        .blood_pressure_level
                                    "
                                    class="form-control"
                                    id="pressureSelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option
                                      v-for="(
                                        blood_pressure_level, index
                                      ) in blood_pressure_level_options"
                                      :key="index"
                                      :value="blood_pressure_level.val"
                                    >
                                      {{ blood_pressure_level.option }}
                                    </option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Gravidez">
                                  <select
                                    v-model="employee.anamnesis_data.pregnancy"
                                    class="form-control"
                                    id="pregnancySelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Data (último ASO)">
                                  <input
                                    class="form-control"
                                    type="date"
                                    :min="'1900-01-01'"
                                    :max="
                                      new Date().toISOString().split('T')[0]
                                    "
                                    v-model="employee.functional_data.last_ohc"
                                    id="ohcSelect"
                                  />
                                </TwInput>
                              </div>
                            </div>
                            <div
                              class="tab-pane fade"
                              v-bind:class="{
                                show: functionalDataSelected,
                                active: functionalDataSelected,
                              }"
                            >
                              <div class="row">
                                <TwInput col_lg="4" label="Área">
                                  <select
                                    v-model="employee.functional_data.area"
                                    class="form-control"
                                    id="areaSelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option
                                      v-for="(area, index) in area_options"
                                      :key="index"
                                      :value="area.toLowerCase()"
                                    >
                                      {{ area }}
                                    </option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Setor">
                                  <select
                                    v-model="
                                      employee.functional_data.department
                                    "
                                    class="form-control"
                                    id="departmentSelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option
                                      v-for="(
                                        department, index
                                      ) in department_options"
                                      :key="index"
                                      :value="department.toLowerCase()"
                                    >
                                      {{ department }}
                                    </option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Cargo">
                                  <select
                                    v-model="employee.functional_data.position"
                                    class="form-control"
                                    id="positionSelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option
                                      v-for="(
                                        position, index
                                      ) in position_options"
                                      :key="index"
                                      :value="position.toLowerCase()"
                                    >
                                      {{ position }}
                                    </option>
                                  </select>
                                </TwInput>
                              </div>
                              <div class="row">
                                <TwInput col_lg="4" label="Turno">
                                  <select
                                    v-model="
                                      employee.functional_data.work_shift
                                    "
                                    class="form-control"
                                    id="workshiftSelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option
                                      v-for="(
                                        work_shift, index
                                      ) in work_shift_options"
                                      :key="index"
                                      :value="work_shift.toLowerCase()"
                                    >
                                      {{ work_shift }}
                                    </option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Jornada (h)">
                                  <input
                                    id="emp-work-journey-in"
                                    v-model="employee.functional_data.work_week"
                                    type="number"
                                    class="form-control"
                                    placeholder="44"
                                    :max="168"
                                  />
                                </TwInput>
                                <TwInput col_lg="4" label="Gestor">
                                  <input
                                    id="emp-supervisor-in"
                                    v-model="employeeSupervisor"
                                    type="text"
                                    autocomplete="off"
                                    class="form-control filter-input"
                                    placeholder="Gestor"
                                  />
                                  <div
                                    v-if="filteredSupervisors.length"
                                    class="autocomplete"
                                  >
                                    <div
                                      class="sup"
                                      @click="onSelectSupervisor('none')"
                                    >
                                      <b>LIMPAR SELEÇÃO</b>
                                    </div>
                                    <div
                                      v-for="sup in filteredSupervisors"
                                      class="sup"
                                      :key="sup.id"
                                      @click="onSelectSupervisor(sup)"
                                    >
                                      <b>{{ sup.name }}</b>
                                    </div>
                                  </div>
                                </TwInput>
                              </div>
                              <div class="row">
                                <TwInput col_lg="4" label="Plano de saúde">
                                  <select
                                    v-model="
                                      employee.functional_data.health_plan
                                    "
                                    class="form-control"
                                    id="healthplanSelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="S">Sim</option>
                                    <option value="N">Não</option>
                                  </select>
                                </TwInput>

                                <TwInput col_lg="4" label="Sexo">
                                  <select
                                    v-model="employee.gender"
                                    class="form-control"
                                    id="genderSelect"
                                  >
                                    <option value="">Selecione</option>
                                    <option value="FEMININO">Feminino</option>
                                    <option value="MASCULINO">Masculino</option>
                                    <option value="NAO-IDENTIFICADO">
                                      Não informado
                                    </option>
                                  </select>
                                </TwInput>
                                <TwInput col_lg="4" label="Data de nascimento">
                                  <input
                                    id="emp-birthdate-in"
                                    class="form-control"
                                    type="date"
                                    :min="'1900-01-01'"
                                    :max="
                                      new Date().toISOString().split('T')[0]
                                    "
                                    v-model="employee.date_of_birth"
                                  />
                                </TwInput>
                              </div>
                              <div class="row">
                                <TwInput col_lg="4" label="Data de admissão">
                                  <input
                                    id="add-employee-hire-date"
                                    class="form-control"
                                    type="date"
                                    :min="'1900-01-01'"
                                    :max="
                                      new Date().toISOString().split('T')[0]
                                    "
                                    v-model="
                                      employee.functional_data.date_of_hire
                                    "
                                  />
                                </TwInput>
                                <TwInput col_lg="4" label="Estado">
                                  <select
                                    disabled
                                    v-model="employee.functional_data.state"
                                    class="form-control"
                                    id="stateSelect"
                                    @change="
                                      changeState(
                                        employee.functional_data.state
                                      )
                                    "
                                  >
                                    <option value="HIRED">Ativo</option>
                                  </select>
                                </TwInput>
                                <TwInput
                                  col_lg="4"
                                  label="Data de desligamento"
                                >
                                  <input
                                    id="add-employee-dismissal-date"
                                    class="form-control"
                                    type="date"
                                    :max="
                                      new Date().toISOString().split('T')[0]
                                    "
                                    v-model="
                                      employee.functional_data.date_of_dismissal
                                    "
                                    :disabled="
                                      employee.functional_data.state !== 'FIRED'
                                    "
                                  />
                                </TwInput>
                              </div>
                            </div>
                            <div
                              class="tab-pane fade"
                              v-bind:class="{
                                show: trainingSelected,
                                active: trainingSelected,
                              }"
                            >
                              <div>
                                <b-table
                                  striped
                                  hover
                                  :items="employee.trainings"
                                  :fields="fields_training"
                                  ref="employee_trainings_table"
                                >
                                  <template
                                    v-slot:cell(name)="data"
                                  >
                                    <select
                                      v-model="data.item.name"
                                      class="form-control"
                                      id="trainingSelect"
                                      @change="onSelectTraining(data.item)"
                                    >
                                      <option value="">Selecione</option>
                                      <option
                                        v-for="training in customerTrainings"
                                        :key="training.id"
                                        :value="training.name"
                                        :disabled="training.selected"
                                      >
                                        {{ training.name }}
                                      </option>
                                    </select>
                                  </template>
                                  <template
                                    v-slot:cell(session)="data"
                                  >
                                    <select
                                      v-model="
                                      data.item.session"
                                      class="form-control"
                                      id="trainingScheduleSelect"
                                      @change="onSelectSession(data.item)"
                                      :disabled="
                                        data.item.schedules.length === 0
                                      "
                                      
                                    >
                                      <option value="">Selecione</option>
                                      <option
                                        v-for="
                                          schedule in data.item.schedules"
                                        :key="schedule.id"
                                        :value="schedule"
                                      >
                                        {{ "Instrutor: " + schedule.instructor + ". Início: " + schedule.t_start + ". Término: " + schedule.t_end}}
                                      </option>
                                    </select>
                                  </template>
                                  <template
                                  v-slot:cell(expiration)="data"
                                  >
                                    <b-form-input
                                      type="date"
                                      v-model="data.item.expiration"
                                      placeholder="Validade do treinamento"
                                      id="trainingExpiration"
                                      :min='"1900-01-01"'
                                      :max='"2099-12-31"'
                                      :disabled="true"
                                    />
                                  </template>
                                  <template
                                      v-slot:cell(attendence)="data"
                                  >
                                    <b-form-input
                                      type="number"
                                      v-model="data.item.attendence"
                                      placeholder="Frequência"
                                      id="trainingFrequency"
                                      step="1"
                                      max="100"
                                      min="0"
                                    />
                                  </template>
                                  <template
                                  v-slot:cell(grade)="data"
                                  >
                                    <b-form-input
                                      type="number"
                                      v-model="data.item.grade"
                                      placeholder="Nota"
                                      id="trainingGrade"
                                      max="100"
                                      min="0"
                                    />
                                  </template>
                                  <template v-slot:cell(actions)="data">
                                    <b-button
                                      variant="danger"
                                      @click="removeRow(data.index)"
                                    >
                                      <i class="far fa-trash-alt"></i>
                                    </b-button>
                                  </template>
                                  <template #table-caption>
                                    <div style="display: flex; justify-content: center;">
                                    <b-button
                                      class="btn btn-primary"
                                      @click="addRow()"
                                    >
                                      <i class="fa fa-add"></i></b-button
                                    >
                                    </div>
                                  </template>
                                </b-table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-3 pl-2">
                  <button
                    id="saveEmployee"
                    class="btn btn-primary px-5"
                    :disabled="load"
                  >
                    <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import { validCPF } from "../../services/helpers";
import { formatDate, formatDateAPI } from "../../utils/format-fields";
import { nullEmptyProps } from "../../utils/format-json";
import "./style.scss";
import TwInput from "@/components/TwInput.vue";

export default {
  name: "AddEmployee",
  data: function () {
    return {
      tabSelected: "functionalData",
      preexisting_conditions_options: [
        "Asma",
        "Cardiopatia",
        "Diabetes",
        "Hepatite",
      ],
      work_shift_options: ["Administrativo", "Primeiro", "Segundo", "Terceiro"],
      blood_pressure_level_options: [
        { val: "BAIXO", option: "BAIXA" },
        { val: "NORMAL", option: "NORMAL" },
        { val: "ELEVADO", option: "ELEVADO" },
        { val: "HIPERTENSAO-ESTAGIO-1", option: "HIPERTENSÃO Estágio I" },
        { val: "HIPERTENSAO-ESTAGIO-2", option: "HIPERTENSÃO Estágio II" },
        { val: "CRISE-HIPERTENSIVA", option: "CRISE HIPERTENSIVA" },
      ],
      fields_training: [
        {
          key: "name",
          label: "Treinamento",
        },
        {
          key: "session",
          label: "Turma",
        },
        {
          key: "expiration",
          label: "Expiração",
        },
        {
          key: "attendence",
          label: "Frequência (%)",
        },
        {
          key: "grade",
          label: "Nota (Máx.: 100)",
        },
        {
          key: "actions",
          label: "Ações"
        },
      ],
      breadcrumbList: [],
      employee: {
        name: "",
        email: "",
        cellphone: "",
        cpf: "",
        registration_number: "",
        gender: "",
        date_of_birth: "",
        anamnesis_data: {
          height: "",
          weight: "",
          smoking: "",
          preexisting_conditions: [],
          physical_activity: "",
          medicines_for_continuous_use: "",
          blood_pressure_level: "",
          pregnancy: "",
        },
        functional_data: {
          area: "",
          department: "",
          position: "",
          work_shift: "",
          work_week: "",
          state: "HIRED",
          date_of_hire: "",
          date_of_dismissal: "",
          supervisor: {
            name: "",
            registration_number: "",
          },
          last_ohc: "",
          health_plan: "",
        },
        trainings:[
          {
            attendence:0,
            duration:0,
            expiration:"",
            grade:0,
            id:0,
            name:"",
            schedules: [],
            session: "",
          },
        ],
      },
      employeeSupervisor: "",
      empty_training: {
        attendence:0,
        duration:0,
        expiration:"",
        grade:0,
        id:0,
        name:"",
        schedules: [],
        session: "",
      },
    };
  },
  mounted() {
    function getTodayDate() {
      let day, month, year;
      let today = new Date();

      day = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();
      month =
        today.getMonth() + 1 < 10
          ? `0${today.getMonth() + 1}`
          : today.getMonth() + 1;
      year = today.getFullYear();

      return `${year}-${month}-${day}`;
    }
    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getEmployees", {
        customer_id: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getAreas", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getDepartments", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("employee/getPositions", {
        customerId: this.$route.params.customerId,
      });
      this.$store.dispatch("training/getCustomerAllTrainings", {
        customer_id: this.$route.params.customerId,
      });     
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
      this.$router.push("/");
    }

    this.employee.functional_data.date_of_hire = getTodayDate();
    this.updateList();
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = this.customer.company_name;
      }
    },
    addRow() {
      document.activeElement.blur(); /* focus out the element (macumba) */
      this.employee.trainings.push({
            attendence:0,
            duration:0,
            expiration:"",
            grade:0,
            id:0,
            name:"",
            schedules: [],
            session: "",
          });
      this.$refs.employee_trainings_table.refresh();
    },
    removeRow(index) {
      if(this.employee.trainings[index].id !== 0){
        for (let training of this.customerTrainings){
          if(training.id == this.employee.trainings[index].id){
           training.selected = false;
          }
        }
      }
      this.employee.trainings.splice(index, 1);
      this.$refs.employee_trainings_table.refresh();
    },
    onSelectSupervisor(supervisor) {
      if (supervisor == "none") {
        this.employeeSupervisor = "";
        this.employee.functional_data.supervisor = {
          name: null,
          registration_number: null,
        };
        return;
      }
      this.employeeSupervisor = supervisor.name;
      this.employee.functional_data.supervisor = {
        name: supervisor.name,
        registration_number: supervisor.registration_number,
      };
    },
    isEmployeeValid() {
      let checkCpf = (obj) => obj.cpf === this.employee.cpf;
      let checkRegistrationNumber = (obj) =>
        obj.registration_number === this.employee.registration_number;
      if (!this.employee.name || this.employee.name.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Nome" });
        return false;
      }
      if (
        this.employee.email.trim() !== "" &&
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
          this.employee.email.trim()
        )
      ) {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", { field: "E-mail" });
        return false;
      }
      if (this.employee.cpf && validCPF(this.employee.cpf) === false) {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", { field: "CPF" });
        return false;
      }
      let checkEmail = (obj) =>
        obj.email === this.employee.email && obj.id !== this.employee.id;
      if (this.employee.email && this.customer_employees.some(checkEmail)) {
        window.frontMsg("FRNT_IN_USE_WARNING", { field: "E-mail" });
        return false;
      }
      if (this.employee.cpf && this.customer_employees.some(checkCpf)) {
        window.frontMsg("FRNT_IN_USE_WARNING", { field: "CPF" });
        return false;
      }
      if (
        (this.employee.cellphone.replace(/[\s()+-]/g, "").length < 12 ||
          this.employee.cellphone.replace(/[\s()+-]/g, "").length > 13) &&
        this.employee.cellphone !== ""
      ) {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
          field: "Número de Celular",
        });
        return false;
      }
      if (
        !this.employee.registration_number ||
        this.employee.registration_number.trim() === ""
      ) {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Número de Registro" });
        return false;
      }
      if (this.customer_employees.some(checkRegistrationNumber)) {
        window.frontMsg("FRNT_IN_USE_WARNING", { field: "Número de Registro" });
        return false;
      }
      if (
        Number(this.employee.anamnesis_data.height) &&
        Number(this.employee.anamnesis_data.height) < 0
      ) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Altura",
          numbercondition: "maior que zero",
        });
        return false;
      }
      if (
        Number(this.employee.anamnesis_data.weight) &&
        Number(this.employee.anamnesis_data.weight) < 0
      ) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Peso",
          numbercondition: "maior que zero",
        });
        return false;
      }
      if (
        Number(this.employee.functional_data.work_week) &&
        Number(this.employee.functional_data.work_week) < 0
      ) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Jornada",
          numbercondition: "maior que zero",
        });
        return false;
      }

      let hireDate = new Date(
        formatDate(this.employee.functional_data.date_of_hire)
      );
      let dismissalDate = new Date(
        formatDate(this.employee.functional_data.date_of_dismissal)
      );
      if (dismissalDate < hireDate) {
        window.frontMsg("FRNT_ACTION_WARNING", {
          reason:
            "a Data de Desligamento não pode ser anterior à Data de Admissão.",
        });
        return false;
      }

      for(let i in this.employee.trainings){
        if(JSON.stringify(this.employee.trainings[i]) != JSON.stringify(this.empty_training)) {

          if(this.employee.trainings[i].attendence < 0){
            window.frontMsg("FRNT_NUM_RANGE_WARNING", {
              field: "Frequencia de treinamento",
              numbercondition: "maior ou igual a zero",
            });
            return false;
          }
          if(this.employee.trainings[i].expiration == ""){
            window.frontMsg("FRNT_EMPTY_WARNING",{
              field: "Expiração",
            });
            return false;
          }
          if(this.employee.trainings[i].grade < 0){
            window.frontMsg("FRNT_NUM_RANGE_WARNING", {
              field: "Nota",
              numbercondition: "maior ou igual a zero",
            });
            return false;
          }
          if(this.employee.trainings[i].name == ""){
            window.frontMsg("FRNT_EMPTY_WARNING",{
              field: "Expiração",
            });
            return false;
          }
          if(this.employee.trainings[i].schedules.length === 0){
            window.frontMsg("FRNT_EMPTY_WARNING",{
              field: "Expiração",
            });
            return false;
          }
        }
      }

      return true;
    },
    onSelectTraining(item){
      if(item.id !== 0){
        for (let training of this.customerTrainings){
          if(training.id == item.id){
           training.selected = false;
          }
        }
      }
      if(item.name !== ""){
        for (let training of this.customerTrainings){
          if(training.name == item.name){
           item.id = training.id;
           item.duration = training.duration;
           item.grade = 0;
           item.attendence = 0;
           item.schedules = training.schedules;
           item.expires_at = training.expires_at;
           training.selected = true;
          }
        }
      } else {
        item.id = 0;
        item.duration = 0;
        item.grade = 0;
        item.attendence = 0;
        item.expiration = "";
        item.schedules = [];
      }
    },
    onSelectSession(item){
      let sche = item.session;
      let end_date = new Date(formatDate(sche.t_end.split(" ")[0]) + "T" + sche.t_end.split(" ")[1]+"Z");
      end_date.setDate(end_date.getUTCDate() + item.expires_at);
      item.expiration = end_date.toISOString().split("T")[0];
    },
    saveEmployee() {
      event.preventDefault();
      if (!this.isEmployeeValid()) {
        return;
      }

      let employeeJson = Object.assign({}, this.employee);

      if (employeeJson.cellphone)
        employeeJson.cellphone = employeeJson.cellphone.replace(/[\s()-]/g, "");

      if (employeeJson.anamnesis_data.weight)
        employeeJson.anamnesis_data.weight = parseFloat(
          employeeJson.anamnesis_data.weight
        );

      if (employeeJson.anamnesis_data.height)
        employeeJson.anamnesis_data.height =
          parseFloat(employeeJson.anamnesis_data.height) || "";

      if (employeeJson.functional_data.work_week)
        employeeJson.functional_data.work_week = Math.round(
          employeeJson.functional_data.work_week
        );

      if (employeeJson.functional_data.last_ohc) {
        employeeJson.functional_data.last_ohc = formatDateAPI(
          employeeJson.functional_data.last_ohc
        );
      }

      if (employeeJson.date_of_birth) {
        employeeJson.date_of_birth = formatDateAPI(employeeJson.date_of_birth);
      }

      if (employeeJson.functional_data.date_of_hire) {
        employeeJson.functional_data.date_of_hire = formatDateAPI(
          employeeJson.functional_data.date_of_hire
        );
      }

      if (employeeJson.functional_data.date_of_dismissal) {
        employeeJson.functional_data.date_of_dismissal = formatDateAPI(
          employeeJson.functional_data.date_of_dismissal
        );
      }

      for(let i = 0; i < employeeJson.trainings.length;){
        if (employeeJson.trainings[i].expiration) {
          employeeJson.trainings[i].expiration = formatDateAPI(
            employeeJson.trainings[i].expiration
          );
        }
        if (employeeJson.trainings[i].attendence){
          employeeJson.trainings[i].attendence = employeeJson.trainings[i].attendence;
        }
        if (employeeJson.trainings[i].session){
          employeeJson.trainings[i].instructor = employeeJson.trainings[i].session.instructor;
          employeeJson.trainings[i].t_start = employeeJson.trainings[i].session.t_start;
          employeeJson.trainings[i].t_end = employeeJson.trainings[i].session.t_end;
          delete employeeJson.trainings[i].selected;
          delete employeeJson.trainings[i].session;
          delete employeeJson.trainings[i].schedules;
          delete employeeJson.trainings[i].expires_at;
        }
        if(JSON.stringify(employeeJson.trainings[i]) === JSON.stringify(this.empty_training)){
          employeeJson.trainings.splice(i,1);
        } else{
          i++;
        }
      }

      if (!employeeJson.functional_data.supervisor?.name) {
        delete employeeJson.functional_data.supervisor;
      }

      if(!employeeJson.trainings){
        delete employeeJson.trainings;
      }

      employeeJson = nullEmptyProps(employeeJson);

      this.$store.dispatch("employee/saveEmployee", {
        customer_id: this.customer.id,
        employee: employeeJson,
      });
    },
    changeTab(newTab) {
      this.tabSelected = newTab;
    },
    selectDisease(disease) {
      if (this.employee.anamnesis_data.preexisting_conditions.includes(disease))
        this.employee.anamnesis_data.preexisting_conditions =
          this.employee.anamnesis_data.preexisting_conditions.filter(
            (x) => x !== disease
          );
      else this.employee.anamnesis_data.preexisting_conditions.push(disease);
    },
    changeState(state) {
      this.employee.functional_data.date_of_dismissal =
        state !== "FIRED"
          ? this.employee.functional_data.date_of_dismissal
          : "";
    },
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    customer() {
      if (this.customer) {
        this.updateList();

        this.$store.dispatch("employee/getEmployees", {
          customer_id: this.customer.id,
        });

        this.$store.dispatch("training/getCustomerAllTrainings", {
          customer_id: this.customer.id,
        });
      }
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    customer_employees() {
      return this.$store.getters["employee/employees"];
    },
    training() {
      return this.$store.getters["training/trainings"];
    },
    functionalDataSelected() {
      return this.tabSelected === "functionalData";
    },
    diseasesList() {
      return this.employee.anamnesis_data.preexisting_conditions.join(", ");
    },
    anamnesisSelected() {
      return this.tabSelected === "anamnesis";
    },
    customerTrainings(){
      return this.$store.getters["training/trainings"];
    },
    trainingSelected() {
      return this.tabSelected === "training";
    },
    area_options() {
      return this.$store.getters["employee/areas"].map((area) => area.area);
    },
    department_options() {
      return this.$store.getters["employee/departments"].map(
        (department) => department.department
      );
    },
    position_options() {
      return this.$store.getters["employee/positions"].map(
        (position) => position.position
      );
    },
    training_options() {
      return this.$store.getters["training/trainings"].map((training) => training.name);
    },
    filteredSupervisors() {
      if (this.employeeSupervisor) {
        return this.customer_employees.filter((employee) => {
          return (
            employee.name
              .toLowerCase()
              .includes(this.employeeSupervisor.toLowerCase()) &&
            employee.id !== this.employee.id &&
            employee.functional_data?.position?.toLowerCase() === "supervisor"
          );
        });
      }
      return this.customer_employees.filter(
        (employee) =>
          employee.id !== this.employee.id &&
          employee.functional_data?.position?.toLowerCase() === "supervisor"
      );
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
};
</script>
