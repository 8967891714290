<template>
  <div v-can="['ADMIN', 'SYSADMIN', 'MANAGER','FRONTDESK', 'USER', 'DASHBOARD']">
    <Modal
    
      id="modalDeleteContact"
      title="Excluir contato"
      v-bind:msg="'Tem certeza que deseja excluir ' + contactDelete.name + '?'"
      button="Excluir"
      :method="deleteContact"
    />
    <div class="row px-0">
      <TwInput label="" col_lg="10" class="pr-0">
        <b-input-group>
          <b-input-group-prepend is-text
            ><i class="fas fa-search"></i>
          </b-input-group-prepend>
          <input
            style="max-width: 900px"
            v-model="filter"
            type="search"
            class="form-control"
            id="filterInputContact"
            placeholder="Buscar"
          />
        </b-input-group>
      </TwInput>

      <div class="col-2 d-flex flex-column-reverse pb-3">
        <b-button
        v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
          href="#"
          variant="primary"
          @click="
            () => {
              this.$router.push({ name: 'customer-contact-add' });
            }
          "
        >
          <i></i>
          Adicionar Contato
        </b-button>
      </div>
    </div>

    <b-table
      responsive="sm"
      striped
      borderless
      hover
      class="mt-3 rounded"
      no-sort-reset
      ref="selectableTable"
      sticky-header="610px"
      show-empty
      empty-text="Nenhum registro encontrado"
      empty-filtered-text="Nenhum registro encontrado para essa busca"
      head-variant="light"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortByContacts"
      :filter="filter"
      :busy="load"
      :fields="actualFields"
      :items="customerContacts"
      @filtered="onFiltered"
      @context-changed="changeBsTableState"
    >
      <template v-slot:table-colgroup>
        <col
          v-for="field in actualFields"
          :key="field.key"
          :style="{
            width: field.key === 'actions' ? '100px' : '550px',
          }"
        />
      </template>
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <strong>
            <i class="fas fa-sync-alt fa-spin"></i>
          </strong>
        </div>
      </template>
      <template v-slot:cell(phones)="data">
        <span v-for="(item, index) in data.item.phones" v-bind:key="index">{{
          formatCellphone(item)
        }}</span>
      </template>
      <template v-slot:cell(actions)="data">
        <a
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
          id="deleteContato"
          @click="contactDelete = data.item"
          href="#"
          class="mr-3"
          data-toggle="modal"
          data-target="#modalDeleteContact"
          title="Excluir"
        >
          <i id="deleteContact" class="far fa-trash-alt"></i>
        </a>
        <router-link
          :to="{
            name: 'customer-contact-edit',
            params: {
              customerId: customer ? customer.id : -1,
              contactId: data.item.id,
            },
          }"
          title="Editar"
          id="editContact-a"
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
        >
          <i id="editContact" class="fas fa-pen"></i>
        </router-link>
      </template>
    </b-table>

    <div class="row">
      <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="pagination"
        ></b-pagination>
      </div>
      <div class="col-12 col-md-8">
        <div class="btn-group" role="group">
          <b-dropdown
            variant="primary"
            v-model="perPage"
            :text="`Linhas: ${perPage}`"
          >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 10;
                }
              "
              >10</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 20;
                }
              "
              >20</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 50;
                }
              "
              >50</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = '1000';
                }
              "
              >1000</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>

    <div class="col-12 pr-4">
      <router-link
        id="addContato"
        :to="{
          name: 'customer-contact-add',
          params: { customerId: customer ? customer.id : -1 },
        }"
        v-can="['ADMIN', 'SYSADMIN', 'MANAGER']"
      >
        <!-- <i class="fas fa-plus mr-2"></i>
        contato -->
        
      </router-link>
    </div>
  </div>
</template>

<script>
import Modal from "../../../components/Modal.vue";
import { formatCellphone } from "../../../utils/format-fields";
import TwInput from "@/components/TwInput.vue";
import hasRole from "@/utils/hasRole";

export default {
  name: "ContactTab",
  data() {
    return {
      filter: null,
      contactDelete: "",
      perPage: 10,
      rows: 1,
      currentPage: 1,
      sortByContacts: "name",
      fieldsContacts: [
        { key: "name", sortable: true, label: "Nome" },
        { key: "phones", label: "Telefone" },
        { key: "actions", label: "Ações", class: "actions" },
      ],
    };
  },
  watch: {
    customer() {
      if (this.customer) {
        this.$store.dispatch("contact/getContacts", {
          customer_id: this.customer.id,
        });
      }
    },
    customerContacts() {
      if (this.$options.name) {
        const ctx = JSON.parse(
          localStorage.getItem(`bstable-state-${this.$options.name}`)
        );
        if (!ctx){
          this.perPage = 10;
          this.currentPage = 1;
          this.sortBy = "";
          this.rows = this.customerContacts.length;
          return;
        }
        if (ctx.filter !== "" && ctx.filter !== null) {
          this.filter = ctx.filter;
        }
        if (ctx.perPage) {
          this.perPage = ctx.perPage;
        }
        if (ctx.sortBy !== "") {
          this.sortBy = ctx.sortBy;
        }
      }
      this.rows = this.customerContacts.length;
    },
  },
  methods: {
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    onFiltered(customerContacts) {
      this.rows = customerContacts.length;
    },
    formatCellphone(cel) {
      return formatCellphone(cel);
    },
    deleteContact() {
      event.preventDefault();
      this.$store.dispatch("contact/deleteContact", {
        id: this.contactDelete.id,
      });
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    customerContacts() {
      return this.$store.getters["contact/contacts"];
    },
    actualFields() {
      if(hasRole(["ADMIN", "SYSADMIN", "MANAGER"])) {
        return this.fieldsContacts;
      }
      return this.fieldsContacts.slice(0, -1);
    }
  },
  components: {
    Modal,
    TwInput,
  },
};
</script>
