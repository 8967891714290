<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />

    <div class="content-header px-0">
      <h1 class="title-I">Adicionar usuário</h1>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 p-0 col-md-12">
        <div class="card direct-chat direct-chat-primary user">
          <div class="card-body">
            <div class="p-4 bg-white rounded">
              <form @submit="saveUser" novalidate="true">
                <div class="row">
                  <TwInput label="Nome" required="false">
                    <input
                      id="username"
                      v-model="name"
                      required
                      type="text"
                      maxlength="55"
                      class="form-control"
                      placeholder="Nome do usuário"
                    />
                  </TwInput>
                  <TwInput label="Celular">
                    <input
                      id="usercellphone"
                      v-model="cellphone"
                      v-mask="'+(##) ## #####-####'"
                      type="text"
                      class="form-control"
                      placeholder="Celular"
                    />
                    <small class="form-text text-muted"
                      >Ex: +(55) 41 99999-9999</small
                    >
                  </TwInput>
                </div>
                <div class="row">
                  <TwInput
                    label="E-mail"
                    required="false"
                    style="margin-bottom: 55px"
                  >
                    <input
                      id="useremail"
                      @focusout="validEmail"
                      v-model="email"
                      required
                      type="email"
                      class="form-control"
                      :class="emailError || wrongFormat ? 'is-invalid' : ''"
                      placeholder="Email"
                      maxlength="55"
                    />
                    <span
                      v-if="emailError"
                      id="exampleInputEmail1-error"
                      class="error invalid-feedback"
                    >
                      Email já esta em uso
                    </span>
                    <span
                      v-else-if="wrongFormat"
                      id="exampleInputEmail1-error"
                      class="error invalid-feedback"
                    >
                      Email inválido
                    </span>
                  </TwInput>
                  <TwInput
                    label="Senha"
                    required="false"
                    v-if="user_type === 'LOCAL'"
                  >
                    <div class="input-group">
                      <input
                        @change="passwordVerification()"
                        id="userpassword"
                        v-model="password"
                        required
                        v-bind:style="
                          new_passwordError
                            ? 'border: 1px solid red;'
                            : 'height: 45px; width: 50%'
                        "
                        :type="!passShow ? 'password' : 'text'"
                        class="form-control"
                        placeholder="Senha"
                        maxlength="255"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <a @click="showPass" href="/">
                            <i
                              v-bind:class="{
                                'far fa-eye': passShow,
                                'far fa-eye-slash': !passShow,
                              }"
                            ></i>
                          </a>
                        </span>
                      </div>
                    </div>

                    <small
                      v-if="!new_passwordError"
                      class="form-text text-muted"
                      style="font-size: 105%"
                      >Mínimo 8 caracteres, 1 maiúscula, 1 minúscula, 1 especial
                      e 1 número
                    </small>

                    <small
                      v-if="new_passwordError"
                      class="form-text"
                      style="color: red; font-size: 105%"
                      >Mínimo 8 caracteres, 1 maiúscula, 1 minúscula, 1 especial
                      e 1 número
                    </small>
                    <small
                      class="form-text text-muted"
                      style="font-size: 85%; font-weight: bold"
                      >Exemplo: MMaa#*01</small
                    >
                  </TwInput>
                </div>

                <div class="row mt-3">
                  <TwInput col_lg="4" label="Status" required="false">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="radioButtonStatus"
                        id="statusACTIVE"
                        value="ACTIVE"
                        v-model="status"
                        size="md"
                      />
                      <label class="form-check-label" for="statusACTIVE">
                        ATIVO
                      </label>
                      <i
                        title="O usuário está ativo e tem acesso ao portal"
                        class="fas fa-question-circle"
                        style="margin-left: 10px"
                      >
                      </i>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="radioButtonStatus"
                        id="statusINACTIVE"
                        value="INACTIVE"
                        v-model="status"
                        size="md"
                        checked
                      />
                      <label class="form-check-label" for="statusINACTIVE">
                        INATIVO
                      </label>
                      <i
                        title="O usuário esta inativo e não tem acesso ao portal"
                        class="fas fa-question-circle"
                        style="margin-left: 10px"
                      >
                      </i>
                    </div>
                  </TwInput>
                  <TwInput col_lg="4" label="Tipo de usuário" required="true">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="radioButtonUserType"
                        id="userLOCAL"
                        value="LOCAL"
                        v-model="user_type"
                        size="md"
                        checked
                      />
                      <label class="form-check-label" for="userLOCAL">
                        INTERNO
                      </label>
                      <i
                        title="A autenticação do usuário é interna."
                        class="fas fa-question-circle"
                        style="margin-left: 10px"
                      >
                      </i>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="radioButtonUserType"
                        id="userEXTERNAL"
                        value="EXTERNAL"
                        v-model="user_type"
                        size="md"
                      />
                      <label class="form-check-label" for="userEXTERNAL">
                        EXTERNO
                      </label>
                      <i
                        title="A autenticação do usuário é externa."
                        class="fas fa-question-circle"
                        style="margin-left: 10px"
                      >
                      </i>
                    </div>
                  </TwInput>
                  <TwInput
                    col_lg="4"
                    label="Duplo fator"
                    v-if="user_type === 'LOCAL'"
                  >
                    <b-form-checkbox
                      id="tfa"
                      v-model="tfa"
                      name="tfa"
                      size="md"
                    >
                      Utilizar duplo fator para autenticar usuário
                      <i
                        title="A configuração de duplo fator definida pelo grupo será prioritária"
                        class="fas fa-question-circle"
                      >
                      </i>
                    </b-form-checkbox>
                  </TwInput>
                </div>

                <div class="row mt-3">
                  <TwInput col_lg="4" label="Grupo" required="false">
                    <select
                      id="userselect"
                      v-model="groupsSelecteds"
                      multiple
                      required
                      class="form-control p-0"
                    >
                      <option
                        v-for="(group, index) in groups"
                        :key="index"
                        class="p-2"
                        :value="group.name"
                      >
                        {{ group.name }}
                      </option>
                    </select>
                  </TwInput>

                  <TwInput col_lg="4" label="Workgroup">
                    <select
                      id="userselectworkgroup"
                      v-model="workgroupsSelecteds"
                      multiple
                      class="form-control p-0"
                    >
                      <option
                        v-for="(workgroup, index) in workgroups"
                        :key="index"
                        class="p-2"
                        :value="workgroup.name"
                      >
                        {{ workgroup.name }}
                      </option>
                    </select>
                    <small id="permissionaHelp" class="form-text text-muted">
                      Clique e segure o botão "ctrl" e selecione os níveis
                      desejados com o clique do mouse.
                    </small>
                  </TwInput>

                  <TwInput col_lg="4" label="Nível de acesso" required="false">
                    <select
                      id="userselectroles"
                      v-model="rolesSelecteds"
                      multiple
                      required
                      class="form-control p-0"
                    >
                      <option
                        v-for="(role, index) in roles"
                        :key="index"
                        class="p-2"
                        :value="role.name"
                      >
                        {{ role.name }}
                      </option>
                    </select>
                  </TwInput>
                </div>

                <!-- TAB HERE-->
                <div
                  class="pt-4 pr-4 pb-2 bg-white rounded"
                  v-if="user_type === 'LOCAL'"
                >
                  <div class="row mb-2">
                    <div class="col-12">
                      <div class="card direct-chat direct-chat-primary">
                        <div class="card-body custom-card overflow-visible">
                          <ul class="nav nav-tabs bg-main">
                            <li class="nav-item">
                              <a
                                id="autentication-tab"
                                class="nav-link first"
                                v-bind:class="{
                                  active: autenticationSelected,
                                }"
                                v-on:click="changeTab('autentication')"
                                href="#"
                                aria-current="autentication"
                                tabindex="-1"
                                >Dados de autenticação</a
                              >
                            </li>
                          </ul>
                          <div class="p-4 bg-white rounded">
                            <div class="tab-content">
                              <div
                                class="tab-pane fade"
                                v-bind:class="{
                                  show: autenticationSelected,
                                  active: autenticationSelected,
                                }"
                              >
                                <div class="row">
                                  <div class="col">
                                    <ul class="nav nav-tabs">
                                      <li class="nav-item">
                                        <a
                                          id="autenticationAcess"
                                          class="nav-link first"
                                          >Expiração Token Acess</a
                                        >
                                      </li>
                                    </ul>
                                    <TwInput
                                      class="pt-4"
                                      col_lg="6"
                                      label="Dias"
                                    >
                                      <input
                                        id="useradd-expire-access-days"
                                        v-model="access_token_expiry.days"
                                        class="form-control"
                                        placeholder="Dias"
                                      />
                                    </TwInput>
                                    <TwInput col_lg="6" label="Horas">
                                      <input
                                        id="useradd-expire-access-hrs"
                                        v-model="access_token_expiry.hours"
                                        class="form-control"
                                        placeholder="Horas"
                                      />
                                    </TwInput>
                                    <TwInput col_lg="6" label="Minutos">
                                      <input
                                        v-model="access_token_expiry.minutes"
                                        class="form-control"
                                        placeholder="Minutos"
                                        id="accessMinutes"
                                      />
                                    </TwInput>
                                    <TwInput col_lg="6" label="Segundos">
                                      <input
                                        v-model="access_token_expiry.seconds"
                                        class="form-control"
                                        placeholder="Segundos"
                                        id="accessSeconds"
                                      />
                                    </TwInput>
                                  </div>

                                  <div class="col">
                                    <ul class="nav nav-tabs">
                                      <li class="nav-item">
                                        <a
                                          id="autenticationRefresh"
                                          class="nav-link first"
                                          >Expiração Token Refresh</a
                                        >
                                      </li>
                                    </ul>
                                    <TwInput
                                      class="pt-4"
                                      col_lg="6"
                                      label="Dias"
                                    >
                                      <input
                                        v-model="refresh_token_expiry.days"
                                        class="form-control"
                                        placeholder="Dias"
                                        id="useradd-refresh-access-days"
                                      />
                                    </TwInput>
                                    <TwInput col_lg="6" label="Horas">
                                      <input
                                        v-model="refresh_token_expiry.hours"
                                        class="form-control"
                                        placeholder="Horas"
                                        id="useradd-refresh-access-hrs"
                                      />
                                    </TwInput>
                                    <TwInput col_lg="6" label="Minutos">
                                      <input
                                        v-model="refresh_token_expiry.minutes"
                                        class="form-control"
                                        placeholder="Minutos"
                                        id="refreshMinutes"
                                      />
                                    </TwInput>
                                    <TwInput col_lg="6" label="Segundos">
                                      <input
                                        v-model="refresh_token_expiry.seconds"
                                        class="form-control"
                                        placeholder="Segundos"
                                        id="refreshSeconds"
                                      />
                                    </TwInput>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row p-1">
                  <button
                    id="useraddsave"
                    class="btn btn-primary px-5"
                    :disabled="load"
                  >
                    <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import TwInput from "@/components/TwInput.vue";
import "./style.scss";

export default {
  name: "AddUser",
  data: function () {
    return {
      tabSelected: "autentication",
      breadcrumbList: [],
      groupsSelecteds: [],
      workgroupsSelecteds: [],
      rolesSelecteds: [],
      status: "INACTIVE",
      user_type: "LOCAL",
      tfa: false,
      email: "",
      name: "",
      password: "",
      cellphone: "",
      emailError: false,
      wrongFormat: false,
      new_passwordError: false,
      passShow: false,
      access_token_expiry: {
        days: 0,
        hours: 0,
        minutes: 15,
        seconds: 0,
      },
      refresh_token_expiry: {
        days: 0,
        hours: 0,
        minutes: 60,
        seconds: 0,
      },
    };
  },
  mounted() {
    this.updateList();
    this.$store.dispatch("user/getGroupsAndWorkgroups");
    this.$store.dispatch("user/getRoles");
    this.$store.dispatch("user/getWorkgroups", {
      group_name: this.userData.user_groups.name,
    });
  },
  watch: {
    $route() {
      this.updateList;
    },
    groupsSelecteds() {
      if (this.groupsSelecteds.length > 1)
        this.groupsSelecteds = [this.groupsSelecteds.slice(-1).pop()];
      let gpName = this.groupsSelecteds[0];
      this.workgroups = this.groupWorkgroup[gpName];
    },
    rolesSelecteds() {
      if (this.rolesSelecteds.length > 1)
        this.rolesSelecteds = [this.rolesSelecteds.slice(-1).pop()];
    },
  },
  methods: {
    validEmail() {
      if (this.email) {
        if (this.email.includes("@") && this.email.includes(".")) {
          this.wrongFormat = false;
          this.$store
            .dispatch("user/verifyUser", { email: this.email })
            .then(() => {
              this.emailError = false;
            })
            .catch(() => {
              window.frontMsg("FRNT_IN_USE_WARNING", { field: "E-mail" });
              this.emailError = true;
            });
        } else {
          window.frontMsg("FRNT_INVALID_FIELD_WARNING", { field: "E-mail" });
          this.wrongFormat = true;
        }
      }
    },
    showPass() {
      event.preventDefault();
      this.passShow = !this.passShow;
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    saveUser() {
      event.preventDefault();

      let user_obj = {
        name: this.name,
        status: this.status,
        cellphone: this.cellphone.replace(/[\s()-]/g, ""),
        email: this.email,
        roles: this.rolesSelecteds ? this.rolesSelecteds : [],
        workgroups: this.workgroupsSelecteds ? this.workgroupsSelecteds : [],
        user_groups: { name: this.groupsSelecteds[0] },
      };

      if(this.isUserValid()){
        if (this.user_type === "EXTERNAL") {
          this.$store.dispatch("user/saveUserExternal", user_obj);
        } else {
          user_obj.access_token_expiry = this.access_token_expiry;
          user_obj.refresh_token_expiry = this.refresh_token_expiry;
          user_obj.tfa = this.tfa;
          user_obj.password = this.password;
          this.$store.dispatch("user/saveUser", user_obj);
        } 
      }
    },
    isUserValid() {
      //Checks if the data is valid.  
      if (!this.name || this.name.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Nome" });
        return false;
      }

      if (!this.email || this.email.trim() === "") {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "E-mail" });
        return false;
      }

      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.email.trim()
        )
      ) {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", { field: "E-mail" });
        return false;
      }

      if (/admin/i.test(this.email.trim())) {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
          field: "E-mail",
          extra: 'O email não pode conter nenhuma variação de "admin".',
        });
        return false;
      }

      if (
        (!this.password || this.password.trim() === "") &&
        this.user_type === "LOCAL"
      ) {
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Senha" });
        return false;
      }

      if (
        (this.cellphone.replace(/[\s()+-]/g, "").length < 11 ||
          this.cellphone.replace(/[\s()+-]/g, "").length > 14) &&
        this.cellphone !== ""
      ) {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
          field: "Número de Celular",
        });
        return false;
      }

      for (let user of this.users) {
        if (user.cellphone) {
          if (
            user.cellphone.replace(/[\s()-]/g, "") ===
              this.cellphone.replace(/[\s()-]/g, "") &&
            user.email !== this.email
          ) {
            window.frontMsg("FRNT_IN_USE_WARNING", {
              field: "Número de Celular",
            });
            return false;
          }
        }
      }

      if (
        !this.groupsSelecteds ||
        this.groupsSelecteds.length != 1 ||
        this.groupsSelecteds[0].trim() === ""
      ) {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
          field: "Grupo",
          extra: "Selecione um grupo para o usuário",
        });
        return false;
      }

      if (!this.rolesSelecteds || this.rolesSelecteds.length == 0) {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
          field: "Nível de Acesso",
          extra: "É necessário selecionar pelo menos um nível de acesso",
        });
        return false;
      }
      if (this.user_type === "LOCAL") {
        if (
          this.access_token_expiry.days != "" &&
          (parseInt(this.access_token_expiry.days) < 0 ||
          isNaN(parseInt(this.access_token_expiry.days)))
        ) {
          window.frontMsg("FRNT_NUM_RANGE_WARNING", {
            field: "Dias",
            numbercondition: "inteiro maior que zero",
          });
          return false;
        }

        if (
          this.access_token_expiry.hours != "" &&
          (parseInt(this.access_token_expiry.hours) < 0 ||
          isNaN(parseInt(this.access_token_expiry.hours)))
        ) {
          window.frontMsg("FRNT_NUM_RANGE_WARNING", {
            field: "Horas",
            numbercondition: "inteiro maior que zero",
          });
          return false;
        }
        if (
          this.access_token_expiry.minutes != "" &&
          (parseInt(this.access_token_expiry.minutes) < 0 ||
          isNaN(parseInt(this.access_token_expiry.minutes)))
        ) {
          window.frontMsg("FRNT_NUM_RANGE_WARNING", {
            field: "Minutos",
            numbercondition: "inteiro maior que zero",
          });
          return false;
        }
        if (
          this.access_token_expiry.seconds != "" &&
          (parseInt(this.access_token_expiry.seconds) < 0 ||
          isNaN(parseInt(this.access_token_expiry.seconds)))
        ) {
          window.frontMsg("FRNT_NUM_RANGE_WARNING", {
            field: "Segundos",
            numbercondition: "inteiro maior que zero",
          });
          return false;
        }
        if (
          this.refresh_token_expiry.days != "" &&
          (parseInt(this.refresh_token_expiry.days) < 0 ||
          isNaN(parseInt(this.refresh_token_expiry.days)))
        ) {
          window.frontMsg("FRNT_NUM_RANGE_WARNING", {
            field: "Dias",
            numbercondition: "inteiro maior que zero",
          });
          return false;
        }
        if (
          this.refresh_token_expiry.hours != "" &&
          parseInt(this.refresh_token_expiry.hours) < 0 ||
          isNaN(parseInt(this.refresh_token_expiry.hours))
        ) {
          window.frontMsg("FRNT_NUM_RANGE_WARNING", {
            field: "Horas",
            numbercondition: "inteiro maior que zero",
          });
          return false;
        }
        if (
          this.refresh_token_expiry.minutes != "" &&
          parseInt(this.refresh_token_expiry.minutes) < 0 ||
          isNaN(parseInt(this.refresh_token_expiry.minutes))
        ) {
          window.frontMsg("FRNT_NUM_RANGE_WARNING", {
            field: "Minutos",
            numbercondition: "inteiro maior que zero",
          });
          return false;
        }
        if (
          this.refresh_token_expiry.seconds != "" &&
          parseInt(this.refresh_token_expiry.seconds) < 0 ||
          isNaN(parseInt(this.refresh_token_expiry.seconds))
        ) {
          window.frontMsg("FRNT_NUM_RANGE_WARNING", {
            field: "Segundos",
            numbercondition: "inteiro maior que zero",
          });
          return false;
        }

        this.access_token_expiry = {
          days: ~~this.access_token_expiry.days || 0,
          hours: ~~this.access_token_expiry.hours || 0,
          minutes: ~~this.access_token_expiry.minutes || 0,
          seconds: ~~this.access_token_expiry.seconds || 0,
        };
        this.refresh_token_expiry = {
          days: ~~this.refresh_token_expiry.days || 0,
          hours: ~~this.refresh_token_expiry.hours || 0,
          minutes: ~~this.refresh_token_expiry.minutes || 0,
          seconds: ~~this.refresh_token_expiry.seconds || 0,
        };

        const access_seconds =
          ~~this.access_token_expiry.days * 24 * 60 * 60 +
          ~~this.access_token_expiry.hours * 60 * 60 +
          ~~this.access_token_expiry.minutes * 60 +
          ~~this.access_token_expiry.seconds;
        const refresh_seconds =
          ~~this.refresh_token_expiry.days * 24 * 60 * 60 +
          ~~this.refresh_token_expiry.hours * 60 * 60 +
          ~~this.refresh_token_expiry.minutes * 60 +
          ~~this.refresh_token_expiry.seconds;

        if (access_seconds > refresh_seconds) {
          window.frontMsg("FRNT_NUM_RANGE_WARNING", {
            field: "Duração do Token de Acesso",
            numbercondition:
              "que não pode ser maior que a duração do token de atualização",
          });
          return false;
        }

        if (this.new_passwordError) {
          window.frontMsg("FRNT_INVALID_FIELD_WARNING", { field: "Senha" });

          return false;
        }
      }
      return true;
    },
    passwordVerification() {
      if (
        /[a-z]/.test(this.password) &&
        /[A-Z]/.test(this.password) &&
        /\d/.test(this.password) &&
        /[^a-zA-Z0-9]/.test(this.password) &&
        this.password.toString().length >= 8
      ) {
        this.new_passwordError = false;
      } else {
        this.new_passwordError = true;

        window.frontMsg("FRNT_INVALID_FIELD_WARNING", { field: "Senha" });
      }
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    users() {
      return this.$store.getters["user/users"];
    },
    groups() {
      return this.$store.getters["user/groups"];
    },
    roles() {
      return this.$store.getters["user/roles"];
    },
    groupWorkgroup() {
      return this.$store.getters["user/groupWorkgroup"];
    },
    autenticationSelected() {
      return this.tabSelected === "autentication";
    },
  },
};
</script>
