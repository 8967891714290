<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="id"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
          <button
            id="close-modal"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">{{ msg }}</div>

        <div class="modal-footer modalMainButtons">
           <div>
            <button
              id="close-modal2"
              style="margin-right: 8px"
              class="btn btn-outline-dark"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              Cancelar
            </button>
            <button
              id="modalexcluir"
              @click="mainMethod()"
              type="button"
              :class="`btn btn-${buttonMod || 'danger'}`"
              data-dismiss="modal"
              :disabled="disableMainButton"
            >
              {{ mainButton }}
            </button>
            <button
              v-if="id === 'ExportModal'"
              type="button"
              class="btn btn-success"
              data-dismiss="modal"
            >
              Sim
            </button>
          </div>
          <div>
            <button
              id="secondaryButton"
              @click="secMethod()"
              :class="`btn btn-${secButtonMod || 'dark'}`"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              {{  secButton  }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    id: String,
    msg: String,
    title: String,
    mainButton: String,
    secButton: String,
    secButtonMod: String,
    buttonMod: String,
    disableMainButton: Boolean,
    mainMethod: Function,
    secMethod: Function,
  },
};
</script>

<style scoped>
  .modalMainButtons{
    display:flex; 
    justify-content:space-between; 
    flex-direction: row-reverse;
  }

  .btn-orange {
  color: #1F2D3D;
  background-color: #ffa517;
  border-color: #ffa517;
  box-shadow: none;
  }

  .btn-orange:hover {
    color: #1F2D3D;
    background-color: #e38b00;
    border-color: #e38b00;
  }

  .btn-orange:focus,
  .btn-orange.focus {
    box-shadow: none, 0 0 0 0 rgba(193, 118, 0, 0.5);
  }

  .btn-orange.disabled,
  .btn-orange:disabled {
    color: #1F2D3D;
    background-color: #ffc107;
    border-color: #ffc107;
  }

  .btn-orange:not(:disabled):not(.disabled):active,
  .btn-orange:not(:disabled):not(.disabled).active,
  .show>.btn-orangewarning.dropdown-toggle {
    color: #1F2D3D;
    background-color: #d28100;
    border-color: #c17600;
  }

  .btn-orange:not(:disabled):not(.disabled):active:focus,
  .btn-orangewarning:not(:disabled):not(.disabled).active:focus,
  .show>.btn-orange.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(193, 118, 0, 0.5);
  }
</style>