<template>
  <div v-can="['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']">
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Adicionar Treinamento</h1>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card direct-chat direct-chat-primary training">
          <form @submit="saveTrainings">
            <div class="card-body">
              <div class="pt-4 pr-4 pb-2 pl-4 bg-white rounded">
                <div class="row">
                  <TwInput col_lg="12" label="Nome do treinamento" required="true">
                    <input
                      v-model="training.name"
                      required
                      type="text"
                      maxlength="55"
                      class="form-control"
                      placeholder="Nome do treinamento"
                      id="trainingName"
                    />
                  </TwInput>
                </div>
                <div class="row">
                  <TwInput label="Categoria de treinamento" required="true" col_lg="4">
                    <select
                      v-model="training.category"
                      required
                      class="form-control"
                      id="trainingCategory"
                    >
                      <option value="">Selecione</option>
                      <option
                        v-for="(categoria, index) in category_options"
                        :key="index"
                        :value="categoria.toUpperCase()"
                      >
                        {{ categoria }}
                      </option>
                    </select>
                  </TwInput>
                  <TwInput label="Área" required="true" col_lg="4">
                    <select
                      v-model="training.area"
                      required
                      class="form-control"
                      id="trainingArea"
                    >
                      <option value="">Selecione</option>
                      <option
                        v-for="(area, index) in area_options"
                        :key="index"
                        :value="area.toUpperCase()"
                      >
                        {{ area }}
                      </option>
                    </select>
                  </TwInput>
                  <TwInput label="Prazo de validade (dias)" required="true" col_lg="4">
                    <input
                      v-model="training.expires_at"
                      required
                      type="number"
                      min="0"
                      max="3650"
                      class="form-control"
                      placeholder="0"
                      id="trainingValidation"
                    />
                  </TwInput>     
                </div>
                <div class="row">
                  <TwInput label="Duração do treinamento (horas)" required="true" col_lg="4">
                    <input
                      v-model="training.duration"
                      required
                      type="number"
                      min="0"
                      max="9999"
                      step="0.25"
                      class="form-control"
                      placeholder="0"
                      id="trainingDuration"
                    />
                  </TwInput>
                  <TwInput label="Frequência mínima de aprovação (%)" required="true" col_lg="4">
                    <input
                      v-model="training.min_attendence"
                      required
                      type="number"
                      min="1"
                      max="100"
                      step="1"
                      class="form-control"
                      placeholder="0"
                      id="trainingMinAttendence"
                    />
                  </TwInput>
                  <TwInput label="Nota mínima de aprovação (Máx.: 100)" col_lg="4">
                    <input
                      v-model="training.min_grade"
                      type="number"
                      min="0"
                      max="100"
                      step="1"
                      class="form-control"
                      placeholder="0"
                      id="trainingMinGrade"
                    />
                  </TwInput>
                </div>
                <div class="row mb-2">
                </div>
                <div class="row">
                  <TwInput col_lg="12" row_lg="30" label="Descrição do treinamento">
                    <input
                      v-model="training.description"
                      type="text"
                      class="form-control"
                      placeholder="Descrição do treinamento"
                      maxlength="256"
                      id="trainingDescription"
                    />
                  </TwInput>
                </div>
                <div class="row pb-3 pl-2">
                  <button
                    id="saveTrainings"
                    class="btn btn-primary px-5"
                    :disabled="load"
                  >
                    <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import { formatDate, formatDateAPI } from "../../utils/format-fields";
import { nullEmptyProps } from "../../utils/format-json";
import "./style.scss";
import TwInput from "@/components/TwInput.vue";

export default {
  name: "AddTraining",
  data: function () {
    return {
      breadcrumbList: [],
      training:{
        name: "",
        category: "",
        area: "",
        duration: "",
        description: "",
        expires_at: "",
        min_attendence: "",
        min_grade: "",
      },
    };
  },
  mounted() {
    function getTodayDate() {
      let day, month, year;
      let today = new Date();

      day = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();
      month =
        today.getMonth() + 1 < 10
          ? `0${today.getMonth() + 1}`
          : today.getMonth() + 1;
      year = today.getFullYear();

      return `${year}-${month}-${day}`;
    }
    if (this.$route.params.customerId) {
      this.$store.dispatch("employee/getAreas", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("training/getCustomerCategories", {
        customer_id: this.$route.params.customerId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
      this.$router.push("/");
    }

    // this.employee.functional_data.date_of_hire = getTodayDate();
    this.updateList();
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = this.customer.company_name;
      }
    },
    saveTrainings() {
      event.preventDefault();

      let trainingJson = Object.assign({}, this.training);

      /*API DATE FORMAT*/
      if (trainingJson.t_start) {
        trainingJson.t_start = formatDateAPI(trainingJson.t_start);
        trainingJson.t_start= trainingJson.t_start.concat(" ",this.schedule_start);
      }
      if (trainingJson.t_end) {
        trainingJson.t_end = formatDateAPI(trainingJson.t_end);
        trainingJson.t_end= trainingJson.t_end.concat(" ",this.schedule_end);
      }

      trainingJson = nullEmptyProps(trainingJson);
      for (let [key, value] of Object.entries(trainingJson)) {
        if (!trainingJson[key]) {
          delete trainingJson[key];
        }
      }

      trainingJson.name = trainingJson.name.toUpperCase();
      
      this.$store.dispatch("training/saveTraining", {
        customer_id: this.customer.id,
        trainings: [trainingJson],
      });
    },

  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    customer() {
      if (this.customer) {
        this.updateList();

        this.$store.dispatch("employee/getEmployees", {
          customer_id: this.customer.id,
        });
      }
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    area_options() {
      return this.$store.getters["employee/areas"].map((area) => area.area);
    },
    category_options() {
      return this.$store.getters["training/categories"].map((category) => category.name);
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
};
</script>
