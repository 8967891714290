<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Importar Ativos</h1>
    </div>
    <AssetImportConfig :entireAsset="editAssetConfig"></AssetImportConfig>
    <div class="col-12 col-sm-12 p-0 col-md-12">
      <div class="card direct-chat direct-chat-primary">
        <div class="card-body">
          <div class="p-4 bg-white rounded">
            <div class="row">
              <div class="col-12 pr-4">
                <div class="form-group">
                  <UploadExcel
                    :on-success="handleSuccess"
                    :before-upload="beforeUpload"
                    :draggable="true"
                  />
                </div>
              </div>
            </div>

            <div v-show="tableData.length">
              <div class="content-header px-0">
                <h4>Ativos encontrados</h4>
              </div>
              <b-table
                id="importAssetsTable"
                responsive
                striped
                borderless
                hover
                :fields="fields"
                :items="tableData"
                class="mt-3 rounded"
                selectable
                @row-selected="onRowSelected"
                select-mode="multi"
                ref="selectableTable"
                editableFields
              >
                <template
                  v-for="field in editableFields"
                  v-slot:[`cell(${field.key})`]="{ item }"
                >
                  <input
                    type="text"
                    class="form-control"
                    v-if="
                      field.key !== 'gender' &&
                      field.key !== 'gateway_type' &&
                      field.key !== 'gateway_model'
                    "
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'imp-' + field.key"
                    @change="isAssetValid(item)"
                  />

                  <span
                    v-if="item.errors"
                    style="color: red"
                    :key="'erro-' + field.key"
                    >{{ item.errors[field.key] }}</span
                  >
                </template>
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true" class="check-symbol">&check;</span>
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                  </template>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center my-2">
                    <strong>
                      <i class="fas fa-sync-alt fa-spin"></i>
                    </strong>
                  </div>
                </template>
                <template v-slot:table-colgroup>
                  <col
                    v-for="field in fields"
                    :key="field.key"
                    :style="{
                      width:
                        field.key === 'selected' ||
                        field.key === 'actions' ||
                        field.key === 'asset_config'
                          ? '150px'
                          : '550px',
                    }"
                  />
                </template>
                <template v-slot:cell(invalid)="data">
                  <span
                    v-if="data.item.invalid"
                    class="valid-import badge badge-danger"
                    >Inválido</span
                  >
                  <span
                    v-if="!data.item.invalid"
                    class="valid-import badge badge-success"
                    >Válido</span
                  >
                </template>
                <template v-slot:cell(asset_config)="data">
                  <div style="text-align: center">
                    <i
                      class="fas fa-xl fa-edit fa-1x"
                      style="color: gray; margin: 2px"
                      @click="editAssetConfig = data.item"
                      data-toggle="modal"
                      data-target="#import-asset-config-modal"
                    ></i>
                  </div>
                </template>
                <template v-slot:cell(actions)="data">
                  <a
                    @click="user = data.item"
                    href="#"
                    class="mr-3"
                    data-toggle="modal"
                    data-target="#modalDeleteUser"
                    title="Excluir"
                    v-if="
                      storage
                        ? storage.getItem('email') != data.item.email
                        : false
                    "
                  >
                    <i class="far fa-trash-alt"></i>
                  </a>
                  <router-link
                    :to="{ name: 'user-edit', params: { user: data.item } }"
                    title="Editar"
                  >
                    <i class="fas fa-pen"></i>
                  </router-link>
                </template>
              </b-table>

              <div class="row p-1">
                <button
                  id="selectAll"
                  type="button"
                  class="btn btn-outlined btn-primary mr-2"
                  @click="selectAllRows"
                >
                  Selecionar tudo
                </button>
                <button
                  id="desselectAll"
                  type="button"
                  class="btn btn-outlined btn-primary mr-2"
                  @click="clearSelected"
                >
                  Desselecionar tudo
                </button>
                <button
                  type="button"
                  class="btn btn-outlined btn-danger mr-2"
                  @click="deleteSelected"
                  id="btn-delete-selected"
                >
                  Remover selecionados
                </button>
                <button
                  id="btn-saveAssets-selected"
                  @click="saveAssets"
                  class="btn btn-success px-5"
                  :disabled="load"
                >
                  <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                </button>
              </div>
            </div>
            <span v-if="!tableData.length" style="color: blue">
              Selecione um arquivo de colaboradores |
              <a v-bind:href="item.loc" download>Modelo de importação xlsx</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadExcel from "@/components/UploadExcel/index.vue";
import Breadcrumb from "@/components/Breadcrumb";
import AssetImportConfig from "@/views/Asset/ImportConfig.vue";
import { formatDate, formatDateAPI } from "@/utils/format-fields.js";

export default {
  components: { Breadcrumb, UploadExcel, AssetImportConfig },
  data() {
    return {
      breadcrumbList: [],
      tableData: [],
      tableHeader: [],
      storage: null,
      user: "",
      sortBy: "name",
      currentPage: 1,
      fields: [
        { key: "selected", sortable: false, label: "Selecionado" },
        {
          key: "name",
          editable: true,
          sortable: true,
          label: "Nome",
        },
        {
          key: "serial_number",
          editable: true,
          sortable: false,
          label: "Número de série",
        },
        {
          key: "internal_id",
          editable: true,
          sortable: true,
          label: "Identificador Interno",
        },
        {
          key: "category",
          editable: true,
          sortable: true,
          label: "Categoria",
        },
        {
          key: "subcategory",
          editable: true,
          sortable: true,
          label: "Subcategoria",
        },
        {
          key: "asset_config",
          label: "Configuração",
        },
        {
          key: "invalid",
          label: "Validade",
        },
      ],
      selected: [],
      fieldsHeader: [
        {
          key: "name",
          label: "Nome do Ativo",
          type: "string",
        },
        {
          key: "internal_id",
          label: "Identificador Interno",
          type: "string",
        },
        {
          key: "serial_number",
          label: "Número de Série",
          type: "string",
        },
        {
          key: "functional_data.area",
          label: "Área",
          type: "string",
        },
        {
          key: "functional_data.department",
          label: "Departamento",
          type: "string",
        },
        {
          key: "functional_data.supervisor",
          label: "Responsável",
          type: "string",
        },
        {
          key: "functional_data.importance",
          label: "Importância",
          type: ["ALTA", "MÉDIA", "BAIXA"],
        },
        {
          key: "financial_data.acquisition_cost",
          label: "Custo de Aquisição",
          type: "float",
        },
        {
          key: "financial_data.salvage_value",
          label: "Valor Residual",
          type: "float",
        },
        {
          key: "functional_data.state",
          label: "Estado Funcional",
          type: ["ATIVO", "INATIVO", "SUCATEADO"],
        },
        {
          key: "warranty_data.status",
          label: "Estado da Garantia",
          type: "automatic",
        },
        {
          key: "warranty_data.valid_until",
          label: "Validade da Garantia",
          type: "date",
        },
        {
          key: "category",
          label: "Categoria",
          type: "string",
        },
        {
          key: "subcategory",
          label: "Subcategoria",
          type: "string",
        },
        {
          key: "acquisition_date",
          label: "Data de Aquisição",
          type: "date",
        },
        {
          key: "financial_data.depreciation_rate",
          label: "Taxa de Depreciação Anual",
          type: "integer",
        },
        {
          key: "financial_data.expiration_date",
          label: "Data de Descarte",
          type: "date",
        },
        {
          key: "financial_data.lifespan",
          label: "Vida Útil Total",
          type: "integer",
        },
        {
          key: "financial_data.ncm",
          label: "NCM",
          type: "integer",
        },
        {
          key: "financial_data.useful_life",
          label: "Vida Útil Restante",
          type: "integer",
        },
        {
          key: "financial_data.borrower",
          label: "Comodatário",
          type: "string",
        },
        {
          key: "financial_data.last_nf_number",
          label: "Número da Última NF",
          type: "integer",
        },
        {
          key: "financial_data.asset_movement_date",
          label: "Data do Movimento do Ativo",
          type: "date",
        },
        {
          key: "financial_data.nf_file",
          label: "Arquivo da NF",
          type: "",
        },
        {
          key: "functional_data.manufacturer",
          label: "Fabricante",
          type: "string",
        },
        {
          key: "functional_data.model",
          label: "Modelo",
          type: "string",
        },
        {
          key: "functional_data.quality_state",
          label: "Estado de Qualidade",
          type: ["PERFEITO", "BOM", "DANIFICADO"],
        },
        {
          key: "functional_data.training_required",
          label: "Treinamento Requerido",
          type: "",
        },
        {
          key: "warranty_data.code",
          label: "Código da Garantia",
          type: "",
        },
        {
          key: "warranty_data.description",
          label: "Descrição da Garantia",
          type: "",
        },
        {
          key: "description",
          label: "Descrição",
          type: "",
        },
        {
          key: "actions",
          label: "Ações",
        },
      ],
      item: {
        title: "Excel template",
        loc: "/assets/files/AssetsExample.xlsx",
      },
      editAssetConfig: {},
      invalidParameter: false,
    };
  },
  watch: {
    successAssets() {
      this.successAssets.forEach((successAsset) => {
        this.tableData = this.tableData.filter((asset) => {
          return asset.internal_id !== successAsset.internal_id;
        });
      });
    },
    customer() {
      if (this.customer) {
        this.updateList();
      }
    },
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  mounted() {
    this.updateList();

    if (this.$route.params.customerId) {
      if (this.$route.params.customerId) {
        this.$store.dispatch("customer/getCustomer", {
          customerId: this.$route.params.customerId,
        });

        this.$store.dispatch("employee/getEmployees", {
          customer_id: this.$route.params.customerId,
        });

        this.$store.dispatch("employee/getAreas", {
          customerId: this.$route.params.customerId,
        });

        this.$store.dispatch("employee/getDepartments", {
          customerId: this.$route.params.customerId,
        });

        this.$store.dispatch("employee/getPositions", {
          customerId: this.$route.params.customerId,
        });

        this.$store.dispatch("asset/getAssets", {
          customer_id: this.$route.params.customerId,
        });
      }
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
      this.$router.push("/");
    }
  },
  methods: {
    verifyAssets() {
      let hashSet = new Set();
      this.tableData.forEach((item) => {
        item.invalid = false;
        item.errors = {
          name: "",
          serial_number: "",
          internal_id: "",
        };

        if (
          !item.name?.toString().trim() ||
          !item.serial_number?.toString().trim() ||
          !item.internal_id?.toString().trim()
        ) {
          item.invalid = true;
          item.errors.name = !item.name?.toString().trim()
            ? "Nome não pode ser vazio"
            : "";
          item.errors.serial_number = !item.serial_number?.toString().trim()
            ? "Número de série não pode ser vazio"
            : "";
          item.errors.internal_id = !item.internal_id?.toString().trim()
            ? "Identificador não pode ser vazio"
            : "";
        }
        else if (
          hashSet.has(item.serial_number) ||
          hashSet.has(item.internal_id)
        ) {
          item.invalid = true;

          if (hashSet.has(item.serial_number)) {
            item.errors.serial_number = "Número de série duplicado";
          }

          if (hashSet.has(item.internal_id))
            item.errors.internal_id = "Identificador duplicado";
        } else {
          hashSet.add(item.serial_number);
          hashSet.add(item.internal_id);
        }
        this.assets.forEach((asset) => {
          if (asset.serial_number == item.serial_number) {
            item.invalid = true;
            item.errors.serial_number = "Número de série já cadastrado";
          }

          if (asset.internal_id == item.internal_id) {
            item.invalid = true;
            item.errors.internal_id = "Identificador já cadastrado";
          }
        });
      });
      this.$refs.selectableTable.refresh();
    },
    changeKeys(obj_array) {
      obj_array.forEach((elem) => {
        Object.keys(elem).forEach((key) => {
          for (let y in this.fieldsHeader) {
            if (key.toLowerCase() == this.fieldsHeader[y].label.toLowerCase()) {
              /* Create new key with the same values/properties */
              Object.defineProperty(
                elem,
                this.fieldsHeader[y].key,
                Object.getOwnPropertyDescriptor(elem, key)
              );
              /*Delete old key*/
              delete elem[key];
              break;
            }
          }
        });
      });

      return obj_array;
    },
    updateData(value) {
      let index = this.tableData.findIndex(
        (item) => item.internal_id == value.internal_id
      );
      this.tableData[index]["asset_config"] = value;
      this.validate();
    },
    label_to_key_header(header) {
      let new_header = [];
      let found;
      /*This code is not optimal, but in the worse case it is only a 32 element array, so it doesnt matter*/
      header.forEach((field) => {
        /* Cannot use "break" in forEach*/
        found = false;
        for (let y in this.fieldsHeader) {
          if (field.toLowerCase() == this.fieldsHeader[y].label.toLowerCase()) {
            new_header.push(this.fieldsHeader[y].key);
            found = true;
            break;
          }
        }
        /*didnt find a match, keep the same*/
        if (!found) {
          new_header.push(field);
        }
      });
      return new_header;
    },

    isAssetValid(item) {
      this.verifyAssets();
    },
    saveAssets() {
      this.verifyAssets();
      let validAssets = this.selected.filter((item) => !item.invalid);
      if (validAssets.length && validAssets.length === this.selected.length) {
        let sendAssets = [...this.selected];
        sendAssets.forEach((asset) => {
          delete asset.invalid;
          delete asset.selected;
          delete asset.errors;
          this.replaceDate(asset);
        });

        this.$store.dispatch("asset/saveListAsset", {
          assets: sendAssets,
          customer_id: this.$route.params.customerId,
        });
      } else {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
          field: "Dados dos Ativos",
          extra: "Selecione Ativos válidos para salvar",
        });
      }
    },
    replaceStrings(obj) {
      for (let key in obj) {
        if (typeof obj[key] === "object") {
          this.replaceStrings(obj[key]);
        } else if (obj[key] === "NULL") {
          obj[key] = null;
        }
      }
      return obj;
    },
    replaceDate(obj){
      for (let key in obj) {
        if (typeof obj[key] === "object") {
          this.replaceDate(obj[key]);
        }

        else if (
          typeof obj[key] === "string" &&
          obj[key].length === 10 &&
          obj[key].split("-").length === 3
        ) {
          obj[key] = formatDateAPI(obj[key]);
        }
      }
    },
    verifyAssetType(asset, newKey){
      let adjustContent = (content, type, key) => {
        if (type === "integer"){
          if (isNaN(parseInt(content))){
            this.invalidParameter = true;
            return null;
          }

          else if (key === "financial_data.lifespan" || key === "financial_data.ncm")
            return parseInt(content).toString();

          return parseInt(content);
        }

        else if (type === "float"){
          if (isNaN(parseFloat(content))){
            this.invalidParameter = true;
            return null;
          }

          return parseFloat(content).toFixed(2);
        }

        else if (type === "string"){
          return content?.toString();
        }

        else if (type === "date"){
          if (content?.toString().length === 10){
            if (content?.toString().split("/").length === 3){
              return formatDate(content);
            }
            
            else if (content?.toString().split("-").length === 3){
              return content;
            }
          }
          
          this.invalidParameter = true;
          return null;
        }

        else if (typeof type === "object"){
          if (!type.includes(content?.toString().toUpperCase())){
            this.invalidParameter = true;
            return null
          }
          return content;
        }

        else {
          return content;
        }
      }

      for(let key in asset){
        let attr = newKey ? `${newKey}.${key}` : key;
        if (attr !== "maintenance_data" && typeof asset[attr] === "object"){
          this.verifyAssetType(asset[key], attr);
        }

        else if (asset[key] !== null && asset[key] !== undefined && asset[key] !== ""){
          for (let field of this.fieldsHeader){
            if (field.key === attr){
              asset[key] = adjustContent(asset[key], field.type, field.key)
              break;
            }
          }
        }
      }
    },
    transformDotNotation(data) {
      let transformedData = [];
      data.forEach((element) => {
        let transformedElement = {
          internal_id: null,
          acquisition_date: null,
          category: "",
          description: "",
          financial_data: {
            acquisition_cost: null,
            depreciation_rate: null,
            expiration_date: null,
            lifespan: null,
            ncm: null,
            salvage_value: null,
            useful_life: null,
            borrower: null,
            last_nf_number: null,
            asset_movement_date: null,
            nf_file: "",
          },
          functional_data: {
            area: null,
            department: null,
            importance: null,
            manufacturer: null,
            model: null,
            quality_state: null,
            state: null,
            supervisor: "",
            training_required: false,
          },
          maintenance_data: [
            {
              description: "",
              frequency: null,
              type: null,
            },
          ],
          name: null,
          serial_number: null,
          subcategory: "",
          warranty_data: {
            code: "",
            description: "",
            status: null,
            valid_until: null,
          },
        };
        for (let key in element) {
          let keyArray = key.split(".");
          let currentElement = transformedElement;
          for (let i = 0; i < keyArray.length; i++) {
            if (i === keyArray.length - 1) {
              currentElement[keyArray[i]] = element[key];
            } else {
              if (!currentElement[keyArray[i]]) {
                currentElement[keyArray[i]] = {};
              }
              currentElement = currentElement[keyArray[i]];
            }
          }
        }
        transformedData.push(transformedElement);
      });

      this.replaceStrings(transformedData);
      return transformedData;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    deleteSelected() {
      this.tableData = this.tableData.filter((e) => {
        return !this.selected.includes(e);
      });
      this.$refs.selectableTable.refresh();
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    },
    handleSuccess({ results, header }) {
      if (results.length < 50) {
        this.tableData = this.transformDotNotation(this.changeKeys(results));
        this.invalidParameter = false;

        for (let asset of this.tableData){
          this.verifyAssets();
          this.verifyAssetsValidities(asset)
          this.verifyAssetType(asset)
        }

        if (this.invalidParameter)
          window.frontMsg(
          "ASSET_IMPORT_INVALID"
        );
          
        this.tableHeader = this.label_to_key_header(header);
      } else {
        window.frontMsg("FRNT_ACTION_WARNING", {
          reason: "a quantidade de linhas na planilha excede o limite de 50",
        });
      }
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = `Editar cliente ${this.customer.company_name}`;
      }
    },
    verifyAssetsValidities(asset) {
      const todayDate = new Date();

      if (asset.warranty_data.valid_until){
        let validityDate = new Date(asset.warranty_data.valid_until);
        validityDate.setDate(validityDate.getDate() + 1) // Adjusting validity date to the choosed day.

        if (validityDate >= todayDate) asset.warranty_data.status = "VALIDO"
        else asset.warranty_data.status = "EXPIRADO"
      }

      else asset.warranty_data.status = null;
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    editableFields() {
      return this.fields.filter((field) => field.editable);
    },
    employees() {
      return this.$store.getters["employee/employees"];
    },
    assets() {
      return this.$store.getters["asset/assets"];
    },
    successAssets() {
      return this.$store.getters["asset/successAssets"];
    },
  },
};
//test
</script>

<style lang="scss" scoped>
.check-symbol {
  font-size: 3rem;
  margin-top: -30px;
  margin-left: 15px;
  position: absolute;
  display: block;
  text-align: center;
  user-select: none;
}

.valid-import {
  width: 100px;
  font-size: 20px;
  padding: 10px;
  border-radius: 50px;
  transition: none;
}
</style>
