<template>
  <div
    id="error-body"
    v-bind:class="loggedIn ? 'error-body-loggedIn' : 'error-body-loggedOut'"
  >
  <!-- 8:00 às 12:00 e 13:00 às 17:00 de segunda - feira a sexta -feira -->
    <div id="btn-container" class="btn-container">
      <h1 class="error-title">Canais de Atendimento</h1>
      <p class="error-text">
        E-mail é nosso principal método de atendimento. Tente mandar um e-mail antes de usar outros canais
      </p>
      <p class="services">Horário de atendimento: 8:00-12:00 & 13:00-17:00 de segunda à sexta</p>
      <div class="att-grid">
        <a href="https://wa.me/5541992123270?text=Olá!%20Tenho%20uma%20dúvida/problema.%20Gostaria%20de%20receber%20atendimento" target="_blank">
        <div class="att-grid-item">
          <img src="@/assets/img/wpp.png" alt="" />
          <p class="services">WhatsApp</p>
        </div>
      </a>
        <a href="mailto:suporte@truework.com.br" target="_blank">
          <div class="att-grid-item">
            <img src="@/assets/img/email.png" />
            <p class="services">E-mail</p>
          </div>
        </a>
<!-- Uncomment the following code to create a second line of icons -->
      <!-- </div>
      <div class="att-grid-2"> -->
        <div class="att-grid-item">
          <img src="@/assets/img/tel.png" alt="" />
          <p class="services">Telefone</p>
          <p class="services">(41) 99212-3270</p>
        </div>
        <!-- <a
          href="https://teams.microsoft.com/l/chat/0/0?users=suporte.gestmod@truework.com.br"
          target="_blank"
          ><div class="att-grid-item">
            <img src="@/assets/img/teams.png" alt="" />
            <p class="services">Microsoft Teams</p>
          </div>
        </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/auth";
export default {
  data() {
    return {
      loggedIn: auth.loggedIn(),
    };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.error-body-loggedIn {
  background-image: url("@/assets/img/Fundo-401.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 120%;
  height: 90vh;
  background-color: #021d3b;
  margin-top: -2%;
  margin-left: -5%;
  margin-bottom: -2%;
}
.error-body-loggedOut {
  background-image: url("@/assets/img/Fundo-401.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 88vh;
  background-color: #021d3b;
}
.error-title {
  text-align: center;
  font-size: 48px;
  font-family: "dosis";
  font-weight: bold;
  color: #ffa517;
}
.error-text {
  text-align: center;
  font-size: 24px;
  font-family: "dosis";
  font-weight: medium;
  color: #ffa517;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.btn-login-go {
  font-family: "dosis";
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  width: 161px;
  align-self: center;
  text-align: center;
  border-width: 0;
  margin-top: 1rem;
  border: none;
  color: #021d3b;
  background-color: #ffa517;
}
.btn-container {
  position: absolute;
  overflow: hidden;
  width: 65%;
  margin: 15%;
  margin-top: 10%;
  text-align: center;
  padding: 10px;
}

.img {
  top: 20%;
  left: 63%;
  position: absolute;
}

.att-grid {
  margin-top: 1rem;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  justify-items: center;
}

.att-grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
  margin-top: 16px;
  margin-left: 10em;
  margin-right: 10em;
  justify-items: center;
}

.att-grid-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  border: 2px solid #021d3b;
  -webkit-box-shadow: 0px 4px 4px rgb(0 0 0 / 100%);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 100%);
  width: 10em;
  height: 10em;
  justify-content: center;
  background-color: #02143ba8;
}

.att-grid-item:hover {
  -webkit-box-shadow: 0px 4px 4px rgb(0 0 0 / 100%);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 100%);
  background-color: #ffa517;
  color: #021d3b !important;
  cursor: pointer;
}

.att-grid-item:hover .services {
  color: #021d3b !important;
}

.services{
  color: white;
  font-size: 1.2rem;
}

.att-grid-item img {
  width: 75px;
  height: 75px;
  padding: 5px;
}
</style>
