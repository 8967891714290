var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"can",rawName:"v-can",value:(['ADMIN', 'SYSADMIN', 'MANAGER', 'FRONTDESK', 'DASHBOARD', 'USER']),expression:"['ADMIN', 'SYSADMIN', 'MANAGER', 'FRONTDESK', 'DASHBOARD', 'USER']"}]},[_c('TrainingScheduleModal',{attrs:{"entireTraining":this.selectedTraining}}),_c('div',{staticClass:"row px-0"},[_c('TwInput',{attrs:{"label":"Busca","col_lg":"6"}},[_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('i',{staticClass:"fas fa-search"})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"form-control",staticStyle:{"max-width":"900px"},attrs:{"type":"search","id":"filterInputEmployee","placeholder":"Buscar"},domProps:{"value":(_vm.filter)},on:{"input":function($event){if($event.target.composing)return;_vm.filter=$event.target.value}}})],1)],1),_c('div',{staticClass:"col-2 d-flex flex-column-reverse pb-3"},[_c('b-button',{directives:[{name:"can",rawName:"v-can",value:(['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']),expression:"['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']"}],attrs:{"id":"addTrainingbtn","variant":"primary","title":"Adicionar treinamento","href":"#"},on:{"click":() => {
            this.$router.push({ name: 'customer-add-training' });
          }}},[_vm._v("Adicionar")])],1),_c('div',{staticClass:"col-2 d-flex flex-column-reverse pb-3"},[_c('b-button',{directives:[{name:"can",rawName:"v-can",value:(['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']),expression:"['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']"}],attrs:{"variant":"primary","href":"#","title":"Importar treinamentos"},on:{"click":() => {
            this.$router.push({ name: 'customer-import-training' });
          }}},[_vm._v("Importar")])],1),_c('div',{staticClass:"col-2 d-flex flex-column-reverse pb-3"},[_c('b-button',{directives:[{name:"can",rawName:"v-can",value:(['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']),expression:"['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']"}],attrs:{"variant":"primary","title":"Exportar treinamentos","href":"#"},on:{"click":function($event){return _vm.exportTrainings()}}},[_vm._v("Exportar")])],1)],1),_c('div',[_c('Modal',{attrs:{"id":"modalDeleteTraining","title":"Excluir treinamento","msg":'Tem certeza que deseja excluir o Treinamento?',"button":"Excluir","method":_vm.deleteTraining}})],1),_c('b-table',{ref:"selectableTable",staticClass:"mt-3 rounded",attrs:{"responsive":"sm","striped":"","borderless":"","hover":"","no-sort-reset":"","sticky-header":"610px","show-empty":"","empty-text":"Nenhum registro encontrado","empty-filtered-text":"Nenhum registro encontrado para essa busca","head-variant":"light","per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortByTrainings,"busy":_vm.load,"filter":_vm.filter,"fields":_vm.fieldsTrainings,"items":_vm.customerTrainings},on:{"update:sortBy":function($event){_vm.sortByTrainings=$event},"update:sort-by":function($event){_vm.sortByTrainings=$event},"filtered":_vm.onFiltered,"context-changed":_vm.changeBsTableState},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(){return _vm._l((_vm.fieldsTrainings),function(field){return _c('col',{key:field.key,style:({
          width: field.key === 'actions' ? '100px' : '550px',
        })})})},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('strong',[_c('i',{staticClass:"fas fa-sync-alt fa-spin"})])])]},proxy:true},{key:"cell(actions)",fn:function(data){return [_c('a',{directives:[{name:"can",rawName:"v-can",value:(['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']),expression:"['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']"}],staticClass:"mr-3",attrs:{"id":"deleteTraining","href":"#","data-toggle":"modal","data-target":'#modalDeleteTraining',"title":"Excluir"},on:{"click":function($event){_vm.trainingDelete = data.item}}},[_c('i',{staticClass:"far fa-trash-alt iconspace"})]),_c('a',{directives:[{name:"can",rawName:"v-can",value:(['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']),expression:"['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']"}],staticClass:"mr-3",attrs:{"href":"#","id":"addtag","data-toggle":"modal","data-target":'#training-schedule-modal',"title":"Associar horários"},on:{"click":function($event){return _vm.selectTrainingToModal(data.item)}}},[_c('i',{staticClass:"fa-solid fa-clock"})]),_c('router-link',{directives:[{name:"can",rawName:"v-can",value:(['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']),expression:"['ADMIN', 'SYSADMIN', 'DASHBOARD', 'USER']"}],attrs:{"to":{
          name: 'customer-edit-training',
          params: {
            customerId: _vm.customer ? _vm.customer.id : -1,
            trainingId: data.item.id,
          },
        },"title":"Editar","id":"customer-employee-edit-a"}},[_c('i',{staticClass:"fas fa-pen iconspace",attrs:{"id":"editEmployee"}})])]}}])}),_c('div',{staticClass:"row"},[(_vm.rows)?_c('div',{staticClass:"col-12 col-md-4 mb-2 pt-1"},[_c('b-pagination',{staticClass:"pagination",attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"align":"fill","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e(),_c('div',{staticClass:"col-12 col-md-8"},[_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('b-dropdown',{attrs:{"variant":"primary","text":`Linhas: ${_vm.perPage}`},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}},[_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":() => {
                this.perPage = 10;
              }}},[_vm._v("10")]),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":() => {
                this.perPage = 20;
              }}},[_vm._v("20")]),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":() => {
                this.perPage = 50;
              }}},[_vm._v("50")]),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":() => {
                this.perPage = '1000';
              }}},[_vm._v("1000")])],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }