<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />

    <div class="content-header px-0">
      <h1 class="title-I">Adicionar Grupo</h1>
    </div>

    <div class="col-12 col-sm-12 p-0 col-md-12">
      <div class="card direct-chat direct-chat-primary Customer">
        <div class="card-body">
          <div class="p-4 bg-white rounded">
            <form @submit="saveGroup" novalidate="true">
              <div class="row">
                <TwInput label="Nome do Grupo" required="false">
                  <input
                    id="groupname"
                    v-model="group_name"
                    type="text"
                    maxlength="45"
                    class="form-control"
                    placeholder="Nome do grupo"
                  />
                </TwInput>
              </div>
              <div class="col-12 row">
                <p class="col-6 row" style="font-size: 18px">Licenças</p>
                <form id="licenses" class="col-12 row">
                  <TwInput
                    v-for="license in licenses"
                    :key="license.role"
                    :label="license.role"
                    required="true"
                    col_lg="2"
                  >
                    <input
                      :id="license.role + '_field'"
                      v-model="license.total_licenses"
                      type="number"
                      class="form-control"
                    />
                  </TwInput>
                </form>
              </div>
              <div
                id="check"
                class="form-check row"
                style="margin-left: 10px; margin-top: 10px; margin-bottom: 10px"
              >
                <div class="row mb-2">
                  <label col_lg="6" style="padding-right: 50px">
                    <input
                      v-on:click="showPBI"
                      v-model="pbi"
                      type="checkbox"
                      class="form-check-input"
                      id="check-activate-pwbi"
                      label="Power BI"
                    />
                    <label
                      for="check-activate-pwbi"
                      class="form-check-label"
                      style="margin-left: 10px"
                    >
                      Associar grupo ao Power BI
                      <i
                        title="Caso essa opção esteja selecionada pode-se associar o grupo ao Power BI"
                        class="fas fa-question-circle"
                      >
                      </i>
                    </label>
                  </label>
<!--                   <label col_lg="6">
                    <input
                      v-model="tfa"
                      type="checkbox"
                      class="form-check-input"
                      id="tfa"
                      label="Duplo fator"
                    />
                    <label
                      for="tfa"
                      class="form-check-label"
                      style="margin-left: 10px"
                    >
                      Ativar duplo fator para autenticar usuários
                      <i
                        title="Caso essa opção esteja selecionada, por padrão será exigida um método secundário de autenticação para os usuários"
                        class="fas fa-question-circle"
                      >
                      </i>
                    </label>
                  </label> -->
                </div>
                <div class="mt-3">
                  <!-- Input de PBI -->
                  <div id="esc" v-show="passpbi">
                    <!-- TAB HERE-->
                    <div class="pt-4 pr-4 pb-2 bg-white rounded">
                      <div class="row mb-2">
                        <div class="col-12">
                          <div class="card direct-chat direct-chat-primary">
                            <div class="card-body custom-card overflow-visible">
                              <ul class="nav nav-tabs bg-main">
                                <li class="nav-item">
                                  <a
                                    id="autentication-tab"
                                    class="nav-link first"
                                    v-on:click="changeTab('autentication')"
                                    href="#"
                                    aria-current="autentication"
                                    tabindex="-1"
                                    >Dados PowerBI</a
                                  >
                                </li>
                              </ul>
                              <div>
                                <div class="row marginInput ml-2 pt-3">
                                  <TwInput label="Usuário PBI">
                                    <div class="pt-1">
                                      <input
                                        v-if="passpbi"
                                        id="username"
                                        v-model="pbi_user"
                                        type="text"
                                        class="form-control padd"
                                        placeholder="Usuário PBI"
                                      />
                                    </div>
                                  </TwInput>
                                  <TwInput label="Senha PBI">
                                    <label class="label-input pass-show" for="">
                                      <input
                                        v-if="passpbi"
                                        id="password"
                                        v-model="pbi_pwd"
                                        :type="!passShow ? 'password' : 'text'"
                                        class="form-control padd"
                                        placeholder="Senha PBI"
                                      />
                                      <a
                                        @click="showPass"
                                        href="/"
                                        class="icon2"
                                      >
                                        <i
                                          v-bind:class="{
                                            'far fa-eye': passShow,
                                            'far fa-eye-slash': !passShow,
                                          }"
                                        ></i>
                                      </a>
                                    </label>
                                  </TwInput>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>                  
                </div>                
              </div>                                             
              <p class="col-12 row" style="font-size: 18px">Configurações de login</p>
              <hr/>
                <form class="col-12 row">
                  <TwInput label="Tentativas de login">
                    <i
                      title="Número máximo de tentativas de login erradas antes do usuário ser bloqueado"
                      class="fas fa-question-circle"
                      style="margin-left: 5px"
                    />
                    <input
                      id="max_attempts"
                      v-model="authen_config.max_attempts"
                      type="number"
                      :min="1"
                      :max="999"
                      class="form-control"
                      placeholder="10"
                    />
                  </TwInput>  
                  <TwInput label="Expiração de senha (Dias)">
                    <i
                      title="Número de dias para a expiração da senha. Após esse período a senha deve ser redefinida"
                      class="fas fa-question-circle"
                      style="margin-left: 5px"
                    />
                  <input
                    id="password_expiration"
                    v-model="authen_config.password_expiration"
                    type="number"
                    :min="1"
                    :max="999"
                    class="form-control"
                    placeholder="180"
                  />
                </TwInput>
            </form>
              <div class="row p-1">
                <button
                  id="savenewgroup"
                  type="submit"
                  class="btn btn-primary px-5"
                  :disabled="load"
                >
                  <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import TwInput from "@/components/TwInput.vue";
export default {
  name: "AddGroup",
  data: function () {
    return {
      breadcrumbList: [],
      group_name: "",
      pbi: false,
      tfa: false,
      passpbi: false,
      passShow: false,
      pbi_user: "",
      pbi_pwd: "",
      licenses: [],
      //authen_config with default values
      authen_config: {
        max_attempts:10,
        password_expiration:180,
      },
    };
  },
  created() {
    this.$store.dispatch("user/getRoles");
    /*This fixes Breadcrumb on page load*/
    this.updateList();    
  },
  watch: {
    $route() {
      this.updateList();
    },
    roles(){
      this.roles.forEach(role => {
        let t = { role: role.name, total_licenses: 0,}
        this.licenses.push(t);
      });
    }
  },
  methods: {
    showPBI() {
      this.passpbi = !this.passpbi;
    },
    showPass() {
      event.preventDefault();
      this.passShow = !this.passShow;
    },
    isGroupValid() {
      let form = document.getElementById("licenses");
      if (!form.reportValidity()) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          {
            field: "Número de Licenças",
            numbercondition: "maior ou igual a zero",
          }
        );
        return;
      }
      if (!this.group_name || this.group_name.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Nome do Grupo" }
        );
        return false;
      }

      if (/^([a-zA-Z0-9_-]+)$/i.test(this.group_name.trim()) === false) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          {
            field: "Nome do Grupo",
            extra: "Apenas letras, números, hífen e underline são permitidos",
          }
        );
        return false;
      }
      if (this.authen_config.max_attempts>999||this.authen_config.max_attempts<1) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Número de Licenças",
          numbercondition: "dentro do intervalo de 1 e 999",
        });
        return false;
      }
      if (this.authen_config.password_expiration>999||this.authen_config.password_expiration<1) {
        window.frontMsg("FRNT_NUM_RANGE_WARNING", {
          field: "Expiração de senha (Dias)",
          numbercondition: "dentro do intervalo de 1 e 999 dias",
        });
        return false;
      }

      return true;
    },
    saveGroup: function () {
      event.preventDefault();
      if (!this.isGroupValid()) {
        return;
      }
      let group_licenses = [];
      for (let license of this.licenses) {
        if (license.total_licenses > 0) {
          group_licenses.push({
            role: license.role,
            total_licenses: license.total_licenses,
          });
        } else if (
          license.total_licenses < 0 ||
          license.total_licenses === ""
        ) {
          window.frontMsg(
            "FRNT_NUM_RANGE_WARNING",
                        {
              field: "Número de Licenças",
              numbercondition: "maior ou igual a zero",
            }
          );
          return;
        }
      }
      let send_package = {
        name: this.group_name,
        pbi: this.pbi,
        tfa: this.tfa,
        licenses: group_licenses,
        authen_config: {
          max_attempts:this.authen_config.max_attempts,
          password_expiration: this.authen_config.password_expiration,
        },
      };
      if (this.pbi) {
        send_package.pbi_user = this.pbi_user;
        send_package.pbi_pwd = this.pbi_pwd;
      }
      this.$store.dispatch("user/saveGroup", send_package);
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  components: {
    Breadcrumb,
    TwInput,
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    group() {
      return this.$store.getters["user/group"];
    },
    roles() {
      return this.$store.getters["user/roles"];
    },
  },
};
</script>
