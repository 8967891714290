<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1>Adicionar Configuração</h1>
    </div>
    <div class="row mt-3">
      <TwInput col_lg="3" label="Área">
        <div class="input-group mb-3">
          <input
            id="newarea"
            v-model="novaarea"
            type="text"
            maxlength="50"
            class="form-control"
            placeholder="Adicionar Nova Área"
            aria-label="Adicionar Nova Área"
            aria-describedby="basic-addon2"
          />
          <div class="input-group-append">
            <button
              class="btn btn-primary"
              id="basic-addon2"
              type="button"
              @click="addArea"
              :disabled="load"
            >
              <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
              Adicionar
            </button>
          </div>
        </div>
        <div class="input-group mb-3 flex-content">
            <router-link
              class="btn btn-primary col-lg-11 mt-1 mb-1 r-md-1 px-xl-3 content-box"
              id="import-area"
              :to="{
                name: 'customer-config-import',
                params: {
                  config: 'area'
                }
                }"
              type="button"
              :disabled="load"
            >
              <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
              Importar
            </router-link>
            <button
              class="btn btn-primary col-lg-11 mt-1 mb-1 px-xl-3 content-box"
              id="export-area"
              type="button"
              @click="configExport(setores, fields_area, 'AreasExport')"
              :disabled="load"
            >
              <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
              Exportar
            </button>
        </div>
        <b-table
          responsive="sm"
          striped
          :busy="load"
          borderless
          hover
          :fields="fields_area"
          :items="setores"
          class="mt-3 rounded"
        >
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <strong>
                <i class="fas fa-sync-alt fa-spin"></i>
              </strong>
            </div>
          </template>

          <template v-slot:table-colgroup>
            <col
              v-for="field in fields_area"
              :key="field.key"
              :style="{ width: field.key === 'actions' ? '100px' : '550px' }"
            />
          </template>
          <template v-slot:cell(area)="data">
          <span>
            {{spliceField(data.item.area, 29)}}
        </span>
        </template>
          <template v-slot:cell(actions)="data">
            <a
              id="deletearea"
              @click="area = data.item.area"
              href="#"
              class="ml-4"
              data-toggle="modal"
              data-target="#modalDeleteArea"
              title="Excluir"
            >
              <i class="far fa-trash-alt"></i>
            </a>
          </template>
        </b-table>
        <Modal
          id="modalDeleteArea"
          title="Excluir Área"
          v-bind:msg="'Tem certeza que deseja excluir?'"
          button="Excluir"
          :method="deleteArea"
        />
      </TwInput>
      <TwInput col_lg="3" label="Departamento">
        <div class="input-group mb-3">
          <input
            id="newdepartment"
            v-model="novodepartamento"
            type="text"
            maxlength="50"
            class="form-control"
            placeholder="Adicionar Novo Departamento"
            aria-label="Adicionar Novo Departamento"
            aria-describedby="basic-addon3"
          />
          <div class="input-group-append">
            <button
              class="btn btn-primary"
              id="basic-addon3"
              type="button"
              @click="addDepartamento"
              :disabled="load"
            >
              <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
              Adicionar
            </button>
          </div>
        </div>
        <div class="input-group mb-3 flex-content">
          <router-link
              class="btn btn-primary col-lg-11 mt-1 mb-1 px-xl-3 content-box"
              id="import-department"
              :to="{
                name: 'customer-config-import',
                params: {
                  config: 'department'
                }
                }"
              type="button"
              :disabled="load"
            >
              <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
              Importar
            </router-link>
            <button
              class="btn btn-primary col-lg-11 mt-1 mb-1 px-xl-3 content-box"
              id="export-department"
              type="button"
              @click="configExport(departamentos, fields_departamento, 'DepartamentosExport')"
              :disabled="load"
            >
              <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
              Exportar
            </button>
        </div>
        <b-table
          responsive="sm"
          striped
          :busy="load"
          borderless
          hover
          :fields="fields_departamento"
          :items="departamentos"
          class="mt-3 rounded"
        >
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <strong>
                <i class="fas fa-sync-alt fa-spin"></i>
              </strong>
            </div>
          </template>

          <template v-slot:table-colgroup>
            <col
              v-for="field in fields_departamento"
              :key="field.key"
              :style="{ width: field.key === 'actions' ? '100px' : '550px' }"
            />
          </template>
          <template v-slot:cell(department)="data">
            <span>
              {{spliceField(data.item.department, 29)}}
          </span>
          </template>
          <template v-slot:cell(actions)="data">
            <a
              id="deletedepartamento"
              @click="departamento = data.item.department"
              href="#"
              class="ml-4"
              data-toggle="modal"
              data-target="#modalDeleteDepartamento"
              title="Excluir"
            >
              <i class="far fa-trash-alt"></i>
            </a>
          </template>
        </b-table>

        <Modal
          id="modalDeleteDepartamento"
          title="Excluir Departamento"
          v-bind:msg="'Tem certeza que deseja excluir?'"
          button="Excluir"
          :method="deleteDepartamento"
        />
      </TwInput>
      <TwInput col_lg="3" label="Cargo">
        <div class="input-group mb-3">
          <input
            id="newposition"
            v-model="novocargo"
            type="text"
            maxlength="50"
            class="form-control"
            placeholder="Adicionar Novo Cargo"
            aria-label="Adicionar Novo Cargo"
            aria-describedby="basic-addon4"
          />
          <div class="input-group-append">
            <button
              class="btn btn-primary"
              id="basic-addon4"
              type="button"
              @click="addCargo"
              :disabled="load"
            >
              <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
              Adicionar
            </button>
          </div>
        </div>
        <div class="input-group mb-3 flex-content">
          <router-link
              class="btn btn-primary col-lg-11 mt-1 mb-1 px-xl-3 content-box"
              id="import-position"
              :to="{
                name: 'customer-config-import',
                params: {
                  config: 'position'
                }
                }"
              type="button"
              :disabled="load"
            >
              <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
              Importar
            </router-link>
            <button
              class="btn btn-primary col-lg-11 mt-1 mb-1 px-xl-3 content-box"
              id="export-position"
              type="button"
              @click="configExport(cargos, fields_cargos, 'CargosExport')"
              :disabled="load"
            >
              <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
              Exportar
            </button>
        </div>
        <b-table
          responsive="sm"
          striped
          :busy="load"
          borderless
          hover
          :fields="fields_cargos"
          :items="cargos"
          class="mt-3 rounded"
        >
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <strong>
                <i class="fas fa-sync-alt fa-spin"></i>
              </strong>
            </div>
          </template>

          <template v-slot:table-colgroup>
            <col
              v-for="field in fields_cargos"
              :key="field.key"
              :style="{ width: field.key === 'actions' ? '100px' : '550px' }"
            />
          </template>
          <template v-slot:cell(position)="data">
            <span>
              {{spliceField(data.item.position, 29)}}
          </span>
          </template>
          <template v-slot:cell(actions)="data">
            <a
              id="deletecargo"
              @click="cargo = data.item.position"
              href="#"
              class="ml-4"
              data-toggle="modal"
              data-target="#modalDeleteCargo"
              title="Excluir"
            >
              <i class="far fa-trash-alt"></i>
            </a>
          </template>
        </b-table>

        <Modal
          id="modalDeleteCargo"
          title="Excluir Cargo"
          v-bind:msg="'Tem certeza que deseja excluir?'"
          button="Excluir"
          :method="deleteCargo"
        />
      </TwInput>
      <TwInput col_lg="3" label="Categoria">
        <div class="input-group mb-3">
          <input
            id="newcategory"
            v-model="novacategoria"
            type="text"
            maxlength="50"
            class="form-control"
            placeholder="Adicionar Nova Categoria"
            aria-label="Adicionar Nova Categoria"
            aria-describedby="basic-addon4"
          />
          <div class="input-group-append">
            <button
              class="btn btn-primary"
              id="basic-addon4"
              type="button"
              @click="addCategory"
              :disabled="load"
            >
              <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
              Adicionar
            </button>
          </div>
        </div>
        <div class="input-group mb-3 flex-content">
          <router-link
              class="btn btn-primary col-lg-11 mt-1 mb-1 px-xl-3 content-box"
              id="import-position"
              :to="{
                name: 'customer-config-import',
                params: {
                  config: 'category'
                }
                }"
              type="button"
              :disabled="load"
            >
              <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
              Importar
            </router-link>
            <button
              class="btn btn-primary col-lg-11 mt-1 mb-1 px-xl-3 content-box"
              id="export-position"
              type="button"
              @click="configExport(cargos, fields_cargos, 'CargosExport')"
              :disabled="load"
            >
              <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
              Exportar
            </button>
        </div>
        <b-table
          responsive="sm"
          striped
          :busy="load"
          borderless
          hover
          :fields="fields_categorias"
          :items="categorias"
          class="mt-3 rounded"
        >
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <strong>
                <i class="fas fa-sync-alt fa-spin"></i>
              </strong>
            </div>
          </template>

          <template v-slot:table-colgroup>
            <col
              v-for="field in fields_categorias"
              :key="field.key"
              :style="{ width: field.key === 'actions' ? '100px' : '550px' }"
            />
          </template>
          <template v-slot:cell(actions)="data">
            <a
              id="deletecategory"
              @click="categoria = data.item.name"
              href="#"
              class="ml-4"
              data-toggle="modal"
              data-target="#modalDeleteCategory"
              title="Excluir"
            >
              <i class="far fa-trash-alt"></i>
            </a>
          </template>
        </b-table>

        <Modal
          id="modalDeleteCategory"
          title="Excluir Categoria"
          v-bind:msg="'Tem certeza que deseja excluir?'"
          button="Excluir"
          :method="deleteCategory"
        />
      </TwInput>
    </div>
  </div>
</template>

<script>
import Modal from "../../components/Modal.vue";
import Breadcrumb from "../../components/Breadcrumb.vue";
import TwInput from "@/components/TwInput.vue";
import { spliceField } from "../../utils/format-fields";
const XLSX = require("xlsx");

export default {
  name: "CustomerConfig",
  data() {
    return {
      breadcrumbList: [],
      novaarea: null,
      area: null,
      fields_area: [
        { key: "area", sortable: true, label: "Área" },
        { key: "actions", label: "Excluir", sortable: false, class: "actions" },
      ],
      departamento: null,
      novodepartamento: null,
      fields_departamento: [
        { key: "department", sortable: true, label: "Setor" },
        { key: "actions", label: "Excluir", sortable: false, class: "actions" },
      ],
      cargo: null,
      novocargo: null,
      fields_cargos: [
        { key: "position", sortable: true, label: "Cargo" },
        { key: "actions", label: "Excluir", sortable: false, class: "actions" },
      ],
      categoria: null,
      novacategoria: null,
      fields_categorias: [
        { key: "name", sortable: true, label: "Categoria" },
        { key: "actions", label: "Excluir", sortable: false, class: "actions" },
      ],
    };
  },
  watch: {
    customer() {
      if (this.customer) {
        this.updateList();
      }
    },
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  methods: {
    spliceField,
    isDuplicate(newInput, configList) {
      const midRoute = configList === "areas" ? "setores" : configList
      let property;
      let object_list;

      if (configList === 'areas') {
        property = 'area';
        object_list = this.$store.state.employee[midRoute][configList];
      }
      else if (configList === 'departamentos') {
        property = 'department';
        object_list = this.$store.state.employee[midRoute][configList];
      }
      else if (configList === 'cargos') {
        property = 'position';
        object_list = this.$store.state.employee[midRoute][configList];
      }
      else if (configList === 'categorias') {
        property = 'name';
        object_list = this.$store.state.training.categories;
      }

      else property = null;

      for (let config of object_list){
        if (newInput.toUpperCase() === config[property]){
          return true;
        }
      }
      return false;
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = `Editar cliente ${this.customer.company_name}`;
      }
    },
    deleteArea() {
      event.preventDefault();
      this.$store.dispatch("employee/setores/deleteEmployeeAreas", {
        area: this.area,
        customer_id: parseInt(this.$route.params.customerId),
      });
    },
    addArea() {
      if (!this.novaarea?.trim())
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Área" });
      else if (this.isDuplicate(this.novaarea.trim(), "areas")){
        window.frontMsg("FRNT_IN_USE_WARNING", { field: "Área" });
      }
      else {
        this.$store.dispatch("employee/setores/saveListAreas", {
          areas: [{area: this.novaarea.trim().toUpperCase()}],
          customer_id: parseInt(this.$route.params.customerId),
        });
        this.novaarea = null;
      }
    },
    deleteDepartamento() {
      event.preventDefault();
      this.$store.dispatch(
        "employee/departamentos/deleteEmployeeDepartamentos",
        {
          departamento: this.departamento,
          customer_id: parseInt(this.$route.params.customerId),
        }
      );
    },
    addDepartamento() {
      if (!this.novodepartamento?.trim())
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Departamento" });
      else if (this.isDuplicate(this.novodepartamento.trim(), "departamentos")){
        window.frontMsg("FRNT_IN_USE_WARNING", { field: "Departamento" });
      }
      else {
        this.$store.dispatch(
          "employee/departamentos/saveListDepartamentos",
          {
            departamentos: [{department: this.novodepartamento.trim().toUpperCase()}],
            customer_id: parseInt(this.$route.params.customerId),
          }
        );
        this.novodepartamento = null;
      }
    },
    deleteCargo() {
      event.preventDefault();
      this.$store.dispatch("employee/cargos/deleteEmployeeCargos", {
        cargo: this.cargo,
        customer_id: parseInt(this.$route.params.customerId),
      });
    },
    addCargo() {
      if (!this.novocargo?.trim())
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Cargo" });
      else if (this.isDuplicate(this.novocargo.trim(), "cargos")){
        window.frontMsg("FRNT_IN_USE_WARNING", { field: "Cargo" });
      }
      else {
        this.$store.dispatch("employee/cargos/saveListCargos", {
          cargos: [{position: this.novocargo.trim().toUpperCase()}],
          customer_id: parseInt(this.$route.params.customerId),
        });
        this.novocargo = null;
      }
    },
    deleteCategory() {
      event.preventDefault();
      this.$store.dispatch("training/deleteCustomerCategories", {
        name: this.categoria,
        customer_id: parseInt(this.$route.params.customerId),
      });
    },
    addCategory() {
      if (!this.novacategoria?.trim())
        window.frontMsg("FRNT_EMPTY_WARNING", { field: "Categoria" });
      else if (this.isDuplicate(this.novacategoria.trim(), "categorias")){
        window.frontMsg("FRNT_IN_USE_WARNING", { field: "Categoria" });
      }
      else {
        this.$store.dispatch("training/saveCategories", {
          name: [{name: this.novacategoria.trim().toUpperCase()}],
          customer_id: parseInt(this.$route.params.customerId),
        });
        this.novacategoria = null;
      }
    },
    configExport(configArray, fields, nomeArquivo) {

      const configExport = configArray.map((config) => {
        const newConfigArray = {};
        const field = Object.keys(config)[0];
        
        newConfigArray[field] = config[field]
        return newConfigArray;
      });

      let non_nested_objects = this.flattenObject(configExport);
      let arr_of_objs = this.flat_obj_to_array_of_objs(non_nested_objects);

      let worksheet = XLSX.utils.json_to_sheet(arr_of_objs, {});
      /*Increased cell width for better visibility*/
      const wb_cols = Array(32).fill({ wch: 20 });
      worksheet["!cols"] = wb_cols;

      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
      let header = [];
      header.push(this.key_to_label_header(configExport, fields));
      XLSX.utils.sheet_add_aoa(worksheet, header, { origin: "A1" });
      XLSX.writeFile(new_workbook, `${nomeArquivo}.xlsx`);
    },
    key_to_label_header(configExport, fields) {
      let header = [];
      Object.keys(configExport[0]).forEach((field) => {
        /* Cannot use "break" in forEach*/
        for (let y in fields) {
          if (
            field == fields[y].key &&
            field !== "actions" &&
            field !== "financial_data.nf_file" &&
            field !== "financial_data.last_nf_number"
          ) {
            header.push(fields[y].label);
            break;
          }
        }
      });
      return header;
    },
    flattenObject(obj) {
      let toReturn = {};

      for (let i in obj) {
        if (!obj.hasOwnProperty(i)) continue;

        if (typeof obj[i] == "object" && obj[i] !== null) {
          let flatObject = this.flattenObject(obj[i]);
          for (let x in flatObject) {
            if (!flatObject.hasOwnProperty(x)) continue;

            toReturn[i + "." + x] = flatObject[x];
          }
        } else {
          toReturn[i] = obj[i];
        }
      }
      return toReturn;
    },
    flat_obj_to_array_of_objs(obj) {
      let arr = [];
      for (let key in obj) {
        // Check if the first character is a number
        if (key.split(".")[0] != null && !isNaN(key.split(".")[0])) {
          if (arr[key.split(".")[0]] == null) {
            arr[key.split(".")[0]] = {};
          }
          let indx = parseInt(key.split(".")[0]);
          let new_key = key.split(".").slice(1).join(".");
          if (obj[key] != null) {
            arr[indx][new_key] = "" + obj[key];
          }
        }
      }
      return arr;
    },
  },
  mounted() {
    this.$store.dispatch("employee/setores/getEmployeeAreas", {
      customer_id: this.$route.params.customerId,
    });
    this.$store.dispatch("employee/departamentos/getEmployeeDepartamentos", {
      customer_id: this.$route.params.customerId,
    });
    this.$store.dispatch("employee/cargos/getEmployeeCargos", {
      customer_id: this.$route.params.customerId,
    });
    this.$store.dispatch("training/getCustomerCategories", {
      
      customer_id: this.$route.params.customerId,
    });
    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });
    } else {
      window.frontMsg("FRNT_MISSING_WARNING", { frontelement: "do Cliente" });
      this.$router.push("/");
    }
    this.updateList();
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    setores() {
      return this.$store.getters["employee/setores/areas"];
    },
    departamentos() {
      return this.$store.getters["employee/departamentos/departamentos"];
    },
    cargos() {
      return this.$store.getters["employee/cargos/cargos"];
    },
    categorias(){
      return this.$store.getters["training/categories"];
    },
    rows() {
      return this.users.length;
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
  },
  components: {
    Modal,
    Breadcrumb,
    TwInput,
  },
};
</script>

<style lang="scss" scoped>
.b-table {
  text-transform: uppercase;
}

.flex-content {
  display: flex; 
  justify-content: space-between;
}

.content-box {
  box-sizing: content-box;
}
</style>
