<template>
  <div v-can="(['ADMIN','SYSADMIN'])">
    <div class="row px-0">
      <TwInput label="" col_lg="10" class="pr-0">
        <b-input-group>
          <b-input-group-prepend is-text
            ><i class="fas fa-search"></i>
          </b-input-group-prepend>
          <input
          style="max-width: 900px"
            v-model="filter"
            type="search"
            class="form-control"
            id="filterInputGateway"
            placeholder="Buscar"
          />
        </b-input-group>
      </TwInput>

      <div class="col-2 d-flex flex-column-reverse pb-3">
        <b-button
          href="#"
          variant="primary"
          @click="
            () => {
              this.$router.push({ name: 'customer-add-gateways' });
            }
          "
          >Associar Coletores</b-button
        >
      </div>
    </div>
    <div v-can="['ADMIN', 'SYSADMIN', 'MANAGER']">
      <b-table
        responsive="sm"
        striped
        borderless
        hover
        class="mt-3 rounded"
        no-sort-reset
        ref="selectableTable"
        sticky-header="610px"
        show-empty
        empty-text="Nenhum registro encontrado"
        empty-filtered-text="Nenhum registro encontrado para essa busca"
        head-variant="light"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :filter="filter"
        :busy="loadingCustomerGateways"
        :items="customerGateways"
        :fields="fieldsGateways"
        @filtered="onFiltered"
        @context-changed="changeBsTableState"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <strong>
              <i class="fas fa-sync-alt fa-spin"></i>
            </strong>
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <button
            v-can="['ADMIN','FRONTDESK','SYSADMIN']"
            class="mr-3"
            title="Desassociar"
            @click="GatewayUnassign = data.item"
            type="button"
            data-toggle="modal"
            data-target="#ModalDesassociarGateway"
            id="btnDesassociarGateway"
          >
            <i class="fas fa-lg fa-unlink" title="Desassociar Coletor"></i>
          </button>
        </template>
      </b-table> 

      <div class="row">
        <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="pagination"
          ></b-pagination>
        </div>
        <div class="col-12 col-md-8">
          <div class="btn-group" role="group">
            <b-dropdown
              variant="primary"
              v-model="perPage"
              :text="`Linhas: ${perPage}`"
            >
              <b-dropdown-item
                href="#"
                @click="
                  () => {
                    this.perPage = 10;
                  }
                "
                >10</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="
                  () => {
                    this.perPage = 20;
                  }
                "
                >20</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="
                  () => {
                    this.perPage = 50;
                  }
                "
                >50</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="
                  () => {
                    this.perPage = '1000';
                  }
                "
                >1000</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>
      </div>

      <Modal
        id="ModalDesassociarGateway"
        @click="GatewayUnassign = data.item"
        title="Desassociar Coletor"
        v-bind:msg="
          'Tem certeza que deseja desassociar ' +
          GatewayUnassign.gateway_name +
          '?'
        "
        button="Desassociar"
        :method="unassignGateway"
      />

      <div class="col-12">
        <router-link
          v-can="(['ADMIN'])"
          :to="{
            name: 'customer-add-gateways',
            params: { customerId: customer ? customer.id : -1 },
          }"
          id="addGateway-a"
        >
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "../../../components/Modal.vue";
import TwInput from "@/components/TwInput.vue";
export default {
  name: "CustomerGatewayTab",
  data() {
    return {
      filter: null,
      perPage: 10,
      currentPage: 1,
      rows: 1,
      sortBy: "",
      GatewayUnassign: "",
      fieldsGateways: [
        { label: "Nome Coletor", key: "gateway_name" },
        { label: "Identificador", key: "mac_address" },
        { label: "Tipo Coletor", key: "gateway_type" },
        { label: "Ações", key: "actions", class: "actions" },
      ],
    };
  },
  watch: {
    customer() {
      if (this.customer) {
        this.$store.dispatch("gateway/getCustomerGateways", {
          customer_id: this.customer.id,
        });
      }
    },
    customerGateways() {
      if (this.$options.name) {
        const ctx = JSON.parse(
          localStorage.getItem(`bstable-state-${this.$options.name}`)
        );
        if (!ctx){
          this.perPage = 10;
          this.currentPage = 1;
          this.sortBy = "";
          this.rows = this.customerGateways.length;
          return;
        }
        if (ctx.filter !== "" && ctx.filter !== null) {
          this.filter = ctx.filter;
        }
        if (ctx.perPage !== "" || ctx.perPage !== null) {
          this.perPage = ctx.perPage;
        }
        if (ctx.sortBy !== "" && ctx.sortBy !== null) {
          this.sortBy = ctx.sortBy;
        }
        
        // IOT-873: Table indicates page 1, but the content is of the last page visited.
        /*if (ctx.currentPage) {
          this.currentPage = ctx.currentPage;
        }*/
      }
      this.rows = this.customerGateways.length;
    },
  },
  methods: {
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    onFiltered(customerGateways) {
      this.rows = customerGateways.length;
    },
    unassignGateway() {
      const data = {
        gateway: this.GatewayUnassign,
        customer_id: this.GatewayUnassign.customer_id,
      };
      this.$store.dispatch("gateway/unassignGatewayFromCustomerEach", data);
    },
  },
  computed: {
    customer() {
      return this.$store.getters["customer/customer"];
    },
    customerGateways() {
      return this.$store.getters["gateway/customerGateways"];
    },
    loadingCustomerGateways() {
      return this.$store.getters["gateway/loadingCustomerGateways"];
    },
  },
  components: {
    Modal,
    TwInput,
  },
};
</script>
