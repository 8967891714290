<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <h4 class="mt-3 title-I">Editar Cliente</h4>
    <div class="mt-3 col-12 col-sm-12 p-0 col-md-12">
      <div class="card direct-chat direct-chat-primary">
        <div style="min-height: 610px" class="card-body m-3">
          <b-tabs content-class="mt-5">
            <b-tab
              v-if="
                hasRole([
                  'ADMIN',
                  'SYSADMIN',
                  'MANAGER',
                  'DASHBOARD',
                  'FRONTDESK',
                  'USER',
                ])
              "
              :active="lastTab === 'customer'"
              @click="setLastTab('customer')"
              id="customerTab"
            >
              <template #title>
                <i class="far fa-id-card nav-icon mr-2"></i>
                <strong>Cliente</strong>
              </template>
              <CustomerTab />
            </b-tab>
            <b-tab
              v-if="
                hasRole(['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER'])
              "
              :active="lastTab === 'building'"
              @click="setLastTab('building')"
              id="buildingTab"
            >
              <template #title>
                <i class="wizard-icon far fa-building mr-2"></i>
                <strong>Edifícios</strong>
              </template>
              <BuildingTab />
            </b-tab>
            <b-tab
              v-if="
                hasRole(['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER'])
              "
              :active="lastTab === 'contact'"
              @click="setLastTab('contact')"
              id="contactTab"
            >
              <template #title>
                <i class="wizard-icon far fa-address-book mr-2"></i>
                <strong>Contatos</strong>
              </template>
              <ContactTab />
            </b-tab>
            <b-tab
              v-if="
                hasRole([
                  'ADMIN',
                  'SYSADMIN',
                  'MANAGER',
                  'FRONTDESK',
                  'DASHBOARD',
                  'USER',
                ])
              "
              :active="lastTab === 'employee'"
              @click="setLastTab('employee')"
              id="employeeTab"
            >
              <template #title>
                <i class="wizard-icon fa fa-user-friends mr-2"></i>
                <strong>Colaboradores</strong>
              </template>
              <EmployeeTab />
            </b-tab>
            <b-tab
              v-if="
                hasRole([
                  'ADMIN',
                  'SYSADMIN',
                  'MANAGER',
                  'FRONTDESK',
                  'DASHBOARD',
                  'USER',
                ])
              "
              :active="lastTab === 'training'"
              @click="setLastTab('training')"
              id="trainingTab"
            >
              <template #title>
                <i class="wizard-icon  fa-solid fa-helmet-safety"></i>
                <strong> Treinamento</strong>
              </template>
              <TrainingTab />
            </b-tab>
            <!-- Asset -->
            <b-tab
              v-if="hasRole(['ADMIN', 'SYSADMIN', 'MANAGER', 'FRONTDESK'])"
              :active="lastTab === 'asset'"
              @click="setLastTab('asset')"
              id="assetTab"
            >
              <template #title>
                <i class="wizard-icon fa fa-file-invoice-dollar mr-2"></i>
                <strong>Ativos</strong>
              </template>
              <AssetTab />
            </b-tab>
            <b-tab
              v-if="
                hasRole(['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER'])
              "
              :active="lastTab === 'tag'"
              @click="setLastTab('tag')"
              id="tabTag"
            >
              <template #title>
                <i class="wizard-icon fa fa-tag mr-2"></i>
                <strong>Tags</strong>
              </template>
              <TagTab />
            </b-tab>

            <b-tab
              v-if="hasRole(['ADMIN', 'SYSADMIN', 'MANAGER'])"
              :active="lastTab === 'gateway'"
              @click="setLastTab('gateway')"
              id="gatewayTab"
            >
              <template #title>
                <i class="wizard-icon fa fa-tower-cell mr-2"></i>

                <strong>Coletores</strong>
              </template>
              <GatewayTab />
            </b-tab>
            <b-tab
              v-if="hasRole(['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD'])"
              :active="lastTab === 'notification'"
              @click="setLastTab('notification')"
              id="notificationTab"
            >
              <template #title>
                <i class="wizard-icon far fa-bell mr-2"></i>
                <strong>Regras de notificação</strong>
              </template>
              <NotificationTab />
            </b-tab>
            <!--             <b-tab
              v-if="hasRole(['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD'])"
              :active="lastTab === 'PhysiologicalParameterTab'"
              @click="setLastTab('PhysiologicalParameterTab')"
              id="physiologicalTab"
            >
              <template #title>
                <i class="wizard-icon fas fa-user-md mr-2"></i>

                <strong>Parametrização Fisiológica</strong>
              </template>
              <PhysiologicalParameterTab />
            </b-tab> -->
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import BuildingTab from "./components/BuildingTab";
import ContactTab from "./components/ContactTab";
import CustomerTab from "./components/CustomerTab.vue";
import EmployeeTab from "./components/EmployeeTab";
import TagTab from "./components/TagTab";
import GatewayTab from "./components/GatewayTab";
import NotificationTab from "./components/NotificationTab";
import PhysiologicalParameterTab from "./components/PhysiologicalParameterTab";
import AssetTab from "./components/AssetTab";
import TrainingTab from "./components/TrainingTab";
import hasRole from "@/utils/hasRole";

export default {
  components: {
    Breadcrumb,
    CustomerTab,
    ContactTab,
    EmployeeTab,
    TagTab,
    GatewayTab,
    NotificationTab,
    BuildingTab,
    PhysiologicalParameterTab,
    AssetTab,
    TrainingTab,
  },
  mounted() {
    this.updateList();
    this.lastTab = sessionStorage.getItem("lastTab");
  },
  data() {
    return {
      breadcrumbList: [],
      lastTab: null,
    };
  },
  methods: {
    setLastTab(tab) {
      sessionStorage.setItem("lastTab", tab);
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    hasRole,
  },
  watch: {
    customer() {
      return this.$store.getters["customer/customer"];
    },
  },
};
</script>

<style>
.wizard-card-footer {
  display: none;
}
</style>
