<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />

    <div class="content-header px-0">
      <h1 class="title-I">Associar Tags</h1>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card direct-chat direct-chat-primary">
          <div class="card-body">
            <div class="p-4 bg-white rounded">
              <div class="row">
                <TwInput col_lg="6" label="Filtro">
                  <div class="pr-1 pl-1 pb-3">
                    <input
                      id="search-available-tags"
                      v-model="searchAvailablesTags"
                      type="search"
                      class="form-control col-12 w-100"
                      placeholder="Buscar tags disponíveis"
                    />
                  </div>
                  <div class="row pr-1 pl-1">
                    <div class="col-12 pb-3">
                      <button
                        type="button"
                        class="btn btn-light table-header-button col-12"
                        @click="onSelectAllAvailable"
                        id="assignTag"
                        :disabled="load"
                      >
                        <i class="fas fa-chevron-right"></i>
                        <i class="fas fa-chevron-right"></i></button
                      ><b-table
                        class="custom-scroll mt-2"
                        thead-class="user-table-header"
                        empty-text="Não há tags disponíveis"
                        head-variant="light"
                        borderless
                        no-sort-reset
                        show-empty
                        :busy="load"
                        :fields="[
                          {
                            key: 'tag_label',
                            sortable: true,
                            label: 'Tags disponíveis',
                          },
                          {
                            key: 'tag_model',
                            sortable: true,
                            label: 'Modelo',
                          },
                          {
                            key: 'mac_address',
                            sortable: true,
                            label: 'Mac Address',
                          },
                        ]"
                        :items="availableTags"
                        :filter="searchAvailablesTags"
                        empty-filtered-text="nenhuma tag encontrada com esse filtro"
                        @row-clicked="onSelectAvailableTag"
                      >
                        <template v-slot:table-busy>
                          <div class="text-center my-2">
                            <strong>
                              <i class="fas fa-sync-alt fa-spin"></i>
                            </strong>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </TwInput>
                <TwInput col_lg="6" label="Filtro">
                  <div class="pr-1 pl-1 pb-3">
                    <input
                      id="search-assigned-tags"
                      v-model="searchFilteredAssignedTags"
                      type="search"
                      class="form-control col-12 w-100"
                      placeholder="Buscar tags já associadas"
                    />
                  </div>

                  <div class="row pr-1 pl-1">
                    <div class="col-12 pb-3">
                      <button
                        @click="onDeselectAll"
                        type="button"
                        class="btn btn-light table-header-button col-12"
                        id="unassignTag"
                        :disabled="load"
                      >
                        <i class="fas fa-chevron-left"></i>
                        <i class="fas fa-chevron-left"></i>
                      </button>
                      <b-table
                        class="custom-scroll mt-2"
                        thead-class="user-table-header"
                        empty-text="Não há tags associadas"
                        head-variant="light"
                        borderless
                        no-sort-reset
                        show-empty
                        :busy="load"
                        :items="customerTags"
                        :fields="[
                          {
                            key: 'tag_label',
                            sortable: true,
                            label: 'Tags Associadas',
                          },
                          {
                            key: 'tag_model',
                            sortable: true,
                            label: 'Modelo',
                          },
                          {
                            key: 'mac_address',
                            sortable: true,
                            label: 'Mac Address',
                          },
                        ]"
                        :filter="searchFilteredAssignedTags"
                        empty-filtered-text="nenhuma tag encontrada com esse filtro"
                        @row-clicked="onDeselectTag"
                      >
                        <template v-slot:table-busy>
                          <div class="text-center my-2">
                            <strong>
                              <i class="fas fa-sync-alt fa-spin"></i>
                            </strong>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </TwInput>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import TwInput from "@/components/TwInput";
export default {
  name: "CustomerAddTags",
  data: function () {
    return {
      breadcrumbList: [],
      fields: [
        { label: "Nome Tag", key: "tag_label" },
        { label: "Identificador", key: "mac_address" },
        { label: "Tipo Tag", key: "tag_type" },
        { label: "Ações", key: "actions", class: "actions" },
      ],
      searchAvailablesTags: "",
      searchFilteredAssignedTags: "",
    };
  },
  components: {
    Breadcrumb,
    TwInput,
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    customer() {
      if (this.customer) {
        this.$store.dispatch("tags/getCustomerTags", {
          customer_id: this.customer.id,
        });
        this.updateList();
      }
    },
  },
  computed: {
    customerTags() {
      return this.$store.getters["tags/customerTags"];
    },
    load(){
      return this.$store.getters["load"];
    },
    availableTags: {
      get() {
        return this.$store.getters["tags/availableTags"];
      },
      set (newValue) {
        return this.$store.dispatch('tags/setAvailableTags', newValue)
      }
    },
  },
  mounted() {
    this.availableTags = [];
    this.$store.dispatch("tags/getAvailableTags");

    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });

      this.$store.dispatch("tags/getCustomerTags", {
        customer_id: this.$route.params.customerId,
      });
    } else {
      window.frontMsg(
        "FRNT_MISSING_WARNING",
        { frontelement: "do Cliente" }
      );
      this.$router.push("/");
    /*This fixes Breadcrumb on page load*/
    this.updateList();
    }
  },
  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = `${this.customer.company_name}`;
      }
    },
    async onSelectAllAvailable() {
      if(this.availableTags.length === 0){
        window.frontMsg(
          "FRNT_ACTION_WARNING",
          {
            reason: "não há tags para associar",
          }
        );
        return;
      }
      const list_id = this.availableTags.map((tag) => {
        return tag.id;
      });
      await this.$store.dispatch("tags/assignTagToCustomer", {
        tag_list: list_id,
        customer_id: this.$route.params.customerId,
      });
      this.updateList();
    },
    async onSelectAvailableTag(item) {
      await this.$store.dispatch("tags/assignTagToCustomer", {
        tag_list: [item.id],
        customer_id: this.$route.params.customerId,
      });
      this.updateList();
    },
    async onDeselectAll() {
      if(this.customerTags.length === 0){
        window.frontMsg(
          "FRNT_ACTION_WARNING",
          {
            reason: "não há tags para desassociar",
          }
        );
        return;
      }
      const list_id = this.customerTags.map((tag) => {
        return tag.id;
      });
      await this.$store.dispatch("tags/unassignTagFromCustomer", {
        tag_list: list_id,
        customer_id: this.$route.params.customerId,
      });
      this.updateList();
    },
    async onDeselectTag(item) {
      await this.$store.dispatch("tags/unassignTagFromCustomer", {
        tag_list: [item.id],
        customer_id: this.$route.params.customerId,
      });
      this.updateList();
    },
  },
};
</script>

<style lang="scss">
.filter-input {
  &:focus,
  &:active {
    + .autocomplete {
      display: block;
    }
  }
}
.autocomplete {
  border: 1px solid #ced4da;
  max-height: 200px;
  position: absolute;
  width: 100%;
  z-index: 2;
  background: white;
  border-radius: 4px;
  overflow-y: auto;
  display: none;

  &:hover,
  &:active {
    display: block;
  }
}

.tag {
  padding: 4px;
  font-size: 0.75rem;

  &.selected-tag {
    text-align: left;
    border-radius: 4px;
    display: block;
    width: 100%;
    line-height: 1;
    padding: 5px;
    border: 1px solid #ced4da;

    > div {
      margin-top: 2px;
    }
  }

  &:hover {
    cursor: pointer;
    background: #f4f6f9;
  }

  div {
    span {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
</style>
